/* eslint-disable no-unused-vars */
import React from 'react';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyIcon from '@mui/icons-material/Money';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import CountUp from 'react-countup';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Calendar from '../components/calendar/Calendar';
import MainContent from '../components/MainContent';
import RightBar from '../components/RightBar';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import Spinner from '../components/spinner/Spinner';
import { VerticalBar } from '../components/charts/VerticalBar';
import { PieChart } from '../components/charts/PieChart';

function PaidModulesAnalytics() {
  const {
    data: paidModulesAnalyticsData,
    isLoading: isLoadingPaidModulesAnalytics,
  } = useFetchAnalytics(
    ['paid-modules-analytics'],
    '/paid-modules-analytics',
    {},
    "Couldn't get paid modules analytics. Please try again",
    true
  );

  return (
    <>
      <MainContent>
        {isLoadingPaidModulesAnalytics ? (
          <div className="mt-5 flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="space-y-5">
            <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon />}
              >
                <NavLink
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  to="/"
                  className="flex items-center"
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  <p>Home</p>
                </NavLink>
                <NavLink
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  to="/analytics"
                  className="flex items-center"
                >
                  <AnalyticsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  <p>Analytics</p>
                </NavLink>
              </Breadcrumbs>
            </div>
            <div className="bg-white px-2 py-5 rounded-lg">
              <p>
                <span className="font-medium">Paid Modules Analytics: </span>
                <span className="underline underline-offset-1">
                  Provides analytics of the paid modules offered, including
                  total student enrollment and revenue generated.
                </span>
              </p>
            </div>
            <div className="grid md:grid-cols-4 grid-cols-1 gap-2">
              <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col space-y-2">
                    <p className="font-extralight text-xs">
                      Total Revenue in USD
                    </p>
                    <CountUp
                      duration={1}
                      className="font-semibold text-center text-xl"
                      end={
                        paidModulesAnalyticsData?.data?.total_revenue_in_usd ??
                        0
                      }
                    />
                  </div>
                  <div className="h-14 w-14 bg-persian-500 text-center text-4xl rounded-full flex items-center justify-center">
                    <AttachMoneyIcon
                      fontSize="inherit"
                      className="text-center text-white"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col space-y-2">
                    <p className="font-extralight text-xs">
                      Total Revenue in KES
                    </p>
                    <CountUp
                      duration={1}
                      className="font-semibold text-center text-xl"
                      end={
                        paidModulesAnalyticsData?.data?.total_revenue_in_kes ??
                        0
                      }
                    />
                  </div>
                  <div className="h-14 w-14 bg-persian-500 text-center text-4xl rounded-full flex items-center justify-center">
                    <MoneyIcon
                      fontSize="inherit"
                      className="text-center text-white"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col space-y-2">
                    <p className="font-extralight text-xs">
                      Total Student Enrollments
                    </p>
                    <CountUp
                      duration={1}
                      className="font-semibold text-center text-xl"
                      end={
                        paidModulesAnalyticsData?.data
                          ?.total_student_enrollment ?? 0
                      }
                    />
                  </div>
                  <div className="h-14 w-14 bg-persian-500 text-center text-4xl rounded-full flex items-center justify-center">
                    <GroupIcon
                      fontSize="inherit"
                      className="text-center text-white"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col space-y-2">
                    <p className="font-extralight text-xs">
                      Total Paid Modules
                    </p>
                    <CountUp
                      duration={1}
                      className="font-semibold text-center text-xl"
                      end={
                        paidModulesAnalyticsData?.data?.total_paid_modules ?? 0
                      }
                    />
                  </div>
                  <div className="h-14 w-14 bg-persian-500 text-center  text-4xl rounded-full flex items-center justify-center">
                    <SchoolIcon
                      htmlColor="#8B173B"
                      fontSize="inherit"
                      className="text-center text-white"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="m-5 grid grid-cols-2 gap-2 ">
              {paidModulesAnalyticsData?.data
                ?.modules_usd_revenue_graphs_data && (
                <div className="bg-white h-[300px]">
                  <VerticalBar
                    labels={
                      paidModulesAnalyticsData?.data
                        ?.modules_usd_revenue_graphs_data?.labels
                    }
                    colour="#8B173B"
                    title={
                      paidModulesAnalyticsData?.data
                        ?.modules_usd_revenue_graphs_data?.title
                    }
                    label="Total Revenue (USD)"
                    datasets={
                      paidModulesAnalyticsData?.data
                        ?.modules_usd_revenue_graphs_data?.datasets || []
                    }
                  />
                </div>
              )}
              {paidModulesAnalyticsData?.data
                ?.modules_kes_revenue_graphs_data && (
                <div className="bg-white h-[300px]">
                  <VerticalBar
                    labels={
                      paidModulesAnalyticsData?.data
                        ?.modules_kes_revenue_graphs_data?.labels
                    }
                    colour="#8B173B"
                    title={
                      paidModulesAnalyticsData?.data
                        ?.modules_kes_revenue_graphs_data?.title
                    }
                    label="Total Revenue (KES)"
                    datasets={
                      paidModulesAnalyticsData?.data
                        ?.modules_kes_revenue_graphs_data?.datasets || []
                    }
                  />
                </div>
              )}
              {paidModulesAnalyticsData?.data
                ?.modules_enrollment_graph_data && (
                <div className="bg-white h-[400px] col-span-2">
                  <VerticalBar
                    labels={
                      paidModulesAnalyticsData?.data
                        ?.modules_enrollment_graph_data?.labels
                    }
                    colour="#8B173B"
                    title={
                      paidModulesAnalyticsData?.data
                        ?.modules_enrollment_graph_data?.title
                    }
                    label="Number of Students"
                    datasets={
                      paidModulesAnalyticsData?.data
                        ?.modules_enrollment_graph_data?.datasets || []
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default PaidModulesAnalytics;
