import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    name: yup.string().required('This is a required field'),
    start_date: yup.string().required('This is a required field'),
    end_date: yup.string().required('This is a required field'),
  })
  .required();

function CreatePaidCohort({ toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();
  const onPaidCohortCreationSuccess = () => {
    toast.success('Paid Cohort successfully created!');
    toggleModal();
    setIsSubmitting(false);
  };
  const onPaidCohortCreationFailure = () => {
    toast.error('Paid Cohort creation failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/paid-cohorts', data);
    },
    {
      onSuccess: () => {
        onPaidCohortCreationSuccess();
        queryClient.invalidateQueries(['paid-cohorts']);
      },
      onError: onPaidCohortCreationFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const requestData = {
      ...data,
      start_date: new Date(data.start_date).toISOString(),
      end_date: new Date(data.end_date).toISOString(),
    };
    mutation.mutate(requestData);
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="name" className="text-base">
              Cohort Name
            </label>
            <input
              {...register('name', { required: true })}
              type="text"
              id="name"
              placeholder="Cohort Name"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.name ? 'border-red-600' : ''
              }`}
            />
            {errors.name && (
              <span className="text-red-600 text-xs mt-2">
                {errors.name?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="start_date" className="text-base">
              Start Date
            </label>
            <input
              {...register('start_date', { required: true })}
              min={new Date().toISOString().split('T')[0]}
              type="date"
              id="start_date"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.start_date ? 'border-red-600' : ''
              }`}
            />
            {errors.start_date && (
              <span className="text-red-600 text-xs mt-2">
                {errors.start_date?.message}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="end_date" className="text-base">
              End Date
            </label>
            <input
              {...register('end_date', { required: true })}
              type="date"
              min={new Date().toISOString().split('T')[0]}
              id="end_date"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.end_date ? 'border-red-600' : ''
              }`}
            />
            {errors.end_date && (
              <span className="text-red-600 text-xs mt-2">
                {errors.end_date?.message}
              </span>
            )}
          </div>
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Paid Cohort</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreatePaidCohort;
