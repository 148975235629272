import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './login.css';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/images/logo.png';
import { login } from '../../../features/auth/authSlice';
import Spinner from '../../../components/spinner/Spinner';

const schema = yup
  .object({
    email: yup.string().required('Email required').email('Enter a valid email'),
    password: yup.string().required('Enter password'),
  })
  .required();

function Login() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authError = useSelector((state) => state.auth.error);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isLoading = useSelector((state) => state.auth.loading);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !authError && !isLoading) {
      reset({ email: '', password: '' });
      navigate('/');
    }
  }, [isAuthenticated, authError, navigate, reset, isLoading]);

  useEffect(() => {
    if (!isAuthenticated && authError && !isLoading) {
      toast.error(authError);
    }
  }, [isAuthenticated, authError, navigate, reset, isLoading]);

  const handleLogin = (data) => {
    dispatch(login(data));
  };

  return (
    <div>
      <div className="flex flex-row h-screen">
        <div className="img-container flex flex-1 relative justify-center items-center">
          <div className="copyright absolute bottom-3">
            <p className="text-white">
              &#169;
              {`               Power Learn Project ${new Date().getFullYear()} all rights reserved`}
            </p>
          </div>
        </div>

        <div className="form flex-1 flex flex-col justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <img src={logo} alt="" className="h-14" />
            <h4 className="text-xl font-medium mb-5">Login</h4>
          </div>

          <form onSubmit={handleSubmit(handleLogin)} className="w-96">
            <div className="flex flex-col mb-5">
              <label
                htmlFor="email"
                className="text-xs text-mine-shaft-500 mb-1 pl-1"
              >
                Email Address
              </label>
              <input
                id="email"
                {...register('email', { required: true, maxLength: 15 })}
                className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
                  errors.email ? 'border-red-600 focus:outline-red-600' : ''
                }`}
                type="email"
                placeholder="Email"
              />
              {errors.email && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.email?.message}
                </span>
              )}
            </div>

            <div className="flex flex-col mb-5">
              <label
                htmlFor="password"
                className="text-xs text-mine-shaft-500 mb-1 pl-1"
              >
                Password
              </label>
              <input
                id="password"
                {...register('password', { required: true, maxLength: 15 })}
                className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
                  errors.password ? 'border-red-600 focus:outline-red-600' : ''
                }`}
                autoComplete="true"
                type={showPassword ? 'text' : 'password'}
                placeholder="password"
              />
              {errors.password && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.password?.message}
                </span>
              )}
            </div>

            <div className="flex flex-row justify-between mb-3 align-middle items-center">
              <div className="flex space-x-1">
                <input
                  type="checkbox"
                  onChange={() => {
                    setShowPassword(!showPassword);
                  }}
                />
                <small className="text-dove-500">Show Password</small>
              </div>

              <Link to="/forgot-password">
                <small className="text-persian-500">Forgot Password?</small>{' '}
              </Link>
            </div>
            {isLoading ? (
              <div className="grid place-items-center">
                <Spinner />
              </div>
            ) : (
              <button
                type="submit"
                className="bg-claret-500 text-white w-full text-xs rounded-md py-2 mb-8"
              >
                LOGIN
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
