import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

function EditEvent({ toggleModal, eventData }) {
  const schema = yup.object().shape({
    eventName: yup.string().required('This is a required field'),
    eventStartDate: yup
      .date()
      .required('Please enter a valid date')
      .test(
        'start-date-test',
        'Start date cannot be in the past!',
        function testValue(value) {
          const currentDate = new Date();
          const originalStartDate = new Date(eventData.eventStartDate);
          return value
            ? new Date(value) >= currentDate ||
                new Date(value).getTime() === originalStartDate.getTime()
            : true;
        }
      ),
    eventEndDate: yup
      .date()
      .when('eventStartDate', (startDate, endDateSchema) => {
        return startDate
          ? endDateSchema.min(startDate, 'End date cannot be before start date')
          : endDateSchema;
      }),
    eventDescription: yup.string().required('This is a required field'),
    eventVenue: yup.string().required('This is a required field'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      eventName: eventData.eventName,
      eventStartDate: format(
        new Date(eventData.eventStartDate),
        "yyyy-MM-dd'T'HH:mm"
      ),
      eventEndDate: eventData.eventEndDate
        ? format(new Date(eventData.eventEndDate), "yyyy-MM-dd'T'HH:mm")
        : '',
      eventDescription: eventData.eventDescription,
      eventVenue: eventData.eventVenue,
    },
  });

  const queryClient = useQueryClient();

  const onEventUpdateSuccess = () => {
    toast.success('Event successfully updated!');
    toggleModal();
  };

  const onEventUpdateFailure = () => {
    toast.error('Event update failed! Please try again');
  };

  const { mutate, isLoading } = useMutation(
    (data) => {
      return axiosInstancev2.put(`/events/${eventData.id}`, data);
    },
    {
      onSuccess: () => {
        onEventUpdateSuccess();
        queryClient.invalidateQueries(['events']);
      },
      onError: onEventUpdateFailure,
    }
  );

  const onSubmit = async (data) => {
    mutate({
      ...data,
      eventStartDate: new Date(data.eventStartDate).toISOString(),
      eventEndDate: data.eventEndDate
        ? new Date(data.eventEndDate).toISOString()
        : null,
    });
  };

  return (
    <form className="text-gray-700 space-y-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col mb-5">
          <label
            htmlFor="eventName"
            className="text-xs text-mine-shaft-500 mb-1 pl-1"
          >
            Event Name
          </label>
          <input
            id="eventName"
            {...register('eventName', { required: true, maxLength: 15 })}
            className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
              errors.eventName ? 'border-red-600 focus:outline-red-600' : ''
            }`}
            type="text"
            placeholder="Event Name"
          />
          {errors.eventName && (
            <span className="text-red-600 text-xs mt-1">
              {errors.eventName?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col mb-5">
          <label
            htmlFor="eventVenue"
            className="text-xs text-mine-shaft-500 mb-1 pl-1"
          >
            Event Venue
          </label>
          <input
            id="eventVenue"
            {...register('eventVenue', { required: true, maxLength: 15 })}
            className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
              errors.eventVenue ? 'border-red-600 focus:outline-red-600' : ''
            }`}
            type="text"
            placeholder="Event Venue"
          />
          {errors.eventVenue && (
            <span className="text-red-600 text-xs mt-1">
              {errors.eventVenue?.message}
            </span>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col mb-5">
          <label
            htmlFor="eventStartDate"
            className="text-xs text-mine-shaft-500 mb-1 pl-1"
          >
            Start Date
          </label>
          <input
            id="eventStartDate"
            {...register('eventStartDate')}
            className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
              errors.eventStartDate
                ? 'border-red-600 focus:outline-red-600'
                : ''
            }`}
            type="datetime-local"
            placeholder="Start Date"
          />
          {errors.eventStartDate && (
            <span className="text-red-600 text-xs mt-1">
              {errors.eventStartDate?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col mb-5">
          <label
            htmlFor="eventEndDate"
            className="text-xs text-mine-shaft-500 mb-1 pl-1"
          >
            End Date
          </label>
          <input
            id="eventEndDate"
            {...register('eventEndDate', { required: true, maxLength: 15 })}
            className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
              errors.eventEndDate ? 'border-red-600 focus:outline-red-600' : ''
            }`}
            type="datetime-local"
            placeholder="End Date"
          />
          {errors.eventEndDate && (
            <span className="text-red-600 text-xs mt-1">
              {errors.eventEndDate?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col col-span-2 mb-5">
          <label
            htmlFor="eventDescription"
            className="text-xs text-mine-shaft-500 mb-1 pl-1"
          >
            Event Description
          </label>
          <input
            id="eventDescription"
            {...register('eventDescription', { required: true, maxLength: 15 })}
            className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
              errors.eventDescription
                ? 'border-red-600 focus:outline-red-600'
                : ''
            }`}
            type="text"
            placeholder="Event Description"
          />
          {errors.eventDescription && (
            <span className="text-red-600 text-xs mt-1">
              {errors.eventDescription?.message}
            </span>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="grid place-items-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
            type="submit"
          >
            <SaveIcon fontSize="inherit" />
            <p>Update Event</p>
          </button>
        </div>
      )}
    </form>
  );
}

export default EditEvent;
