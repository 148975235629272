import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function DoughnutChart({
  title,
  label,
  labels,
  datasets,
  borderColor,
  backgroundColor,
}) {
  const data = {
    labels,
    datasets: [
      {
        label,
        data: datasets,
        backgroundColor,
        borderColor,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    aspectRatio: 1,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  return <Doughnut data={data} options={options} />;
}
