import React from 'react';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useQuill } from 'react-quilljs';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Spinner from './spinner/Spinner';
// import useFetchData from '../hooks/useFetchData';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    subject: yup.string().required('This is a required field'),
    cohort: yup
      .object()
      .shape({
        label: yup.string().required('cohort is required (from label)'),
        value: yup.string().required('cohort is required'),
      })
      .nullable()
      .required('Please select a cohort'),
  })
  .required();

function CreateEmail({ toggleModal }) {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ align: [] }],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],

        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link'],
        [{ color: [] }, { background: [] }],
        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
    placeholder: 'Type message...',
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();

  const { data } = useFetchDatav2(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = data
    ? data?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: `${cohort.name} (${cohort.no_of_students || 0} Students)`,
      }))
    : [];

  const onSendEmailSuccess = () => {
    toast.success('Emails sent successfully.');
    toggleModal();
    queryClient.invalidateQueries(['emails']);
  };

  const onSendEmailFailure = () => {
    toast.error("Couldn't send emails. Please try again!");
  };

  const { mutate, isLoading } = useMutation(
    (emailData) => {
      return axiosInstancev2.post(`/cohorts/emails`, emailData);
    },
    { onSuccess: onSendEmailSuccess, onError: onSendEmailFailure }
  );

  const onSubmit = async (requestData) => {
    const { subject, cohort } = requestData;
    const formattedData = {
      subject,
      cohort_id: cohort.value,
      is_bulk: true,
      message: quill.root.innerHTML,
    };
    mutate(formattedData);
  };
  return (
    <form className="text-gray-700 space-y-2" onSubmit={handleSubmit(onSubmit)}>
      <div className=" grid grid-cols-2  space-x-10 align-middle">
        <div className="flex flex-col space-y-1">
          <label htmlFor="subject" className="text-base">
            Select Cohort
          </label>
          <Controller
            name="cohort"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Select Cohort"
                // defaultValue={options[0]}
                {...field}
                isClearable
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={cohortOptions}
              />
            )}
          />
          {errors.cohort && (
            <span className="text-red-600 text-xs mt-2">
              {errors.cohort?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="subject" className="text-base">
            Subject
          </label>
          <input
            {...register('subject', { required: true })}
            type="text"
            id="subject"
            placeholder="Email Subject"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.subject ? 'border-red-600' : ''
            }`}
          />
          {errors.subject && (
            <span className="text-red-600 text-xs mt-2">
              {errors.subject?.message}
            </span>
          )}
        </div>
      </div>
      <div className="grid mt-5">
        <p className="mb-3">Message</p>
        <div ref={quillRef} />
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center mt-5">
          <Spinner />
        </div>
      ) : (
        <div className="flex justify-between mt-7">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
            type="submit"
          >
            <SaveIcon fontSize="inherit" />
            <p>Send Email</p>
          </button>
        </div>
      )}
    </form>
  );
}

export default CreateEmail;
