import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PollIcon from '@mui/icons-material/Poll';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LoginIcon from '@mui/icons-material/Login';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MainContent from '../components/MainContent';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';
import AnalyticsCard from '../components/AnalyticsCard';
import usePermissions from '../hooks/usePermissions';

function AnalyticsDashBoard() {
  const permissions = usePermissions();

  const analyticsCards = [
    {
      id: 1,
      name: 'Cohort Logins',
      description:
        'Login stats for students, showing activity and engagement by cohort.',
      icon: <LoginIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/cohort-logins-analytics',
      permission: 'ViewScholarshipLoginsAnalytics',
    },
    {
      id: 2,
      name: 'Cohort Quiz Analytics',
      description:
        'Insights into quiz submissions, performance metrics by gender and cohort.',
      icon: <AssessmentIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/cohort-quiz-analytics',
      permission: 'ViewScholarshipQuizAnalytics',
    },
    {
      id: 3,
      name: 'Cohort Weekly Assignment Analytics',
      description:
        'Insights into weekly assignment submissions, performance metrics by cohort.',
      icon: <AssessmentIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/cohort-assignment-analytics',
      permission: 'ViewScholarshipAssignmentAnalytics',
    },
    {
      id: 4,
      name: 'Cohort  Survey Analytics',
      description: 'Insights into weekly survey responses segmented by cohort.',
      icon: <PollIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/survey-analytics',
      permission: null,
    },
    {
      id: 5,
      name: 'Sponsored Cohort Module Progress',
      description:
        'Analysis of learner progression across modules and courses by sponsored cohort.',
      icon: <TrendingUpIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/cohort-module-progress-analytics',
      permission: 'ViewStudentsProgressAnalytics',
    },
    {
      id: 6,
      name: 'Cohort Certification Analytics',
      description:
        'Insights into projects submitted by learners and a list of learners approved for certification.',
      icon: <WorkspacePremiumIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/cohort-certification-analytics',
      permission: 'ViewScholarshipCertificationAnalytics',
    },
    {
      id: 7,
      name: 'Paid Modules Student Progress',
      description:
        'Analysis of learner progression across paid modules and courses per paid cohort.',
      icon: <TrendingUpIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/paid-modules-students-progress',
      permission: 'ViewStudentsProgressAnalytics',
    },
    {
      id: 8,
      name: 'Paid Modules Analytics',
      description:
        'Analytics of paid modules, student enrollment, and revenue generated.',
      icon: <MonetizationOnIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/paid-modules',
      permission: 'ViewPaidModulesAnalytics',
    },
  ];

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 mt-4">
          {analyticsCards?.map((card) =>
            card?.permission ? (
              permissions.includes(card.permission) && (
                <AnalyticsCard
                  key={card.id}
                  cardName={card.name}
                  cardDescription={card.description}
                  componentLink={card.componentLink}
                >
                  {card.icon}
                </AnalyticsCard>
              )
            ) : (
              <AnalyticsCard
                key={card.id}
                cardName={card.name}
                cardDescription={card.description}
                componentLink={card.componentLink}
              >
                {card.icon}
              </AnalyticsCard>
            )
          )}
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default AnalyticsDashBoard;
