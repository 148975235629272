import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TableWrapper from '../utils/TableWrapper';
import Modal from '../components/Modal';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
// import useFetchData from '../hooks/useFetchData';
import Spinner from '../components/spinner/Spinner';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import CreatePaidCohort from '../components/CreatePaidCohort';

const columns = [
  { title: 'Cohort Name', field: 'name' },
  {
    title: 'Number of Students',
    field: 'no_of_students',
    render: (row) => <p>{row.no_of_students || 0}</p>,
  },
  {
    title: 'Start Date',
    field: 'start_date',
    render: (row) => (
      <p>
        {new Date(row.start_date).toLocaleDateString('en-us', {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
        })}
      </p>
    ),
  },
  {
    title: 'End Date',
    field: 'end_date',
    render: (row) => (
      <p>
        {new Date(row.end_date).toLocaleDateString('en-us', {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
        })}
      </p>
    ),
  },
  {
    title: 'Certification Date',
    field: 'certification_date',
    render: (row) =>
      row?.certification_date && (
        <p>
          {new Date(row?.certification_date).toLocaleDateString('en-us', {
            month: 'long',
            year: 'numeric',
            day: 'numeric',
          })}
        </p>
      ),
  },
  {
    title: 'Status',
    field: 'status',
    render: (row) => {
      if (row.status === 'live') {
        return (
          <p className="bg-green-400 text-center py-1 rounded-md capitalize font-medium">
            {row.status}
          </p>
        );
      }
      if (row.status === 'upcoming') {
        return (
          <p className="bg-orange-400 text-center py-1 rounded-md capitalize font-medium">
            {row.status}
          </p>
        );
      }
      return (
        <p className="bg-red-400 text-center py-1 rounded-md capitalize font-medium">
          {row.status}
        </p>
      );
    },
  },
];

function PaidCohorts() {
  const [createPaidCohortModalOpen, setCreatePaidCohortModalOpen] =
    useState(false);

  const navigate = useNavigate();

  const role = useGetUserRole();

  const toggleCreatePaidCohortModal = () => {
    setCreatePaidCohortModalOpen(!createPaidCohortModalOpen);
  };

  const { data, isLoading } = useFetchDatav2(
    ['paid-cohorts'],
    '/paid-cohorts',
    {},
    "Couldn't get paid cohorts. Please try again!",
    true
  );

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          {role === 'SuperAdmin' && (
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleCreatePaidCohortModal}
            >
              Create Paid Cohort
            </button>
          )}
        </div>
        <div className="mt-4">
          {isLoading ? (
            <Spinner />
          ) : (
            <TableWrapper
              title="List of Paid Cohorts"
              columns={columns}
              data={data ? data?.data?.paid_cohorts : []}
              onRowClick={(event, rowData) =>
                navigate(
                  `/paid-cohorts=${rowData.status}/${rowData.name}/${rowData.id}`
                )
              }
            />
          )}
        </div>
      </MainContent>
      <Modal
        modalOpen={createPaidCohortModalOpen}
        toggleModal={toggleCreatePaidCohortModal}
        title="Create Paid Cohort"
      >
        <CreatePaidCohort toggleModal={toggleCreatePaidCohortModal} />
      </Modal>
    </>
  );
}

export default PaidCohorts;
