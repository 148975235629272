import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Select from 'react-select';
import parse from 'html-react-parser';
import useFetchData from '../hooks/useFetchData';
import Spinner from './spinner/Spinner';

export default function SurveyResponsesAnalytics() {
  const [courseId, setCourseId] = useState('');
  const [moduleId, setModuleId] = useState('');
  const [weekId, setWeekId] = useState('');
  const [surveyId, setSurveyId] = useState('');
  const [surveyName, setSurveyName] = useState('');

  // get courses
  const { data: courses } = useFetchData(
    ['courses'],
    '/courses',
    {},
    "Couldn't fetch courses. Please try again!",
    true
  );

  const courseOptions = courses
    ? courses?.data?.courses?.map((course) => ({
        value: course.id,
        label: course.courseName,
      }))
    : [];

  // get modules
  const { data: courseModules } = useFetchData(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't fetch modules. Please try again!",
    courseId
  );

  const moduleOptions = courseModules
    ? courseModules?.data?.course_modules.map((courseModule) => ({
        value: courseModule.id,
        label: courseModule.moduleName,
      }))
    : [];

  // get module weeks

  const { data: moduleWeeks } = useFetchData(
    ['module-weeks', moduleId],
    `/module-weeks`,
    {
      params: { moduleId },
    },
    "Couldn't fetch weeks. Please try again!",
    moduleId
  );
  const weekOptions = moduleWeeks
    ? moduleWeeks?.data?.weeks?.map((week) => ({
        value: week.id,
        label: week.weekName,
      }))
    : [];

  // get surveys
  const { data: surveysData } = useFetchData(
    ['week-surveys', weekId],
    '/surveys',
    {
      params: { week_id: weekId },
    },
    "Couldn't get week surveys. Please try again!",
    weekId
  );

  const surveyOptions = surveysData
    ? surveysData?.data?.surveys?.map((survey) => ({
        value: survey.id,
        label: survey.name,
      }))
    : [];

  // get survey responses
  const { data: surveyResponseData, isLoading } = useFetchData(
    ['survey-questions', surveyId],
    `/surveys/questions/response/${surveyId}`,
    {},
    "Couldn't get survey responses. Please try again!",
    !!surveyId
  );

  return (
    <>
      <div className="bg-white px-2 py-5 rounded-lg">
        <p>
          <span className="font-medium">Survey Analytics: </span>
          <span className="underline underline-offset-1">
            Detailed insights into survey responses by learners
          </span>
        </p>
      </div>
      <div className="grid grid-cols-4 bg-white p-3 gap-4">
        <Select
          //   {...field}
          isClearable
          placeholder="Select Course"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={courseOptions}
          onChange={(event) => {
            setCourseId(event?.value || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Module"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={moduleOptions}
          onChange={(event) => {
            setModuleId(event?.value || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Week"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={weekOptions}
          onChange={(event) => {
            setWeekId(event?.value || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Survey"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={surveyOptions}
          onChange={(event) => {
            setSurveyId(event?.value || null);
            setSurveyName(event?.label || null);
          }}
        />
      </div>
      <div className="mt-4">
        {isLoading && surveyId ? (
          <div className="flex items-center justify-center bg-white">
            <Spinner />
          </div>
        ) : (
          surveyResponseData?.data?.questions && (
            <div className="w-full">
              <div className="mx-auto w-full rounded-md bg-white p-2">
                {surveyName && <h3>{surveyName}</h3>}
                {surveyResponseData?.data?.questions?.map((question) => (
                  <Disclosure key={question.id} as="div" className="mt-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                          <span>{parse(`${question?.question}`)}</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-5 w-5 text-purple-500`}
                          />
                        </Disclosure.Button>
                        {question.choices.map((choice) => (
                          <Disclosure.Panel
                            key={choice.id}
                            className="px-4 pt-4 pb-2 text-sm flex items-center align-middle bg-gray-100 rounded-md mt-2"
                          >
                            <div className=" flex justify-between w-full">
                              <p>{choice?.answer_choice}</p>
                              <p>
                                <span className="font-medium">
                                  {choice?.frequency || 0}
                                </span>{' '}
                                {choice?.frequency === 1
                                  ? 'student'
                                  : 'students'}{' '}
                                selected this option
                              </p>
                            </div>
                          </Disclosure.Panel>
                        ))}
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}
