import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BookIcon from '@mui/icons-material/Book';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import GridViewIcon from '@mui/icons-material/GridView';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useSelector } from 'react-redux';
import EventIcon from '@mui/icons-material/Event';
import ForumIcon from '@mui/icons-material/Forum';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const useSidebarData = () => {
  const role = useSelector((state) => state.auth.userDetails.role);

  switch (role) {
    case 'Admin':
      return [
        { id: 1, title: 'Dashboard', icon: <DashboardIcon />, link: '/' },
        {
          id: 2,
          title: 'Analytics',
          icon: <AnalyticsIcon />,
          link: '/analytics',
        },
        {
          id: 5,
          title: 'Sponsorship Cohorts',
          icon: <GridViewIcon />,
          link: '/cohorts',
        },
        {
          id: 6,
          title: 'Paid Cohorts',
          icon: <GridViewIcon />,
          link: '/paid-cohorts',
        },
        { id: 8, title: 'Groups', icon: <GroupsIcon />, link: '/groups' },
        { id: 9, title: 'Courses', icon: <BookIcon />, link: '/courses' },
        {
          id: 10,
          title: 'Tutorials',
          icon: <OndemandVideoIcon />,
          link: '/tutorials',
        },
        { id: 11, title: 'Events', icon: <EventIcon />, link: '/events' },
        {
          id: 12,
          title: 'Class Sessions',
          icon: <CalendarMonthIcon />,
          link: '/class-sessions',
        },
        {
          id: 13,
          title: 'Bulk Email',
          icon: <EmailOutlinedIcon />,
          link: '/emails',
        },
        { id: 14, title: 'Community', icon: <ForumIcon />, link: '/community' },
        {
          id: 15,
          title: 'Tickets',
          icon: <AirplaneTicketIcon />,
          link: '/tickets',
        },
      ];
    case 'ModuleLead':
      return [
        { id: 1, title: 'Dashboard', icon: <DashboardIcon />, link: '/' },
        {
          id: 2,
          title: 'Analytics',
          icon: <AnalyticsIcon />,
          link: '/analytics',
        },
        {
          id: 5,
          title: 'Sponsorship Cohorts',
          icon: <GridViewIcon />,
          link: '/cohorts',
        },
        {
          id: 6,
          title: 'Paid Cohorts',
          icon: <GridViewIcon />,
          link: '/paid-cohorts',
        },
        { id: 8, title: 'Groups', icon: <GroupsIcon />, link: '/groups' },
        { id: 9, title: 'Courses', icon: <BookIcon />, link: '/courses' },
        {
          id: 10,
          title: 'Tutorials',
          icon: <OndemandVideoIcon />,
          link: '/tutorials',
        },
        { id: 11, title: 'Events', icon: <EventIcon />, link: '/events' },
        {
          id: 12,
          title: 'Class Sessions',
          icon: <CalendarMonthIcon />,
          link: '/class-sessions',
        },
        { id: 13, title: 'Community', icon: <ForumIcon />, link: '/community' },
        {
          id: 14,
          title: 'Tickets',
          icon: <AirplaneTicketIcon />,
          link: '/tickets',
        },
      ];
    case 'Instructor':
      return [
        { id: 1, title: 'Dashboard', icon: <DashboardIcon />, link: '/' },
        {
          id: 2,
          title: 'Analytics',
          icon: <AnalyticsIcon />,
          link: '/analytics',
        },
        {
          id: 3,
          title: 'Sponsorship Cohorts',
          icon: <GridViewIcon />,
          link: '/cohorts',
        },
        {
          id: 4,
          title: 'Paid Cohorts',
          icon: <GridViewIcon />,
          link: '/paid-cohorts',
        },
        { id: 6, title: 'Groups', icon: <GroupsIcon />, link: '/groups' },
        { id: 7, title: 'Courses', icon: <BookIcon />, link: '/courses' },
        {
          id: 8,
          title: 'Tutorials',
          icon: <OndemandVideoIcon />,
          link: '/tutorials',
        },
        { id: 9, title: 'Events', icon: <EventIcon />, link: '/events' },
        {
          id: 10,
          title: 'Class Sessions',
          icon: <CalendarMonthIcon />,
          link: '/class-sessions',
        },
        { id: 11, title: 'Community', icon: <ForumIcon />, link: '/community' },
        {
          id: 12,
          title: 'Tickets',
          icon: <AirplaneTicketIcon />,
          link: '/tickets',
        },
      ];
    case 'AssistantInstructor':
      return [
        { id: 1, title: 'Dashboard', icon: <DashboardIcon />, link: '/' },
        {
          id: 2,
          title: 'Analytics',
          icon: <AnalyticsIcon />,
          link: '/analytics',
        },
        {
          id: 3,
          title: 'Sponsorship Cohorts',
          icon: <GridViewIcon />,
          link: '/cohorts',
        },
        {
          id: 4,
          title: 'Paid Cohorts',
          icon: <GridViewIcon />,
          link: '/paid-cohorts',
        },
        { id: 6, title: 'Groups', icon: <GroupsIcon />, link: '/groups' },
        { id: 7, title: 'Courses', icon: <BookIcon />, link: '/courses' },
        {
          id: 8,
          title: 'Tutorials',
          icon: <OndemandVideoIcon />,
          link: '/tutorials',
        },
        { id: 9, title: 'Events', icon: <EventIcon />, link: '/events' },
        {
          id: 10,
          title: 'Class Sessions',
          icon: <CalendarMonthIcon />,
          link: '/class-sessions',
        },
        { id: 11, title: 'Community', icon: <ForumIcon />, link: '/community' },
        {
          id: 12,
          title: 'Tickets',
          icon: <AirplaneTicketIcon />,
          link: '/tickets',
        },
      ];
    case 'DataAnalyst':
      return [
        { id: 1, title: 'Dashboard', icon: <DashboardIcon />, link: '/' },
        {
          id: 2,
          title: 'Analytics',
          icon: <AnalyticsIcon />,
          link: '/analytics',
        },
        {
          id: 4,
          title: 'Sponsorship Cohorts',
          icon: <GridViewIcon />,
          link: '/cohorts',
        },
        {
          id: 5,
          title: 'Paid Cohorts',
          icon: <GridViewIcon />,
          link: '/paid-cohorts',
        },
        { id: 6, title: 'Groups', icon: <GroupsIcon />, link: '/groups' },
        { id: 7, title: 'Courses', icon: <BookIcon />, link: '/courses' },
        {
          id: 8,
          title: 'Tutorials',
          icon: <OndemandVideoIcon />,
          link: '/tutorials',
        },
        { id: 9, title: 'Events', icon: <EventIcon />, link: '/events' },
        {
          id: 10,
          title: 'Class Sessions',
          icon: <CalendarMonthIcon />,
          link: '/class-sessions',
        },
        { id: 11, title: 'Community', icon: <ForumIcon />, link: '/community' },
        {
          id: 12,
          title: 'Tickets',
          icon: <AirplaneTicketIcon />,
          link: '/tickets',
        },
      ];
    case 'CommunityManager':
      return [
        { id: 1, title: 'Dashboard', icon: <DashboardIcon />, link: '/' },
        {
          id: 2,
          title: 'Analytics',
          icon: <AnalyticsIcon />,
          link: '/analytics',
        },
        { id: 3, title: 'Events', icon: <EventIcon />, link: '/events' },
        {
          id: 4,
          title: 'Bulk Email',
          icon: <EmailOutlinedIcon />,
          link: '/emails',
        },
        { id: 5, title: 'Community', icon: <ForumIcon />, link: '/community' },
        {
          id: 6,
          title: 'Tickets',
          icon: <AirplaneTicketIcon />,
          link: '/tickets',
        },
      ];
    case 'PartnerAdmin':
      return [
        { id: 1, title: 'Dashboard', icon: <DashboardIcon />, link: '/' },
        {
          id: 2,
          title: 'Analytics',
          icon: <AnalyticsIcon />,
          link: '/analytics',
        },
        {
          id: 3,
          title: 'Paid Cohorts',
          icon: <GridViewIcon />,
          link: '/paid-cohorts',
        },
      ];
    case 'SupportAdmin':
      return [
        {
          id: 1,
          title: 'Analytics',
          icon: <AnalyticsIcon />,
          link: '/analytics',
        },
        {
          id: 2,
          title: 'Sponsorship Cohorts',
          icon: <GridViewIcon />,
          link: '/cohorts',
        },
        { id: 3, title: 'Groups', icon: <GroupsIcon />, link: '/groups' },
        { id: 4, title: 'Courses', icon: <BookIcon />, link: '/courses' },
        { id: 5, title: 'Events', icon: <EventIcon />, link: '/events' },
        {
          id: 6,
          title: 'Class Sessions',
          icon: <CalendarMonthIcon />,
          link: '/class-sessions',
        },
        { id: 7, title: 'Community', icon: <ForumIcon />, link: '/community' },
        {
          id: 8,
          title: 'Tickets',
          icon: <AirplaneTicketIcon />,
          link: '/tickets',
        },
      ];
    default:
      return [
        { id: 1, title: 'Dashboard', icon: <DashboardIcon />, link: '/' },
        {
          id: 2,
          title: 'Analytics',
          icon: <AnalyticsIcon />,
          link: '/analytics',
        },
        {
          id: 5,
          title: 'Administrators',
          icon: <PersonAddAltIcon />,
          link: '/users',
        },
        {
          id: 6,
          title: 'Roles & Permissions',
          icon: <ManageAccountsOutlinedIcon />,
          link: '/roles&permissions',
        },
        {
          id: 7,
          title: 'Sponsorship Cohorts',
          icon: <GridViewIcon />,
          link: '/cohorts',
        },
        {
          id: 8,
          title: 'Paid Cohorts',
          icon: <GridViewIcon />,
          link: '/paid-cohorts',
        },
        { id: 10, title: 'Groups', icon: <GroupsIcon />, link: '/groups' },
        { id: 11, title: 'Courses', icon: <BookIcon />, link: '/courses' },
        {
          id: 12,
          title: 'Tutorials',
          icon: <OndemandVideoIcon />,
          link: '/tutorials',
        },
        { id: 13, title: 'Events', icon: <EventIcon />, link: '/events' },
        {
          id: 14,
          title: 'Class Sessions',
          icon: <CalendarMonthIcon />,
          link: '/class-sessions',
        },
        {
          id: 15,
          title: 'Bulk Email',
          icon: <EmailOutlinedIcon />,
          link: '/emails',
        },
        { id: 16, title: 'Community', icon: <ForumIcon />, link: '/community' },
        {
          id: 17,
          title: 'Tickets',
          icon: <AirplaneTicketIcon />,
          link: '/tickets',
        },
      ];
  }
};

export default useSidebarData;
