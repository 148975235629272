import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import UploadIcon from '@mui/icons-material/Upload';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

import 'react-circular-progressbar/dist/styles.css';

let controller;

const schema = yup
  .object({
    videolesson: yup
      .mixed()
      .required('Please select an image')
      .test('fileSize', 'Please upload video less than 1 GB', (value) => {
        return value && value[0]?.size <= 1000000000;
      }),
  })
  .required();

function AddVideoLesson({ toggleModal }) {
  const { lessonId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();
  const [percentage, setPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Couldn't upload video. Please try again!"
  );

  const onAddLessonVideoSuccess = () => {
    queryClient.invalidateQueries(['lesson-videos', lessonId]);
    toast.success('Video upload in progress. Wait for notification.');
    toggleModal();
  };

  const onSubmit = async (data) => {
    controller = new AbortController();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('lesson_id', lessonId);
    formData.append('video_file', data.videolesson[0]);
    axiosInstancev2
      .post(`/week-lessons/videos`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (dataUploaded) => {
          setPercentage(
            Math.round((100 * dataUploaded.loaded) / dataUploaded.total)
          );
        },
        signal: controller.signal,
      })
      .then(() => {
        onAddLessonVideoSuccess();
      })
      .catch(() => {
        toast.error(errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cancelUploadHandler = () => {
    if (controller) {
      setErrorMessage('Video upload cancelled');
      controller.abort();
    }
    toggleModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading ? (
        <div className="mt-5 grid items-center justify-center">
          <p className="mb-5">Video Uploading in progress ...</p>
          <CircularProgressbar
            className="h-28 w-28 text-claret-600"
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles({
              textColor: 'rgb(139 23 59 /1)',
              pathColor: 'rgb(139 23 59 /1)',
            })}
          />
          <div className=" mx-auto">
            <button
              className="border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center mt-4"
              type="button"
              onClick={cancelUploadHandler}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel Upload</p>
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="lessonTopic" className="text-base">
              Select video file (max 1GB)
            </label>
            <input
              {...register('videolesson', {
                required: true,
              })}
              accept="video/mp4"
              type="file"
              id="lessonVideo"
              placeholder="Lesson Title"
              className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
              //   value={lessonTopic}
              //   onChange={(event) => setLessonTopic(event.target.value)}
              //   ref={topicRef}
            />
            {errors.videolesson && (
              <span className="text-red-600 text-xs mt-2">
                {errors.videolesson?.message}
              </span>
            )}
          </div>
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={() => toggleModal()}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <UploadIcon fontSize="inherit" />
              <p>Upload Video</p>
            </button>
          </div>
        </>
      )}
    </form>
  );
}

export default AddVideoLesson;
