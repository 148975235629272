import React, { Fragment, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stats from '../components/Stats';
import RightBar from '../components/RightBar';
import Instructor from '../components/Instructor';
import Curriculum from '../components/Curriculum';
import CourseOverview from '../components/CourseOverview';
import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
import Modal from '../components/Modal';
import EditCourseModule from '../components/EditCourseModule';
import usePermissions from '../hooks/usePermissions';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import useGetUserRole from '../hooks/useGetUserRole';
// import useFetchData from '../hooks/useFetchData';

function ModuleDetail() {
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const role = useGetUserRole();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const { data: moduleData, isLoading } = useFetchDatav2(
    ['module', moduleId],
    `/course-modules/${moduleId}`,
    {},
    "Couldn't get module details. Please try again!",
    true
  );

  const { data } = useFetchDatav2(
    ['module-weeks', moduleId],
    `/module-weeks`,
    {
      params: { moduleId },
    },
    "Couldn't fetch weeks. Please try again!",
    true
  );

  const tabs = [
    {
      id: 1,
      title: 'Overview',
      component: (
        <CourseOverview
          moduleDescription={moduleData?.data?.course_module.moduleDescription}
        />
      ),
    },
    {
      id: 2,
      title: 'Curriculum',
      component: (
        <Curriculum
          weeks={data?.data.weeks || []}
          moduleName={moduleData?.data?.course_module.moduleName}
        />
      ),
    },
    {
      id: 3,
      title: 'Instructor',
      component: (
        <Instructor moduleName={moduleData?.data?.course_module.moduleName} />
      ),
    },
    // { title: "Reviews", component: <Reviews /> },
  ];

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/courses"
              className="flex items-center"
            >
              <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Courses</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={() => navigate(-1)}
              to={() => navigate(-1)}
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Modules</p>
            </NavLink>
          </Breadcrumbs>
          {(permissions?.includes('UpdateCourseModule') ||
            role === 'SuperAdmin') && (
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleModal}
            >
              Edit Module
            </button>
          )}
        </div>
        <div className="mt-2 space-y-4 bg-white rounded-md pb-2">
          <div className="bg-modulebg bg-no-repeat bg-center bg-cover w-full text-white px-12 py-8 rounded-sm ">
            <h5 className="text-2xl leading-8 mb-5">
              {moduleData?.data?.course_module.moduleName}
            </h5>
            <h5 className="mb-5 font-medium">
              {moduleData?.data?.course_module?.moduleShortDescription}
            </h5>
            <div className="flex  justify-between text-xs">
              <div className=" flex space-x-2 items-center">
                <AccessTimeIcon fontSize="inherit" />
                <p>
                  Duration {data?.data.weeks ? data?.data.weeks?.length : 0}{' '}
                  {data?.data.weeks?.length === 1 ? 'week' : 'weeks'}
                </p>
              </div>
              <div className=" flex space-x-2 items-center">
                <PlayLessonIcon fontSize="inherit" />
                <p className="capitalize">
                  {moduleData?.data?.course_module?.skillLevel} Level
                </p>
              </div>
            </div>
          </div>
          <Tab.Group>
            <Tab.List className=" flex space-x-5 mb-6 px-2">
              {tabs.map((tab) => (
                <Tab as={Fragment} key={tab.id}>
                  {({ selected }) => (
                    <div
                      className={` focus:outline-none cursor-pointer p-2 hover:bg-lms-custom-50
                     ${
                       selected
                         ? ' text-[#8B173B] border-b-2 border-[#8B173B]'
                         : ' hover:border-b-2 hover:border-lms-custom-100'
                     }
                    `}
                    >
                      <p>{tab.title}</p>
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>
            {isLoading ? (
              <div className="flex justify-center items-center mt-4">
                <Spinner />
              </div>
            ) : (
              <Tab.Panels className="px-2">
                {tabs.map((tab) => (
                  <Tab.Panel key={tab.id}>{tab.component}</Tab.Panel>
                ))}
              </Tab.Panels>
            )}
          </Tab.Group>
        </div>
      </MainContent>
      <RightBar>
        <Stats weeks={data?.data.weeks ? data?.data.weeks : []} />
      </RightBar>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Edit Module Details"
      >
        <EditCourseModule
          toggleModal={toggleModal}
          module={moduleData?.data?.course_module}
        />
      </Modal>
    </>
  );
}

export default ModuleDetail;
