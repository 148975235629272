import React, { Fragment, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Tab } from '@headlessui/react';
import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
import Modal from '../components/Modal';
import EditLesson from '../components/EditLesson';
import RightBar from '../components/RightBar';
import Weeks from '../components/Weeks';
import usePermissions from '../hooks/usePermissions';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import useGetUserRole from '../hooks/useGetUserRole';
import AddVideoLesson from '../components/AddVideoLesson';
import LessonVideoList from '../components/LessonVideoList';

function LessonDetail() {
  const { lessonId } = useParams();
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const role = useGetUserRole();

  const toggleVideoModal = () => {
    setVideoModalOpen(!videoModalOpen);
  };

  const getSingleLesson = async () => {
    const response = await axiosInstancev2.get(`/week-lessons/${lessonId}`);
    return response;
  };

  const getLessonVideos = async () => {
    const response = await axiosInstancev2.get(`/week-lessons/videos`, {
      params: { lesson_id: lessonId },
    });
    return response;
  };

  const { data, isLoading } = useQuery(['lesson', lessonId], getSingleLesson, {
    enabled: !!lessonId,
    staleTime: 3600 * 10000,
    cacheTime: 3600 * 10000,
  });

  const { data: videosData } = useQuery(
    ['lesson-videos', lessonId],
    getLessonVideos,
    {
      enabled: !!lessonId,
      staleTime: 3600 * 10000,
      cacheTime: 3600 * 10000,
    }
  );

  const onFetchModuleWeeksFailure = () => {
    toast.error("Couldn't fetch weeks. Please try again!");
  };

  const fetchModuleWeeks = async () => {
    // eslint-disable-next-line no-shadow
    const data = await axiosInstancev2.get(`/module-weeks`, {
      params: { moduleId },
    });
    return data;
  };

  const { data: weeks } = useQuery(
    ['module-weeks', moduleId],
    fetchModuleWeeks,
    {
      staleTime: 3600 * 10000,
      cacheTime: 3600 * 10000,
      onError: onFetchModuleWeeksFailure,
    }
  );

  const tabs = [
    {
      id: 1,
      title: 'Lesson Content',
      component: (
        <EditLesson
          currentLessonTopic={data ? data?.data?.lesson?.lessonTopic : ''}
          currentLessonContent={data ? data?.data?.lesson?.lessonContent : ''}
          currentLessonFileUrl={data ? data?.data?.lesson?.file_url : ''}
        />
      ),
    },
    {
      id: 2,
      title: 'Lesson Videos',
      component: <LessonVideoList videos={videosData?.data?.lesson_videos} />,
    },
  ];

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between mb-5">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/courses"
              className="flex items-center"
            >
              <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Courses</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={() => navigate(-1)}
              to={() => navigate(-1)}
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Modules</p>
            </NavLink>{' '}
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to={`/module/${moduleId}`}
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Module Details</p>
            </NavLink>
          </Breadcrumbs>
          {(permissions?.includes('UpdateLesson') || role === 'SuperAdmin') && (
            <div className="space-x-2">
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleVideoModal}
              >
                Add Video Lesson
              </button>
            </div>
          )}
        </div>
        <div className="bg-white p-4">
          <Tab.Group manual>
            <Tab.List className=" flex space-x-5 ">
              {tabs.map((tab) => (
                <Tab as={Fragment} key={tab.id}>
                  {({ selected }) => (
                    <div
                      className={` focus:outline-none cursor-pointer p-2 hover:bg-lms-custom-50
                     ${
                       selected
                         ? ' text-[#8B173B] border-b-2 border-[#8B173B]'
                         : '  hover:border-b-2 hover:border-lms-custom-100'
                     }
                    `}
                    >
                      <p>{tab.title}</p>
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {tabs.map((tab) => (
                <Tab.Panel key={tab.id}>{tab.component}</Tab.Panel>
              ))}
            </Tab.Panels>
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <div className="bg-white p-4" />
            )}
          </Tab.Group>
        </div>
        <Modal
          modalOpen={videoModalOpen}
          toggleModal={toggleVideoModal}
          title="Add Lesson Video"
        >
          <AddVideoLesson toggleModal={toggleVideoModal} />
        </Modal>
      </MainContent>
      <RightBar>
        <Weeks weeks={weeks?.data.weeks || []} />
      </RightBar>
    </>
  );
}

export default LessonDetail;
