import React from 'react';
import { saveAs } from 'file-saver'; // To save the CSV file
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import GetAppIcon from '@mui/icons-material/GetApp';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
);

export function LineGraph({ title, labels, colour, datasets, label }) {
  const options = {
    elements: {
      line: {
        tension: 0,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label,
        data: datasets,
        borderColor: colour,
        backgroundColor: colour,
      },
    ],
  };

  // Function to export the data to CSV
  const exportDataToCSV = () => {
    if (!datasets.length || !labels.length) {
      return;
    }

    // CSV header
    let csvContent = `Date, ${label}\n`;

    // Assuming `datasets` is an array of values for each label
    datasets.forEach((value, index) => {
      csvContent += `${labels[index]}, ${value}\n`;
    });

    // Create a Blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${title}.csv`);
  };

  return (
    <div>
      <div className="flex justify-between m-2">
        <h2>{title}</h2>
        <button
          type="button"
          onClick={exportDataToCSV}
          className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
        >
          <GetAppIcon className="inherit" />
          <span>Export Data as CSV</span>
        </button>
      </div>

      {/* Line chart rendering */}
      <Line options={options} data={data} />
    </div>
  );
}
