import React, { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { Avatar } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { GitHub, LinkedIn, Language } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import GridViewIcon from '@mui/icons-material/GridView';
import MainContent from '../components/MainContent';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import Spinner from '../components/spinner/Spinner';
import logo from '../assets/images/institution.png';
import school from '../assets/images/college.jpeg';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import StudentEnrolledModule from '../components/StudentEnrolledModule';
import EditStudentProfile from '../components/EditStudentProfile';
import Modal from '../components/Modal';
import TableWrapper from '../utils/TableWrapper';

function StudentDetailsPage() {
  const { studentId } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const role = useGetUserRole();

  const [editStudentModalOpen, setEditStudentModalOpen] = useState(false);
  const toggleEditStudentModal = () => {
    setEditStudentModalOpen(!editStudentModalOpen);
  };

  const { data: studentData, isLoading: isFetchingStudentData } =
    useFetchDatav2(
      ['student-details', studentId],
      `/users/student/${studentId}`,
      {},
      "Couldn't get student details. Please try again",
      !!studentId
    );

  const {
    data: studentAssignmentSubmissionsData,
    isLoading: isFetchingStudentAssignmentSubmissionsData,
  } = useFetchDatav2(
    ['student-assignment-submission', studentId],
    `/student-assignment-submission/filter-by-student-id/${studentId}`,
    {},
    "Couldn't get student assignment submissions. Please try again",
    !!studentId
  );

  const {
    data: studentQuizResultsData,
    isLoading: isFetchingStudentQuizResultsData,
  } = useFetchDatav2(
    ['my-quizes', studentId],
    `/result/quizes/${studentId}`,
    {},
    "Couldn't get student quizes results. Please try again",
    !!studentId
  );

  const { data: studentGroupsData, isLoading: isFetchingStudentGroupsData } =
    useFetchDatav2(
      ['group-members', studentId],
      '/group-members',
      {
        params: { student_id: studentId },
      },
      "Couldn't get learner's groups. Please try again!",
      true
    );

  const {
    data: studentModuleProgressData,
    isLoading: isFetchingModuleProgressData,
  } = useFetchAnalytics(
    ['students-progress', studentId],
    `/students-progress/${studentId}`,
    {},
    "Couldn't get student's progress on enrolled modules. Please try again",
    !!studentId
  );

  const arrayToString = (arr) => {
    if (arr.length === 0) return '';
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return arr.join(' and ');
    return `${arr.slice(0, -1).join(', ')}, and ${arr[arr.length - 1]}`;
  };

  const modulesSpecialized =
    studentData?.data?.student?.modules_specialised &&
    Array.isArray(JSON.parse(studentData?.data?.student?.modules_specialised))
      ? arrayToString(
          JSON.parse(studentData?.data?.student?.modules_specialised)
        )
      : studentData?.data?.student?.modules_specialised;

  const studentSubmittedAssignmentColumns = [
    {
      title: 'Assignment name',
      field: 'assignment.title',
    },
    {
      title: 'Submission Url',
      field: 'submission_url',
      render: (row) => (
        <a
          href={row?.submission_url}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          {row?.submission_url}
        </a>
      ),
    },
  ];

  const studentQuizResultsColumns = [
    {
      title: 'Quiz Name',
      field: 'quiz_name',
      render: (row) => <p className="capitalize">{row?.quiz_name}</p>,
    },
    {
      title: 'Percentage Result',
      field: 'percentage_result',
      render: (row) => <p className="">{row?.percentage_result || 0}</p>,
    },
    {
      title: 'Passed ?',
      field: 'passed',
      render: (row) => (
        <p className="capitalize">{row?.passed ? 'Yes' : 'No'}</p>
      ),
    },
    {
      title: 'First Attempt ?',
      field: 'is_first_attempt',
      render: (row) => (
        <p className="capitalize">{row?.is_first_attempt ? 'Yes' : 'No'}</p>
      ),
    },
  ];

  const studentGroupsColumns = [
    {
      title: 'Group Name',
      field: 'group.name',
      render: (row) => <p className="capitalize">{row?.group?.name}</p>,
    },
  ];

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between items-center">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={() => navigate(-1)}
              to={() => navigate(-1)}
              className="flex items-center"
            >
              <GridViewIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Previous Page</p>
            </NavLink>
          </Breadcrumbs>
        </div>
        {isFetchingStudentData ? (
          <Spinner />
        ) : (
          <div className="grid grid-cols-1 gap-4 bg-gray-200 mt-5 p-2">
            <div className="flex gap-4">
              <div className="flex-1 grid grid-cols-1 gap-4 bg-white rounded-md shadow-md">
                <div className="flex flex-col items-center justify-center">
                  <div className="m-2">
                    <Avatar
                      sx={{
                        height: 200,
                        width: 200,
                      }}
                      src={studentData?.data?.student?.profile_image}
                      className="bg-center cursor-pointer"
                    >
                      <p className="uppercase text-4xl">
                        {studentData?.data?.student?.firstname[0]}
                        {studentData?.data?.student?.lastname[0]}
                      </p>
                    </Avatar>
                  </div>
                  <div className="m-3 text-center">
                    <p className="capitalize font-extrabold text-2xl">{`${studentData?.data?.student?.firstname} ${studentData?.data?.student?.lastname}`}</p>
                    {studentData?.data?.student?.country && (
                      <p className="capitalize font-medium text-gray-600 text-xl">
                        {studentData?.data?.student?.town
                          ? `${studentData?.data?.student?.town},`
                          : ''}{' '}
                        {studentData?.data?.student?.country}
                      </p>
                    )}
                  </div>
                  {(permissions?.includes('UpdateStudent') ||
                    role === 'SuperAdmin') && (
                    <div className="m-2">
                      <button
                        className="border-2 border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center"
                        type="button"
                        onClick={toggleEditStudentModal}
                      >
                        <EditIcon fontSize="inherit" />
                        <p>Edit Profile</p>
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex flex-col items-baseline justify-center">
                  <div className="p-3 flex w-full items-center">
                    <div className="flex items-center">
                      <Language fontSize="large" />
                      <span className="ml-2 font-bold text-lg">Portfolio</span>
                    </div>
                    <div className="ml-auto">
                      <span className="">
                        {studentData?.data?.student?.portfolio ? (
                          <CopyToClipboard
                            text={studentData?.data?.student?.portfolio}
                            onCopy={() => {
                              toast.success(
                                'Student Portfolio link copied to clipboard'
                              );
                            }}
                          >
                            <ContentCopyIcon className="cursor-pointer" />
                          </CopyToClipboard>
                        ) : (
                          'Not Available'
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="p-3 flex w-full items-center">
                    <div className="flex items-center">
                      <GitHub fontSize="large" />
                      <span className="ml-2 font-bold text-lg">Github</span>
                    </div>
                    <div className="ml-auto">
                      <span>
                        {studentData?.data?.student?.github_account ? (
                          <CopyToClipboard
                            text={studentData?.data?.student?.github_account}
                            onCopy={() => {
                              toast.success(
                                'Student Github Account link copied to clipboard'
                              );
                            }}
                          >
                            <ContentCopyIcon className="cursor-pointer" />
                          </CopyToClipboard>
                        ) : (
                          'Not Available'
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="p-3 flex w-full items-center">
                    <div className="flex items-center">
                      <LinkedIn fontSize="large" />
                      <span className="ml-2 font-bold text-lg">LinkedIn</span>
                    </div>
                    <div className="ml-auto">
                      <span>
                        {studentData?.data?.student?.linkedin ? (
                          <CopyToClipboard
                            text={studentData?.data?.student?.linkedin}
                            onCopy={() => {
                              toast.success(
                                'Student LinkedIn link copied to clipboard'
                              );
                            }}
                          >
                            <ContentCopyIcon className="cursor-pointer" />
                          </CopyToClipboard>
                        ) : (
                          'Not Available'
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 bg-white rounded-md shadow-md flex flex-col">
                <div className="p-2 flex items-center">
                  <div className="w-[30%]">
                    <span className="font-bold text-xl">Full Name</span>
                  </div>
                  <div className="">
                    <span className="capitalize text-xl">
                      {studentData?.data?.student?.firstname ?? ''}{' '}
                      {studentData?.data?.student?.middlename ?? ''}{' '}
                      {studentData?.data?.student?.lastname ?? ''}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="p-2 flex items-center">
                  <div className="w-[30%]">
                    <span className="font-bold text-xl">Email</span>
                  </div>
                  <div className="">
                    <span className="text-xl flex items-center space-x-3">
                      {studentData?.data?.student?.email ? (
                        <>
                          <span>{studentData?.data?.student?.email}</span>
                          <CopyToClipboard
                            text={studentData?.data?.student?.email}
                            onCopy={() => {
                              toast.success(
                                'Student Email copied to clipboard'
                              );
                            }}
                          >
                            <ContentCopyIcon className="cursor-pointer" />
                          </CopyToClipboard>
                        </>
                      ) : (
                        'Not Available'
                      )}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="p-2 flex items-center">
                  <div className="w-[30%]">
                    <span className="font-bold text-xl">Phone Number</span>
                  </div>
                  <div className="">
                    <span className="text-xl flex items-center space-x-3">
                      {studentData?.data?.student?.phone_number ? (
                        <>
                          <span>
                            {studentData?.data?.student?.phone_number}
                          </span>
                          <CopyToClipboard
                            text={studentData?.data?.student?.phone_number}
                            onCopy={() => {
                              toast.success(
                                'Student Phone Number copied to clipboard'
                              );
                            }}
                          >
                            <ContentCopyIcon className="cursor-pointer" />
                          </CopyToClipboard>
                        </>
                      ) : (
                        'Not Available'
                      )}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="p-2 flex items-center">
                  <div className="w-[30%]">
                    <span className="font-bold text-xl">Gender</span>
                  </div>
                  <div className="">
                    <span className="capitalize text-xl">
                      {studentData?.data?.student?.gender ?? 'Non-binary'}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="p-2 flex items-center">
                  <div className="w-[30%]">
                    <span className="font-bold text-xl">Age</span>
                  </div>
                  <div className="">
                    <span className="capitalize text-xl">
                      {studentData?.data?.student?.age ?? 'Not Available'}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="p-2 flex items-center">
                  <div className="w-[30%]">
                    <span className="font-bold text-xl">
                      Level of Education
                    </span>
                  </div>
                  <div className="">
                    <span className="capitalize text-xl">
                      {studentData?.data?.student?.level_of_education ??
                        'Not Available'}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="p-2 flex items-center">
                  <div className="w-[30%]">
                    <span className="font-bold text-xl">
                      Modules Specialized
                    </span>
                  </div>
                  <div className="">
                    <span className="capitalize text-xl">
                      {modulesSpecialized ?? 'Not Available'}
                    </span>
                  </div>
                </div>
                <hr />
                {studentData?.data?.student?.cohort && (
                  <>
                    <div className="p-2 flex items-center">
                      <div className="w-[30%]">
                        <span className="font-bold text-xl">
                          Scholarship Cohort
                        </span>
                      </div>
                      <div className="">
                        <span className="capitalize text-xl">
                          {studentData?.data?.student?.cohort?.name ??
                            'Not Available'}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className="p-2 flex items-center">
                      <div className="w-[30%]">
                        <span className="font-bold text-xl">
                          Scholarship Certification
                        </span>
                      </div>
                      <div className="">
                        <span
                          className={`rounded-md p-1 text-white capitalize text-xl ${
                            studentData?.data?.student
                              ?.is_scholarship_certification_approved
                              ? 'bg-persian-500'
                              : 'bg-claret-500'
                          }`}
                        >
                          {studentData?.data?.student
                            ?.is_scholarship_certification_approved
                            ? 'Approved'
                            : 'Not Approved'}
                        </span>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                <div className="p-2 flex items-center">
                  <div className="w-[30%]">
                    <span className="font-bold text-xl">Bio</span>
                  </div>
                  <div className="max-w-[70%]">
                    <span className="text-base line-clamp-5">
                      {studentData?.data?.student?.profile_desc ??
                        'Not Available'}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="p-2 flex items-center">
                  <div className="w-[30%]">
                    <span className="font-bold text-xl">Last Login</span>
                  </div>
                  <div className="">
                    <span className="capitalize text-xl">
                      {studentData?.data?.student?.last_login
                        ? new Date(
                            studentData?.data?.student?.last_login
                          ).toLocaleString(undefined, {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                        : 'No Record'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              {isFetchingStudentAssignmentSubmissionsData ? (
                <Spinner />
              ) : (
                <TableWrapper
                  title="Learner's Weekly Assignments Submissions"
                  data={
                    studentAssignmentSubmissionsData?.data?.submissions || []
                  }
                  columns={studentSubmittedAssignmentColumns}
                />
              )}
            </div>
            <div className="">
              {isFetchingStudentQuizResultsData ? (
                <Spinner />
              ) : (
                <TableWrapper
                  title="Learner's Weekly Quizes Results"
                  data={
                    studentQuizResultsData?.data?.student_quiz_results || []
                  }
                  columns={studentQuizResultsColumns}
                />
              )}
            </div>
            <div className="">
              {isFetchingStudentGroupsData ? (
                <Spinner />
              ) : (
                <TableWrapper
                  title="Learner's Groups"
                  data={studentGroupsData?.data?.group_members || []}
                  columns={studentGroupsColumns}
                />
              )}
            </div>
            <div className="flex flex-col gap-4">
              <div className="bg-white px-4 py-5 rounded-md shadow-md">
                <div className="flex align-middle justify-between">
                  <p className="flex justify-center items-center py-2 text-xl font-bold text-persian-500">
                    Education Background
                  </p>
                </div>
                <div className="space-y-4">
                  {studentData?.data?.student?.education_backgrounds &&
                    studentData?.data?.student?.education_backgrounds?.map(
                      (education_background) => (
                        <div
                          key={education_background.id}
                          className="text-gray-500 text-sm "
                        >
                          <div className="w-full flex flex-row space-x-6">
                            <div>
                              <img src={school} alt="" className="h-10" />
                            </div>
                            <div className="w-full">
                              <div className="flex flex-row justify-between items-center">
                                <p className="text-black font-normal text-lg">
                                  {education_background?.institution}
                                </p>
                              </div>
                              <p>{education_background?.course_taken}</p>
                              <p>
                                {education_background?.level_of_education
                                  ?.split('_')
                                  ?.join(' ')}
                              </p>
                              <p className="">
                                {`${education_background?.city || ''}, ${
                                  education_background?.country || ''
                                }`}
                              </p>
                              <div className="flex flex-row text-xs pb-4">
                                <p className="">
                                  {new Date(
                                    education_background?.start_date
                                  ).toLocaleDateString('en-us', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                  })}{' '}
                                  to &nbsp;
                                </p>
                                {education_background?.is_current === true ? (
                                  <p>Present</p>
                                ) : (
                                  <p>
                                    {new Date(
                                      education_background?.end_date
                                    )?.toLocaleDateString('en-us', {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                    })}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="bg-white px-4 py-5 rounded-md shadow-md">
                <div className="flex align-middle justify-between">
                  <p className="flex justify-center items-center py-2 text-xl font-bold text-persian-500">
                    Work Experience
                  </p>
                </div>
                <div className="space-y-4">
                  {studentData?.data?.student?.work_experiences &&
                    studentData?.data?.student?.work_experiences?.map(
                      (work) => (
                        <div key={work?.id}>
                          <div className="text-gray-500 text-sm flex flex-row w-full space-x-6">
                            <div>
                              <img src={logo} alt="" className="h-10" />
                            </div>
                            <div className="flex flex-col w-full">
                              <div className="">
                                <div className="flex justify-between items-center">
                                  <p className="font-normal text-black text-lg">
                                    {work?.position}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                <p>{work?.organisation}</p>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                <p>
                                  {' '}
                                  {`${work?.city || ''}, ${
                                    work?.country || ''
                                  }`}
                                </p>
                              </div>
                              <div className="flex flex-row text-xs pb-4">
                                <p className="">
                                  {new Date(
                                    work?.start_date
                                  ).toLocaleDateString('en-us', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                  })}{' '}
                                  - &nbsp;
                                </p>
                                {work?.is_current === true ? (
                                  <p>Present</p>
                                ) : (
                                  <p>
                                    {new Date(
                                      work?.end_date
                                    ).toLocaleDateString('en-us', {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                    })}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md shadow-md">
              <div className="pb-2 ">
                <h3 className="font-roboto text-xl font capitalize">
                  Student&apos;s Enrolled Modules
                </h3>
              </div>
              <div className=" grid grid-cols-3 gap-4 p-2 ">
                {isFetchingModuleProgressData ? (
                  <Spinner />
                ) : (
                  studentModuleProgressData?.data?.student_progress?.modules?.map(
                    (student_enrolled_module) => (
                      <StudentEnrolledModule
                        key={student_enrolled_module.module_id}
                        enrolled_module={{
                          id: student_enrolled_module?.module_id,
                          name: student_enrolled_module?.module_name,
                          image: student_enrolled_module?.module_image,
                          category: student_enrolled_module.module_category,
                          completion: student_enrolled_module.completion,
                          is_paid_module:
                            student_enrolled_module?.is_paid_module ?? false,
                          start_date: student_enrolled_module?.start_date ?? '',
                        }}
                      />
                    )
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </MainContent>
      <Modal
        modalOpen={editStudentModalOpen}
        toggleModal={toggleEditStudentModal}
        title="Edit Student Profile"
      >
        <EditStudentProfile
          toggleModal={toggleEditStudentModal}
          currentStudentDetails={studentData?.data?.student}
        />
      </Modal>
    </>
  );
}

export default StudentDetailsPage;
