import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import logo from '../../assets/images/logo.png';
import Spinner from '../../components/spinner/Spinner';

function ResetPassword() {
  const formSchema = yup
    .object({
      email: yup
        .string()
        .required('Email required')
        .email('Enter a valid email'),
      otp: yup
        .string()
        .required('OTP required')
        .length(6, 'OTP should be 6 characters long'),
      new_password: yup
        .string()
        .required('This is a required field')
        .min(6, 'Password must be at least 6 characters')
        .max(15, 'Password must be less than 15 characters')
        .matches(
          /^.*(?=.{6,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Password must contain at least 6 characters, one uppercase letter and one number'
        ),
      confirm_new_password: yup
        .string()
        .required('Enter Password')
        .oneOf([yup.ref('new_password')], 'Passwords do not match'),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(formSchema) });

  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // reset inputs if successfully submitted

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);
  const baseURL =
    process.env.REACT_APP_BASE_URL_v2 ||
    'https://api.lms.v2.gateway.test.powerlearnprojectafrica.org';

  const onSubmit = async (data) => {
    setIsLoading(true);
    const requestData = { ...data };
    try {
      await axios.post(
        `${baseURL}/auth/administrator/reset-password`,
        requestData
      );
      toast.success('Password reset successfully');
      setIsLoading(false);
      window.location = '/login';
    } catch (error) {
      toast.error("Couldn't update password! Please try again!");
      setIsLoading(false);
      throw new Error(error);
    }
  };

  return (
    <div>
      <div className="flex flex-row h-screen max-h-screen">
        <div className="img-container flex flex-1 relative justify-center items-center">
          <div className="copyright absolute bottom-3">
            <p className="text-white">
              &#169; Power Learn Project 2022 all rights reserved
            </p>
          </div>
        </div>

        <div className="form flex-1 flex flex-col justify-center items-center max-h-screen">
          <div className="flex flex-col justify-center items-center">
            <img src={logo} alt="" className="h-14" />
            <h4 className="text-xl font-medium mb-5">Reset Password</h4>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="w-96 ">
            <div className="flex flex-col mb-5">
              <label
                htmlFor="email"
                className="text-xs text-mine-shaft-500 mb-1 pl-1"
              >
                Email Address
              </label>
              <input
                id="email"
                {...register('email', { required: true, maxLength: 15 })}
                className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
                  errors.email ? 'border-red-600 focus:outline-red-600' : ''
                }`}
                type="email"
                placeholder="Email"
              />
              {errors.email && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.email?.message}
                </span>
              )}
            </div>
            <div className="flex flex-col mb-5">
              <label
                htmlFor="otp"
                className="text-xs text-mine-shaft-500 mb-1 pl-1"
              >
                OTP
              </label>
              <input
                id="otp"
                {...register('otp', { required: true, maxLength: 15 })}
                className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
                  errors.otp ? 'border-red-600 focus:outline-red-600' : ''
                }`}
                autoComplete="true"
                type="password"
                placeholder="New Password"
              />
              {errors.otp && (
                <span className="text-red-600 text-xs mt-1">
                  {errors.otp?.message}
                </span>
              )}
            </div>
            <div className="flex flex-col mb-5">
              <label
                htmlFor="new_password"
                className="text-xs text-mine-shaft-500 mb-1 pl-1"
              >
                New Password
              </label>
              <input
                id="new_password"
                {...register('new_password', { required: true, maxLength: 15 })}
                className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
                  errors.new_password
                    ? 'border-red-600 focus:outline-red-600'
                    : ''
                }`}
                autoComplete="true"
                type="password"
                placeholder="New Password"
              />
              {errors.new_password && (
                <span className="text-red-600 text-xs mt-1">
                  {errors.new_password?.message}
                </span>
              )}
            </div>

            <div className="flex flex-col mb-7">
              <label
                htmlFor="confirm_new_password"
                className="text-xs text-mine-shaft-500 mb-1 pl-1"
              >
                Confirm Password
              </label>
              <input
                id="confirm_new_password"
                {...register('confirm_new_password', {
                  required: true,
                  maxLength: 15,
                })}
                className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
                  errors.confirm_new_password
                    ? 'border-red-600 focus:outline-red-600'
                    : ''
                }`}
                autoComplete="true"
                type="password"
                placeholder="Confirm New Password"
              />
              {errors.confirm_new_password && (
                <span className="text-red-600 text-xs mt-1">
                  {errors.confirm_new_password?.message}
                </span>
              )}
            </div>
            {isLoading ? (
              <div className="grid place-items-center">
                <Spinner />
              </div>
            ) : (
              <button
                type="submit"
                className="bg-claret-500 text-white w-full text-xs rounded-md py-2 mb-4"
              >
                SAVE NEW PASSWORD
              </button>
            )}
            <Link to="/login">
              <small className="text-persian-500">Back to Login</small>{' '}
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
