import React, { Fragment } from 'react';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Tab } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Calendar from '../components/calendar/Calendar';
import MainContent from '../components/MainContent';
import RightBar from '../components/RightBar';
import SponsoredCohortQuizResultsRawData from '../components/SponsoredCohortQuizResultsRawData';
import SponsoredCohortQuizAnalytics from '../components/SponsoredCohortQuizAnalytics';

function CohortQuizAnalytics() {
  const tabs = [
    {
      id: 1,
      title: 'Sponsored Cohort Quiz Analytics',
      component: <SponsoredCohortQuizAnalytics />,
    },
    {
      id: 2,
      title: 'Sponsored Cohort Quiz Results Raw Data',
      component: <SponsoredCohortQuizResultsRawData />,
    },
  ];

  return (
    <>
      <MainContent>
        <div className=" space-y-5">
          <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
            >
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/"
                className="flex items-center"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Home</p>
              </NavLink>
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/analytics"
                className="flex items-center"
              >
                <AnalyticsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Analytics</p>
              </NavLink>
            </Breadcrumbs>
          </div>
          <Tab.Group>
            <Tab.List className="flex rounded-md border border-claret-500 space-x-5 mb-6 p-2">
              {tabs.map((tab) => (
                <Tab as={Fragment} key={tab.id}>
                  {({ selected }) => (
                    <div
                      className={` focus:outline-none cursor-pointer p-2 hover:bg-lms-custom-50
                     ${
                       selected
                         ? ' text-[#8B173B] font-semibold border-b-2 border-[#8B173B]'
                         : ' hover:border-b-2 hover:border-lms-custom-100'
                     }
                    `}
                    >
                      <p>{tab.title}</p>
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels className="px-2">
              {tabs.map((tab) => (
                <Tab.Panel key={tab.id}>{tab.component}</Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default CohortQuizAnalytics;
