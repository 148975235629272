import React from 'react';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import Spinner from './spinner/Spinner';
import TableWrapper from '../utils/TableWrapper';

export default function WebinarParticipantsRawData({ webinarID }) {
  const columns = [
    { title: 'Email', field: 'email' },
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row?.firstname}</p>,
    },
    {
      title: 'Middle Name',
      field: 'middlename',
      render: (row) => <p className="capitalize">{row?.middlename || 'N/A'}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row?.lastname}</p>,
    },
    {
      title: 'Total Duration (minutes)',
      field: 'cummulative_duration_in_mins',
      render: (row) => (
        <p>
          {Math.round(Number(row?.cummulative_duration_in_mins || 0) * 100) /
            100}
        </p>
      ),
    },
    { title: 'Country', field: 'country' },
    { title: 'Phone Number', field: 'phone_number' },
    { title: 'Gender', field: 'gender' },
    { title: 'Academic Level', field: 'level_of_education' },
    { title: 'Cohort', field: 'cohort_name' },
  ];

  const { data: webinarAnalyticsOverviewData, isLoadingWebinarDetails } =
    useFetchAnalytics(
      ['webinar-analytics-overview', webinarID],
      `/class-sessions/${webinarID}/overview`,
      {},
      "Couldn't fetch webinar analytics overview. Please try again.",
      webinarID
    );

  const {
    data: classSessionParticipantsRawData,
    isLoadingWebinarParticipants,
  } = useFetchAnalytics(
    ['class-session-participants-raw-data', webinarID],
    `/class-sessions/participants/${webinarID}/raw-data`,
    {},
    "Couldn't fetch class session participants raw data. Please try again.",
    webinarID
  );

  return (
    <div className="">
      {isLoadingWebinarDetails && isLoadingWebinarParticipants && webinarID ? (
        <div className="flex items-center justify-center bg-white">
          <Spinner />
        </div>
      ) : (
        <TableWrapper
          title={`${
            webinarAnalyticsOverviewData?.data?.title || ''
          } Participants`}
          columns={columns}
          data={classSessionParticipantsRawData?.data?.participants || []}
        />
      )}
    </div>
  );
}
