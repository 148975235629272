/* eslint-disable react/self-closing-comp */
import React, { useState, useRef } from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import { Avatar, IconButton } from '@mui/material';
import ReactTimeAgo from 'react-time-ago';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useTruncatedElement from '../../hooks/useTruncatedElement';
import CommunityPostMedia from './CommunityPostMedia';
import PopoverWrapper from '../PostPopover';
import Modal from '../Modal';
import DeleteCommunityPost from '../modals/DeleteCommunityPost';
import FlagCommunityPost from '../modals/FlagCommunityPostModal';

function CommunityPostItem({
  post: {
    message,
    created_by,
    id,
    created_at,
    file_url,
    file_mime_type,
    flagged_count,
    latest_flagging_reason,
  },
}) {
  const [showReason, setShowReason] = useState(false);
  const [deleteCommunityPost, setDeleteCommunityPost] = useState(false);
  const [flagCommunityPost, setFlagCommunityPost] = useState(false);
  const [modifyPostId, setModifyPostId] = useState('');

  const toggleDeleteCommunityPostModal = (modifyId) => {
    setModifyPostId(modifyId);
    setDeleteCommunityPost(!deleteCommunityPost);
  };

  const toggleUnFlagCommunityPostModal = (modifyId) => {
    setModifyPostId(modifyId);
    setFlagCommunityPost(!flagCommunityPost);
  };

  const ref = useRef(null);
  const { isTruncated, isShowingMore, toggleIsShowingMore } =
    useTruncatedElement({
      ref,
    });

  const toggleShowReason = () => {
    setShowReason(!showReason);
  };

  return (
    <div className="bg-white shadow-md py-2 mt-4 rounded-md max-w-xl mx-auto">
      <div className="flex justify-between items-center  px-4">
        <div className=" flex items-center mb-2 ">
          <Avatar
            src={created_by?.profile_image}
            sx={{ width: 50, height: 50 }}
          >
            {created_by?.name?.toUpperCase().split(' ')[0][0]}
            {created_by?.name?.toUpperCase().split(' ')[1][0]}
          </Avatar>
          <div className="text-xs  font-roboto px-2">
            <p className="capitalize">{created_by?.name}</p>
            <p className="text-xs font-light">
              Posted{' '}
              <ReactTimeAgo
                className="text-xs font-light"
                date={new Date(created_at)}
              />
            </p>
          </div>
        </div>
        <div>
          <PopoverWrapper
            toggleDeleteModal={() => toggleDeleteCommunityPostModal(id)}
            toggleFlagModal={() => toggleUnFlagCommunityPostModal(id)}
          >
            <IconButton className="">
              <MoreHorizIcon />
            </IconButton>
          </PopoverWrapper>
        </div>
      </div>
      <div className="col-span-3 space-y-4">
        <div className="">
          <p
            ref={ref}
            id="post-content"
            style={{ width: '100%', lineHeight: '20px' }}
            className={`${
              isShowingMore ? '' : 'line-clamp-5'
            } duration-300 ease-in-out text-justify leading-normal  px-4`}
          >
            {message}
          </p>
        </div>
        {isTruncated && (
          <div
            className=" w-fit ml-auto cursor-pointer text-claret-500 flex justify-end text-xs  px-4"
            onClick={toggleIsShowingMore}
          >
            <p>{isShowingMore ? 'Read less' : 'Read more'}</p>
          </div>
        )}
        {file_url && (
          <CommunityPostMedia mimeType={file_mime_type} url={file_url} />
        )}
        <div className=" flex justify-between items-center  px-4">
          {/* {number_of_replies && ( */}
          <div
            className=" text-sm cursor-pointer flex space-x-1"
            onClick={toggleShowReason}
          >
            <CommentIcon className="text-sm text-black/80" fontSize="small" />
            <p>Reason</p>
          </div>
          {/* )} */}
          {flagged_count && (
            <div className="text-xs cursor-pointer">
              <p>
                {flagged_count}{' '}
                {`${flagged_count === 1 ? 'Total flag' : 'Total flags'}`}
              </p>
            </div>
          )}
        </div>
      </div>
      {showReason && (
        <div className=" px-4">
          <div className=" bg-gray-400/20 flex p-2 justify-between rounded-md text-justify">
            <div className=" flex items-start mb-2">
              <div className=" px-2">
                <p className="text-sm">{latest_flagging_reason}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Delete Post"
        modalOpen={deleteCommunityPost}
        toggleModal={toggleDeleteCommunityPostModal}
      >
        <DeleteCommunityPost
          postId={modifyPostId}
          setModifyPostId={setModifyPostId}
          toggleModal={toggleDeleteCommunityPostModal}
        />
      </Modal>
      <Modal
        title="Unflag this post"
        modalOpen={flagCommunityPost}
        toggleModal={toggleUnFlagCommunityPostModal}
      >
        <FlagCommunityPost
          postId={modifyPostId}
          setModifyPostId={setModifyPostId}
          toggleModal={toggleUnFlagCommunityPostModal}
        />
      </Modal>
    </div>
  );
}

export default CommunityPostItem;
