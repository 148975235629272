import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import { fileToBase64 } from '../helpers/convertToBase64';

const schema = yup
  .object({
    certTemplateImage: yup
      .mixed()
      .required('Please select an image')
      .test('fileSize', 'Please upload an image less than 10MB', (value) => {
        const maxSize = 10485760; // 10 MB
        return value && value.length > 0 ? value[0]?.size <= maxSize : true;
      }),
    nameStyleClassnames: yup
      .string()
      .required(
        'Please enter the Tailwind CSS classnames for the student name'
      ),
    specializationStyleClassnames: yup
      .string()
      .required(
        'Please enter the Tailwind CSS classnames for the modules specialization'
      ),
    certificationDateStyleClassnames: yup
      .string()
      .required(
        'Please enter the Tailwind CSS classnames for the certification date'
      ),
    validCertQRCodeStyleClassnames: yup
      .string()
      .required(
        'Please enter the Tailwind CSS classnames for the valid cert QR code'
      ),
  })
  .required();

function CreateCohortCertSettings({ cohortId, toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const queryClient = useQueryClient();

  const postMutation = useMutation(
    (data) => axiosInstancev2.post(`/cohorts/${cohortId}/cert-settings`, data),
    {
      onSuccess: () => {
        toast.success('Cohort certificate settings successfully created!');
        toggleModal();
        setIsSubmitting(false);
        queryClient.invalidateQueries(['cohort-cert-settings', cohortId]);
        queryClient.invalidateQueries(['cohort-cert-settings-check', cohortId]);
      },
      onError: (error) => {
        toast.error(
          `Cohort certificate settings creation failed! Please try again. ${error?.response?.data?.error}`
        );
        setIsSubmitting(false);
      },
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const base64Url = data.certTemplateImage[0]
      ? await fileToBase64(data.certTemplateImage[0])
      : '';

    const requestBody = {
      cert_template: base64Url,
      name_style_classnames: data.nameStyleClassnames,
      specialization_style_classnames: data.specializationStyleClassnames,
      certification_date_style_classnames:
        data.certificationDateStyleClassnames,
      valid_cert_qr_code_style_classnames: data.validCertQRCodeStyleClassnames,
    };

    postMutation.mutate(requestBody);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-1">
          {imagePreviewUrl && (
            <img
              src={imagePreviewUrl}
              alt="Certificate Template Preview"
              className="w-64 h-64 object-contain mt-2"
            />
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="certTemplateImage" className="text-base">
            Certificate Template Image
          </label>
          <input
            {...register('certTemplateImage', {
              required: true,
            })}
            accept="image/gif, image/jpeg, image/png"
            type="file"
            id="certTemplateImage"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.certTemplateImage ? 'border-red-600' : ''
            }`}
            onChange={handleImageChange}
          />
          {errors.certTemplateImage && (
            <span className="text-red-600 text-xs mt-2">
              {errors.certTemplateImage?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="nameStyleClassnames" className="text-base">
            Name Style Classnames
          </label>
          <input
            {...register('nameStyleClassnames', {
              required: true,
            })}
            type="text"
            id="nameStyleClassnames"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.nameStyleClassnames ? 'border-red-600' : ''
            }`}
          />
          {errors.nameStyleClassnames && (
            <span className="text-red-600 text-xs mt-2">
              {errors.nameStyleClassnames?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="specializationStyleClassnames" className="text-base">
            Specialization Style Classnames
          </label>
          <input
            {...register('specializationStyleClassnames', {
              required: true,
            })}
            type="text"
            id="specializationStyleClassnames"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.specializationStyleClassnames ? 'border-red-600' : ''
            }`}
          />
          {errors.specializationStyleClassnames && (
            <span className="text-red-600 text-xs mt-2">
              {errors.specializationStyleClassnames?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label
            htmlFor="certificationDateStyleClassnames"
            className="text-base"
          >
            Certification Date Style Classnames
          </label>
          <input
            {...register('certificationDateStyleClassnames', {
              required: true,
            })}
            type="text"
            id="certificationDateStyleClassnames"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.certificationDateStyleClassnames ? 'border-red-600' : ''
            }`}
          />
          {errors.certificationDateStyleClassnames && (
            <span className="text-red-600 text-xs mt-2">
              {errors.certificationDateStyleClassnames?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="validCertQRCodeStyleClassnames" className="text-base">
            Valid Cert QR Code Style Classnames
          </label>
          <input
            {...register('validCertQRCodeStyleClassnames', {
              required: true,
            })}
            type="text"
            id="validCertQRCodeStyleClassnames"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.validCertQRCodeStyleClassnames ? 'border-red-600' : ''
            }`}
          />
          {errors.validCertQRCodeStyleClassnames && (
            <span className="text-red-600 text-xs mt-2">
              {errors.validCertQRCodeStyleClassnames?.message}
            </span>
          )}
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex justify-between mt-8">
            <button
              className="border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center"
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Cohort Cert Settings</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateCohortCertSettings;
