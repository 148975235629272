import React from 'react';
import { format } from 'date-fns';
import useParsedData from '../hooks/useParsedData';

function Message({ fromMe, text, sender, timeStamp }) {
  const message = useParsedData(text);
  return (
    <div
      className={`richtext px-3 overflow-x-hidden ${
        fromMe
          ? 'rounded-tr-lg rounded-bl-lg rounded-tl-lg ml-auto'
          : 'rounded-tr-lg rounded-br-lg rounded-tl-lg '
      } p-3 bg-gray-200 max-w-md space-y-1`}
    >
      <p className={`${fromMe ? 'hidden' : 'text-[#8b173b]'} text-xs`}>
        {sender}
      </p>
      {message}
      {timeStamp && (
        <p
          className={`${fromMe ? 'text-[#00999e]' : 'text-[#8b173b]'} text-xs `}
        >{` ${new Date(timeStamp).toLocaleTimeString()} ${format(
          new Date(timeStamp),
          'dd/MM/yyy'
        )}`}</p>
      )}
    </div>
  );
}

export default Message;
