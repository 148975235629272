import { useState } from 'react';
import Popover from '@mui/material/Popover';
// import Avatar from '@mui/material/Avatar';
import { Badge, IconButton } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';

export default function NotificationPopover({
  count,
  messages,
  readNotifications,
  readNotification,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Badge color="primary" badgeContent={count}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="flex  align-middle items-center ">
          <List>
            {messages.map((message) => (
              <ListItem
                key={message.id}
                disablePadding
                onClick={() => {
                  readNotification(message.id);
                  handleClose();
                }}
              >
                <div className="flex flex-col justify-start items-start">
                  <ListItemButton>
                    <ListItemText
                      style={{ textTransform: 'capitalize' }}
                      primary={message.message}
                    />
                  </ListItemButton>
                </div>
              </ListItem>
            ))}
            {count > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    readNotifications();
                    handleClose();
                  }}
                >
                  <ListItemText
                    style={{ textTransform: 'capitalize', color: 'blue' }}
                    primary="View All Notifications"
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              <p className="px-2">No new notifications</p>
            )}
          </List>
        </div>
      </Popover>
    </div>
  );
}
