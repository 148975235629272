import React from 'react';
import { Avatar } from '@mui/material';
import Rating from '@mui/material/Rating';
import ReactTimeAgo from 'react-time-ago';

function GroupSubmissionReviewItem({ review }) {
  return (
    <div className="bg-gray-400/20 flex p-2 justify-between rounded-md text-justify">
      <div className="flex items-start mb-2">
        <div className="">
          <Avatar
            src={review?.created_by?.profile_image}
            sx={{ width: 40, height: 40 }}
          >
            <p className="text-sm">
              {review?.created_by?.firstname?.toUpperCase()[0]}
            </p>
            <p className="text-sm">
              {review?.created_by?.lastname?.toUpperCase()[0]}
            </p>
          </Avatar>
        </div>
        <div className="px-2 space-y-2">
          <div className="text-xs font-roboto">
            <p className="capitalize">{`${review?.created_by?.firstname} ${review?.created_by?.lastname}`}</p>
          </div>
          <p className="text-sm line-clamp-4">{review?.remarks}</p>
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <ReactTimeAgo
          className="text-xs mb-2"
          date={new Date(review?.created_at)}
        />
        <Rating
          size="small"
          className="text-xs"
          name="read-only"
          value={review?.rating}
          readOnly
          precision={0.5}
        />
      </div>
    </div>
  );
}

export default GroupSubmissionReviewItem;
