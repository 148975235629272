import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Select from 'react-select';
import Spinner from './spinner/Spinner';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const groupOptions = [
  { value: 'Automatic', label: 'Automatic' },
  { value: 'Manual', label: 'Manual' },
  // { value: "self", label: "Self-Assignment" },
];

const schema = yup
  .object({
    join_mode: yup
      .string()
      .nullable()
      .required('Please select grouping option'),
    purpose: yup.string().required('Please enter a reason for the group'),
    size: yup.number('Must be a number').min(1, 'Minimun number is 1'),
    number_of_groups: yup
      .number('Must be a number')
      .min(1, 'Minimun number is 1')
      .required(),
    cohort: yup
      .object()
      .shape({
        label: yup.string().required('cohort is required (from label)'),
        value: yup.string().required('cohort is required'),
      })
      .nullable()
      .required('Please select a cohort'),
  })
  .required();

function CreateGroup({ toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [joinMode, setJoinMode] = useState('');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();
  const onGroupCreationSuccess = () => {
    toast.success('Group successfully created!');
    setIsSubmitting(false);
    toggleModal();
  };
  const onGroupCreationFailure = () => {
    toast.error('Group creation failed! Please try again');
    setIsSubmitting(false);
  };
  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/groups', {
        ...data,
        group_size: data.size,
      });
    },
    {
      onSuccess: () => {
        onGroupCreationSuccess();
        queryClient.invalidateQueries(['groups']);
      },
      onError: onGroupCreationFailure,
    }
  );

  // fetchCohorts
  const { data } = useFetchDatav2(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const options = data
    ? data?.data.cohorts
        .filter((cohort) => cohort.status !== 'ended')
        .map((cohort) => ({
          value: cohort.id,
          label: `${cohort.name} - ${cohort.no_of_students || 0} students`,
        }))
    : [];

  const onSubmit = async (requestData) => {
    setIsSubmitting(true);
    const { cohort, ...rest } = requestData;
    mutation.mutate({
      ...rest,
      cohort_id: cohort.value,
      action: requestData.join_mode,
    });
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-2 gap-4 ">
          <div className="flex flex-col space-y-1">
            <label htmlFor="cohort" className="text-base">
              Select Cohort
            </label>
            <Controller
              name="cohort"
              control={control}
              render={({ field }) => (
                <Select
                  // defaultValue={options[0]}
                  {...field}
                  isClearable
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={options}
                />
              )}
            />
            {errors.cohort && (
              <span className="text-red-600 text-xs mt-2">
                {errors.cohort?.message}
              </span>
            )}
          </div>
          <div>
            <p className="mb-2">Assignment Type</p>
            <div className="flex space-x-8">
              {groupOptions.map((groupOption) => (
                <div
                  className="flex space-x-1 items-center justify-center align-middle"
                  key={groupOption.value}
                >
                  <input
                    {...register('join_mode')}
                    type="radio"
                    name="join_mode"
                    // value={groupOption.value}
                    // id={groupOption.value}
                    // onChange={(event) => setJoinMode(event.target.value)}
                  />
                  <label htmlFor={groupOption.value} className="">
                    {groupOption.label}
                  </label>
                </div>
              ))}
            </div>
            {errors.join_mode && (
              <span className="text-red-600 text-xs mt-2">
                {errors.join_mode?.message}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="purpose" className="text-base">
              Group Purpose
            </label>
            <input
              {...register('purpose', { required: true })}
              type="text"
              id="purpose"
              placeholder="Group Purpose"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.purpose ? 'border-red-600' : ''
              }`}
            />
            {errors.purpose && (
              <span className="text-red-600 text-xs mt-2">
                {errors.purpose?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="size" className="text-base">
              Group Size
            </label>
            <input
              {...register('size', { required: true })}
              type="number"
              id="size"
              placeholder="Group Size"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.size ? 'border-red-600' : ''
              }`}
            />
            {errors.size && (
              <span className="text-red-600 text-xs mt-2">
                {errors.size?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="size" className="text-base">
              Number of Groups
            </label>
            <input
              {...register('number_of_groups', { required: true })}
              type="number"
              id="number_of_groups"
              placeholder="Number of Groups"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.size ? 'border-red-600' : ''
              }`}
            />
            {errors.size && (
              <span className="text-red-600 text-xs mt-2">
                {errors.number_of_groups?.message}
              </span>
            )}
          </div>
        </div>
        {/* {joinMode === 'Manual' && (
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col space-y-1">
              <label htmlFor="moduleName" className="text-base">
                Group Name
              </label>
              <input
                {...register('moduleName', { required: true })}
                type="text"
                id="moduleName"
                placeholder="Group Name"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.moduleName ? 'border-red-600' : ''
                }`}
              />
              {errors.moduleName && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.moduleName?.message}
                </span>
              )}
            </div>
          </div>
        )} */}
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Group</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateGroup;
