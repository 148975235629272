import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    weekName: yup.string().required('This is a required field'),
  })
  .required();

function AddWeek({ toggleModal }) {
  const { moduleId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // week functions
  const queryClient = useQueryClient();
  const onWeekCreationSuccess = () => {
    toast.success('Week Created Successfully');
    toggleModal();
  };

  const onWeekCreationFailure = () => {
    toast.error("Couldn't create week. Please try again!");
  };
  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/module-weeks', data);
    },
    {
      onSuccess: () => {
        onWeekCreationSuccess();
        queryClient.invalidateQueries(['module-weeks', moduleId]);
      },
      onError: onWeekCreationFailure,
    }
  );

  const onSubmit = async (data) => {
    // setIsLoading(true);
    mutation.mutate({ ...data, moduleId });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="flex flex-col space-y-1">
        <label htmlFor="weekName" className="text-base">
          Week
        </label>
        <input
          {...register('weekName', { required: true })}
          type="text"
          id="weekName"
          placeholder="Week Name"
          className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
            errors.weekName ? 'border-red-600' : ''
          }`}
        />
        {errors.weekName && (
          <span className="text-red-600 text-xs mt-2">
            {errors.weekName?.message}
          </span>
        )}
      </div>
      {mutation.isLoading ? (
        <div className="grid justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
            type="submit"
          >
            <SaveIcon fontSize="inherit" />
            <p>Add Week</p>
          </button>
        </div>
      )}
    </form>
  );
}

export default AddWeek;
