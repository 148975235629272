import React, { useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-hot-toast';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

function IssueClaims({ cohortId, toggleModal, cohortName }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    axiosInstancev2
      .post('/blockchain/cohorts/student/upload-data', {
        cohort_id: cohortId,
      })
      .then((response_data) => {
        toast.success(
          response_data?.data?.message ||
            `Uploading ${cohortName} students progress data! This might take a moment.`
        );
        setIsSubmitting(false);
        toggleModal();
      })
      .catch((error) => {
        toast.error(
          `Couldn't uplaod students progress data!. ${
            error?.response?.data?.error || ''
          }. Please try again`
        );
        setIsSubmitting(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <form
      className="text-gray-700 space-y-4"
      onSubmit={(event) => handleSubmit(event)}
    >
      <p>
        Are you sure you want to issue claims for <strong>{cohortName} </strong>{' '}
        students by uploading their progress data?
      </p>
      {isSubmitting ? (
        <div className="w-full flex align-middle justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
            type="submit"
          >
            <CachedIcon fontSize="inherit" />
            <p>Issue Claims</p>
          </button>
        </div>
      )}
    </form>
  );
}

export default IssueClaims;
