import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import usePermissions from '../hooks/usePermissions';
import TableWrapper from '../utils/TableWrapper';
import EditClassSession from './EditClassSession';
import Modal from './Modal';
import DeleteClassSession from './DeleteClassSession';
import useGetUserRole from '../hooks/useGetUserRole';

function ClassSessionsTableView({ classSessions, tableTitle }) {
  const navigate = useNavigate();

  const [editClassSessionModalOpen, setEditClassSessionModalOpen] =
    useState(false);
  const [deleteClassSessionModalOpen, setDeleteClassSessionModalOpen] =
    useState(false);
  const [classSession, setClassSession] = useState({});
  const permissions = usePermissions();
  const role = useGetUserRole();

  const toggleEditClassSessionModal = () => {
    setEditClassSessionModalOpen(!editClassSessionModalOpen);
  };

  const toggleDeleteClassSessionModal = () => {
    setDeleteClassSessionModalOpen(!deleteClassSessionModalOpen);
  };

  const currentTime = new Date();

  const columns = [
    { title: 'Title', field: 'title' },
    { title: 'Webinar ID', field: 'webinarID' },
    {
      title: 'Registration Opens On',
      field: 'registrationOpenHours',
      render: (row) => {
        const startDate = new Date(row.startDate);
        const registrationOpenHours = row.registrationOpenHours || 120; // Default to 5 days - 120 hours if not provided
        const registrationOpenDate = new Date(
          startDate.getTime() - registrationOpenHours * 60 * 60 * 1000
        );

        return <p>{registrationOpenDate.toLocaleString()}</p>;
      },
    },
    {
      title: 'Start Date',
      field: 'startDate',
      render: (row) => <p>{new Date(row.startDate).toLocaleString()}</p>,
    },
    {
      title: 'End Date',
      field: 'endDate',
      render: (row) => <p>{new Date(row.endDate).toLocaleString('en-us')}</p>,
    },
    {
      title: 'Actions',
      render: (row) => (
        <div className="flex space-x-2">
          {(permissions?.includes('ViewClassSessionAnalytics') ||
            role === 'SuperAdmin') &&
            currentTime > new Date(row.endDate) && (
              <button
                className="border-[2px] border-claret-500 text-claret-500 px-4 py-1 rounded-md flex items-center justify-center gap-1"
                type="button"
                onClick={() => {
                  navigate(`/class-sessions/${row.id}/${row.webinarID}`);
                }}
              >
                <AnalyticsIcon className="inherit" />
                Analytics
              </button>
            )}
          {(permissions?.includes('UpdateClassSession') ||
            role === 'SuperAdmin') && (
            <button
              className="bg-persian-500 text-white px-4 py-1 rounded-md flex items-center justify-center gap-1"
              type="button"
              onClick={() => {
                toggleEditClassSessionModal();
                setClassSession(row);
              }}
            >
              <EditIcon className="inherit" />
              Edit
            </button>
          )}
          {(permissions?.includes('DeleteClassSession') ||
            role === 'SuperAdmin') && (
            <button
              className="bg-claret-500 text-white px-4 py-1 rounded-md flex items-center justify-center gap-1"
              type="button"
              onClick={() => {
                toggleDeleteClassSessionModal();
                setClassSession(row);
              }}
            >
              <DeleteIcon className="inherit" />
              Delete
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="mt-4">
      <TableWrapper title={tableTitle} columns={columns} data={classSessions} />
      <Modal
        modalOpen={editClassSessionModalOpen}
        toggleModal={toggleEditClassSessionModal}
        title="Update Class Session"
      >
        <EditClassSession
          classSessionData={classSession}
          toggleModal={toggleEditClassSessionModal}
        />
      </Modal>
      <Modal
        modalOpen={deleteClassSessionModalOpen}
        toggleModal={toggleDeleteClassSessionModal}
        title="Delete Class Session"
      >
        <DeleteClassSession
          toggleModal={toggleDeleteClassSessionModal}
          classSessionData={classSession}
        />
      </Modal>
    </div>
  );
}

export default ClassSessionsTableView;
