import React, { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import Spinner from '../components/spinner/Spinner';
import TableWrapper from '../utils/TableWrapper';
import Modal from '../components/Modal';
import CreateUser from '../components/CreateUser';
import MainContent from '../components/MainContent';
// import useFetchData from '../hooks/useFetchData';
import useFetchDatav2 from '../hooks/useFetchDatav2';

function Users() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const columns = [
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row.firstname}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row.lastname}</p>,
    },
    {
      title: 'Email',
      field: 'email',
      render: (row) => <p className="lowercase">{row.email}</p>,
    },
    {
      title: 'Last Login',
      field: 'last_login',
      render: (row) => (
        <p className="">
          {row.last_login
            ? new Date(row.last_login).toLocaleString(undefined, {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : 'No Record'}
        </p>
      ),
    },
  ];

  // TODO: configure pagination with the table
  const { data, isLoading } = useFetchDatav2(
    ['users'],
    '/users/administrator',
    {},
    "Couldn't get users. Please try again",
    true
  );
  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex w-full justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          <button
            type="button"
            className="bg-claret-500 text-white text-sm py-1 px-3 rounded-md cursor-pointer uppercase font-medium"
            onClick={toggleModal}
          >
            + Create New User
          </button>
        </div>
        <div className="mt-4">
          {isLoading || (!data && data?.data?.administrators?.length < 1) ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <TableWrapper
              title="List of Users"
              columns={columns}
              data={data ? data?.data?.administrators : []}
              onRowClick={(event, rowData) => navigate(`/users/${rowData.id}`)}
            />
          )}
        </div>
      </MainContent>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Create New User"
      >
        <CreateUser toggleModal={toggleModal} />
      </Modal>
    </>
  );
}

export default Users;
