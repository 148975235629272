import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import usePermissions from '../hooks/usePermissions';
import Modal from '../components/Modal';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';
import CreateTutorial from '../components/CreateTutorial';
import Spinner from '../components/spinner/Spinner';
import TutorialItem from '../components/TutorialItem';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import useFetchData from '../hooks/useFetchData';

function Tutorials() {
  const permissions = usePermissions();
  const role = useGetUserRole();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const { data, isLoading } = useFetchData(
    ['tutorials'],
    '/tutorials',
    {},
    "Couldn't get tutorials. Please try again.",
    true
  );

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          {/* <p>Home > Courses > Modules </p> */}
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          {(permissions?.includes('CreateTutorial') ||
            role === 'SuperAdmin') && (
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleModal}
            >
              Add Tutorial
            </button>
          )}
        </div>
        {isLoading ? (
          <div className="grid items-center justify-center my-auto mt-10">
            <Spinner />
          </div>
        ) : (
          <div className="px-2 py-5 rounded-lg mt-4 bg-white space-y-6">
            {!isLoading && !data?.data?.tutorials ? (
              <p className="text-center">No Tutorials Added Yet</p>
            ) : (
              data?.data?.tutorials?.map((tutorial) => (
                <div
                  key={tutorial.id}
                  className="cursor-pointer hover:scale-[101%] ease-in-out duration-300"
                >
                  <div className="opacity-0 h-0 group-hover:opacity-100 group-hover:h-full">
                    This Is The Overlay
                  </div>
                  <TutorialItem tutorial={tutorial} />
                </div>
              ))
            )}
          </div>
        )}

        <Modal
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          title="Create Tutorial"
        >
          <CreateTutorial toggleModal={toggleModal} />
        </Modal>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default Tutorials;
