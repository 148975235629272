import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

function DeleteClassSession({ toggleModal, classSessionData }) {
  const queryClient = useQueryClient();
  const onClassSessionDeletionSuccess = () => {
    toast.success('Class session successfully deleted!');
    toggleModal();
  };
  const onClassSessionDeletionFailure = () => {
    toast.error('Class session deletion failed! Please try again');
  };

  const { mutate, isLoading } = useMutation(
    () => {
      return axiosInstancev2.delete(`/class-sessions/${classSessionData?.id}`);
    },
    {
      onSuccess: () => {
        onClassSessionDeletionSuccess();
        queryClient.invalidateQueries(['class-sessions']);
        queryClient.invalidateQueries([
          'class-sessions',
          classSessionData?.cohortId,
        ]);
      },
      onError: onClassSessionDeletionFailure,
    }
  );

  return (
    <div>
      Are you sure you want to delete {classSessionData?.title} class session?
      {isLoading ? (
        <div className="grid place-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex justify-between mt-8">
          <button
            className="border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center"
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
            type="button"
            onClick={() => mutate()}
          >
            <SaveIcon fontSize="inherit" />
            <p>Delete Class Session</p>
          </button>
        </div>
      )}
    </div>
  );
}

export default DeleteClassSession;
