/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
// import InputField from './Input';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { logout } from '../features/auth/authSlice';
import NavPopover from './Popover';
import NotificationPopover from './NotificationPopover';
import useGetLoggedInUserId from '../hooks/useGetLoggedInUserId';
import { getNotifications } from '../features/notification/notificationSlice';
// import useNotifications from '../hooks/useNotifications';

const notificationSocketUrl =
  process.env.REACT_APP_NOTIFICATION_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnprojectafrica.org/notification/socket';

const socketConnection = new WebSocket(
  `${notificationSocketUrl}/notification?Authorization=${localStorage.getItem(
    'token'
  )}`
);

function Navbar() {
  // const [inputValue, setInputValue] = useState({ name: '' });
  const userDetails = useSelector((state) => state.auth.userDetails);
  const userNotifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUserId = useGetLoggedInUserId();
  // const [socketConnection] = useState(
  //   new WebSocket(`${webSocketUrl + localStorage.getItem('token')}`)
  // );

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const readNotificationHandler = (ids) => {
    // if (typeof ids === 'string') {
    //   return socketConnection.send(JSON.stringify({ notification_ids: [ids] }));
    // }
    return socketConnection.send(JSON.stringify({ notification_ids: [ids] }));
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setInputValue((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  return (
    <div className="z-10 py-6 fixed top-0 w-full px-6 bg-[#EFEFEF]  ">
      {/* <div className="h-[5vh] w-[93.75vw]  py-[2%] fixed top-0  bg-[#EFEFEF]  z-10 "> */}
      <nav className=" bg-white rounded-lg px-[2.8%] flex items-center justify-between h-20">
        <div className=" flex align-middle items-center space-x-10">
          <Link to="/">
            <img className="w-14 h-20 object-contain" src={logo} alt="logo" />
          </Link>
          <p>{new Date().toLocaleDateString()}</p>
          {/* <InputField
            type="text"
            value={inputValue.name}
            placeholder="Search"
            required
            name="name"
            onChange={handleChange}
          /> */}
        </div>
        <div className="flex items-center space-x-5 ">
          {/* <NotificationsNoneIcon className="" /> */}
          {/* <ChatBubbleOutlineIcon className="" /> */}

          <NotificationPopover
            count={userNotifications.count}
            messages={userNotifications.messages.slice(0, 3)}
            readNotification={readNotificationHandler}
            readNotifications={() => navigate('notifications')}
          />
          <div className="ml-3">
            <p>
              {userDetails?.firstname} {userDetails?.lastname}
            </p>
            {/* <p>ID: 123456789</p> */}
          </div>
          <NavPopover
            firstName={userDetails?.firstname}
            lastName={userDetails?.lastname}
            profilePic={userDetails?.profile_image}
            logout={handleLogout}
            visitProfile={() => navigate(`/users/${loggedInUserId}`)}
          />
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
