export const roles = [
  { id: 1, label: 'Administrator', value: 'Admin' },
  { id: 2, label: 'Module Lead', value: 'ModuleLead' },
  { id: 3, label: 'Instructor', value: 'Instructor' },
  { id: 4, label: 'Assistant Instructor', value: 'AssistantInstructor' },
  { id: 5, label: 'Community Manager', value: 'CommunityManager' },
  { id: 6, label: 'Data Analyst', value: 'DataAnalyst' },
  { id: 6, label: 'Support Admin', value: 'SupportAdmin' },
  { id: 6, label: 'Partner Admin', value: 'PartnerAdmin' },
];
