import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    weekName: yup.string().required('This is a required field'),
  })
  .required();

function EditWeek({ week, toggleModal }) {
  const { moduleId } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Initialize fields with current week details
  useEffect(() => {
    if (week) {
      setValue('weekName', week.weekName);
    }
  }, [week, setValue]);

  const queryClient = useQueryClient();

  const onWeekUpdateSuccess = () => {
    toast.success('Week updated successfully');
    toggleModal();
    setIsSubmitting(false);
  };

  const onWeekUpdateFailure = () => {
    toast.error("Couldn't update week. Please try again!");
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => axiosInstancev2.put(`/module-weeks/${week.id}`, data),
    {
      onSuccess: () => {
        onWeekUpdateSuccess();
        queryClient.invalidateQueries(['module-weeks', moduleId]);
      },
      onError: onWeekUpdateFailure,
    }
  );

  const onSubmit = async (formData) => {
    setIsSubmitting(true);
    mutation.mutate(formData);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="text-gray-700 space-y-2"
      >
        <div className="grid grid-cols-1 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="weekName" className="text-base">
              Week Name
            </label>
            <input
              id="weekName"
              {...register('weekName', { required: true })}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.weekName ? 'border-red-600' : ''
              }`}
              type="text"
              placeholder="Week Name"
            />
            {errors.weekName && (
              <span className="text-red-600 text-xs mt-2">
                {errors.weekName.message}
              </span>
            )}
          </div>
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex justify-between mt-8">
            <button
              className="border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center"
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Update</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default EditWeek;
