import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Fragment, useState } from 'react';
import { Tab } from '@headlessui/react';
import MainContent from '../components/MainContent';
import AllPosts from './AllPosts';
import FlaggedPosts from './FlaggedPosts';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';
import CreatePost from '../components/community/CreatePost';
import Modal from '../components/Modal';

function CommunityTabs() {
  const [createPostModalOpen, setCreatePostModalOpen] = useState(false);

  const tabs = [
    {
      title: 'All Posts',
      component: <AllPosts />,
    },
    {
      title: 'Flagged Posts',
      component: <FlaggedPosts />,
    },
  ];

  const toggleCreatePostModal = () => {
    setCreatePostModalOpen(!createPostModalOpen);
  };

  return (
    <>
      <MainContent>
        <div className=" fixed lg:w-3/5 pr-10 px-2 z-10  w-full ">
          <div className="bg-white px-2 py-5  flex w-full justify-between shadow-xl">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
            >
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/"
                className="flex items-center"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Home</p>
              </NavLink>
            </Breadcrumbs>
            <button
              onClick={toggleCreatePostModal}
              type="button"
              className="px-4 py-1 bg-claret-500 rounded-md text-white font-roboto text-sm"
            >
              Create Post
            </button>
          </div>
        </div>
        <Tab.Group>
          <div className="fixed mt-16 lg:w-3/5 pr-10 px-2 z-10 w-full">
            <Tab.List className="bg-white px-2 py-5  flex w-full justify-between shadow-xl">
              {tabs.map((tab) => (
                <Tab as={Fragment} key={tab.title}>
                  {({ selected }) => (
                    <div
                      className={`focus:outline-none cursor-pointer p-2 hover:bg-lms-custom-50  ${
                        selected
                          ? 'text-[#8B173B] border-b-2 border-[#8B173B] transition-all duration-300'
                          : 'hover:border-b-2 hover:border-lms-custom-100'
                      }`}
                    >
                      <p>{tab.title}</p>
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>
          </div>
          <Tab.Panels>
            {tabs.map((tab) => (
              <Tab.Panel key={tab.title}>{tab.component}</Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
      <Modal
        modalOpen={createPostModalOpen}
        toggleModal={toggleCreatePostModal}
        title="Create Post"
      >
        <CreatePost toggleModal={toggleCreatePostModal} />
      </Modal>
    </>
  );
}

export default CommunityTabs;
