import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Select from 'react-select';
import Spinner from './spinner/Spinner';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    cohort: yup.object().shape({
      label: yup.string().required('Cohort is required (from label)'),
      value: yup.string().required('Cohort is required'),
    }),
  })
  .required();

function CreateForum({ toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [courseId, setCourseId] = useState('');

  const { control, handleSubmit } = useForm({ resolver: yupResolver(schema) });

  // getCohorts
  const { data: cohorts } = useFetchDatav2(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohorts
    ? cohorts?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: cohort.name,
      }))
    : [];

  // get courses
  const { data: courses } = useFetchDatav2(
    ['courses'],
    '/courses',
    {},
    "Couldn't fetch courses. Please try again!",
    true
  );

  const courseOptions = courses
    ? courses?.data?.courses?.map((course) => ({
        value: course.id,
        label: course.courseName,
      }))
    : [];

  // get modules

  const { data: modules } = useFetchDatav2(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't fetch modules. Please try again.",
    courseId
  );

  const moduleOptions = modules
    ? modules?.data?.course_modules?.map((module) => ({
        value: module.id,
        label: module.moduleName,
      }))
    : [];

  const queryClient = useQueryClient();

  const onForumCreationSuccess = () => {
    toast.success('Forum successfully created!');
    toggleModal();
    setIsSubmitting(false);
  };

  const onForumCreationFailure = () => {
    toast.error('Forum creation failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/forums', data);
    },
    {
      onSuccess: () => {
        onForumCreationSuccess();
        queryClient.invalidateQueries(['forums']);
      },
      onError: onForumCreationFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const formattedData = {
      cohort_id: data.cohort.value,
      courseId,
      module_names: data.modules?.map((module) => module.label),
    };
    mutation.mutate(formattedData);
  };

  return (
    <form className="text-gray-700 space-y-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-3 bg-white p-3 gap-4">
        <Controller
          name="cohort"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isClearable
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={cohortOptions}
            />
          )}
        />
        <Select
          // {...field}
          placeholder="Select Course"
          name="course"
          isClearable
          className="react-dropdown"
          classNamePrefix="dropdown"
          onChange={(event) => {
            setCourseId(event?.value || null);
          }}
          options={courseOptions}
          defaultValue=""
        />

        <Controller
          name="modules"
          control={control}
          render={({ field }) => (
            <Select
              placeholder="Select Module"
              isMulti
              {...field}
              isClearable
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={moduleOptions}
              defaultValue=""
            />
          )}
        />
      </div>

      {isSubmitting ? (
        <div className="grid place-items-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
            type="submit"
          >
            <SaveIcon fontSize="inherit" />
            <p>Create Forum</p>
          </button>
        </div>
      )}
    </form>
  );
}

export default CreateForum;
