import { useState } from 'react';
import Popover from '@mui/material/Popover';
import { IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FlagIcon from '@mui/icons-material/Flag';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteIcon from '@mui/icons-material/Delete';
// import useGetLoggedInUserId from '../hooks/useGetLoggedInUserId';

export default function CommunityPostPopover({
  toggleDeleteModal,
  toggleFlagModal,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  // const loggedInUserId = useGetLoggedInUserId();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        // anchorPosition={{ top: 200, left: 400 }}
      >
        <div className="flex  align-middle items-center ">
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  toggleFlagModal();
                  handleClose();
                }}
              >
                <ListItemIcon style={{ minWidth: '40px' }}>
                  <FlagIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ textTransform: 'capitalize', color: 'gray' }}
                  primary={` Unflag this post`}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  toggleDeleteModal();
                  handleClose();
                }}
              >
                <ListItemIcon style={{ minWidth: '40px' }}>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ textTransform: 'capitalize', color: 'gray' }}
                  primary={` Delete post`}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleClose();
                }}
              >
                <ListItemIcon style={{ minWidth: '40px' }}>
                  <HighlightOffIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ textTransform: 'capitalize', color: 'gray' }}
                  primary="Close"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Popover>
    </div>
  );
}
