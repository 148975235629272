import React, { useState } from 'react';
import Select from 'react-select';
import Spinner from './spinner/Spinner';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import useFetchData from '../hooks/useFetchData';
import { VerticalBar } from './charts/VerticalBar';

function SponsoredCohortAssignmentAnalytics() {
  const [cohortCourseId, setCohortCourseId] = useState('');
  const [cohortModules, setCohortModules] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [moduleId, setModuleId] = useState('');
  const [weekId, setWeekId] = useState('');
  const [cohortId, setCohortId] = useState('');

  // get courses

  const { data: courses } = useFetchData(
    ['courses', cohortCourseId],
    '/courses',
    { params: { courses_ids: JSON.stringify([cohortCourseId]) } },
    "Couldn't fetch courses. Please try again!",
    !!cohortId
  );

  const courseOptions = courses
    ? courses?.data?.courses?.map((course) => ({
        value: course.id,
        label: course.courseName,
      }))
    : [];

  // get modules
  const { data: courseModules } = useFetchData(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't fetch modules. Please try again!",
    courseId
  );

  const moduleOptions = courseModules
    ? courseModules?.data?.course_modules
        .filter((courseModule) => cohortModules.includes(courseModule.id))
        .map((courseModule) => ({
          value: courseModule.id,
          label: courseModule.moduleName,
        }))
    : [];

  // get module weeks

  const { data: moduleWeeks } = useFetchData(
    ['module-weeks', moduleId],
    `/module-weeks`,
    {
      params: { moduleId },
    },
    "Couldn't fetch weeks. Please try again!",
    moduleId
  );
  const weekOptions = moduleWeeks
    ? moduleWeeks?.data?.weeks?.map((week) => ({
        value: week.id,
        label: week.weekName,
      }))
    : [];

  const { data: cohortData } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohortData
    ? cohortData?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: `${cohort.name} (${cohort.no_of_students || 0} Students)`,
        enrolled_course: cohort?.course_id ? cohort.course_id : null,
        enrolled_modules: cohort?.modules ? cohort?.modules : [],
      }))
    : [];

  const { data, isLoading: assignmentsAnalyticsLoading } = useFetchAnalytics(
    ['assignments-analytics', weekId],
    `/assignments/${weekId}`,
    { params: { cohort_id: cohortId } },
    "Couldn't get assignments analytics. Please try again",
    weekId
  );

  return (
    <div className=" space-y-5">
      <div className="bg-white px-2 py-5 rounded-lg">
        <p>
          <span className="font-medium">Weekly Assignments Analytics: </span>
          <span className="underline underline-offset-1">
            Detailed insights into weekly assignments submissions by learners,
            segmented by cohort
          </span>
        </p>
      </div>
      <div className="grid grid-cols-4 bg-white p-3 gap-4">
        <Select
          //   {...field}
          isClearable
          placeholder="Select Cohort"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={cohortOptions}
          onChange={(event) => {
            setCohortCourseId(event?.enrolled_course);
            setCohortModules(event?.enrolled_modules);
            setCohortId(event?.value || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Course"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={courseOptions}
          onChange={(event) => {
            setCourseId(event?.value || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Module"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={moduleOptions}
          onChange={(event) => {
            setModuleId(event?.value || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Week"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={weekOptions}
          onChange={(event) => {
            setWeekId(event?.value || null);
          }}
        />
      </div>
      {weekId && assignmentsAnalyticsLoading ? (
        <div className="mt-5 flex justify-center bg-white">
          <Spinner />
        </div>
      ) : (
        <div className="mt-5 grid grid-cols-2 gap-2">
          <div className="bg-white h-[500px] col-span-2">
            <VerticalBar
              labels={data?.data?.labels}
              colour="#8B173B"
              title={`${data?.data?.title || ''} ${
                !data?.data?.datasets || data?.data?.datasets?.length === 0
                  ? 'No Available Data'
                  : ''
              }`}
              label={
                data?.data?.title ||
                'Total Week Assignment Submissions by Learners'
              }
              datasets={data?.data?.datasets || []}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SponsoredCohortAssignmentAnalytics;
