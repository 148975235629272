import React, { useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { instructionOptions } from '../utils/editorToolbar';
import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import SurveyDisclosureWrapper from '../components/SurveyDisclosureWrapper';
import Modal from '../components/Modal';

const schema = yup
  .object({
    option1: yup.string().required('This is a required field'),
    option2: yup.string().required('This is a required field'),
    option3: yup.string().required('This is a required field'),
    option4: yup.string().required('This is a required field'),
    option5: yup.string().required('This is a required field'),
  })
  .required();

function AddSurveyQuestions() {
  const [question, setQuestion] = useState('');

  const { surveyId, surveyName } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [questionId, setQuestionId] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const toggleDeleteModal = () => setOpenDeleteModal(!openDeleteModal);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { name: '' },
  });

  // wysiwyg
  const { quill, quillRef } = useQuill({
    modules: { toolbar: instructionOptions },
  });

  // wysiwyg

  const isQuestionEmpty = question === '<p><br></p>' || question === '';
  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setQuestion(quill.root.innerHTML);
      });
    }
  }, [quill, quillRef]);

  const disableButton = isQuestionEmpty;
  const clearEditors = () => {
    quill.setText('');
    reset();
  };

  // mutation to create quiz
  const queryClient = useQueryClient();
  const onQuestionCreationSuccess = () => {
    toast.success('Question successfully added!');
    clearEditors();
    setIsSubmitting(false);
  };
  const onQuestionCreationFailure = () => {
    toast.error("Couldn't add question! Please try again");
    setIsSubmitting(false);
  };

  const fetchSurveyQuestions = async () => {
    const response = await axiosInstancev2.get(
      `/surveys/questions/response/${surveyId}`
    );
    return response;
  };

  const onFetchSurveyFailure = () => {
    toast.error("Couldn't fetch surveys! Please try again");
  };

  const { data } = useQuery(
    ['survey-questions', surveyId],
    fetchSurveyQuestions,
    {
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
      onError: onFetchSurveyFailure,
    }
  );

  const mutation = useMutation(
    (requestData) => {
      return axiosInstancev2.post('/surveys/questions', requestData);
    },
    {
      onSuccess: () => {
        onQuestionCreationSuccess();
        queryClient.invalidateQueries(['survey-questions', surveyId]);
      },
      onError: onQuestionCreationFailure,
    }
  );

  const onQuestionDeletionSuccess = () => {
    toast.success('Question successfuly deleted');
    toggleDeleteModal();
    queryClient.invalidateQueries(['survey-questions', surveyId]);
  };

  const onQuestionDeletionError = () => {
    toast.error("Couldn't delete question. Please try again");
  };

  const { mutate, isLoading: deletingQuestion } = useMutation(
    () => {
      return axiosInstancev2.delete(`/surveys/questions/${questionId}`);
    },
    {
      onSuccess: onQuestionDeletionSuccess,
      onError: onQuestionDeletionError,
    }
  );

  const deleteQuestionHandler = () => {
    mutate(questionId);
  };

  const onSubmit = async (requestData) => {
    setIsSubmitting(true);
    const formattedData = {
      survey_id: surveyId,
      survey_questions: [
        {
          question,
          choices: [
            {
              answer_choice: requestData.option1,
            },
            {
              answer_choice: requestData.option2,
            },
            {
              answer_choice: requestData.option3,
            },
            {
              answer_choice: requestData.option4,
            },
            {
              answer_choice: requestData.option5,
            },
          ],
        },
      ],
    };
    mutation.mutate(formattedData);
  };

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex justify-between mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/courses"
            className="flex items-center"
          >
            <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Courses</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-3)}
            to={() => navigate(-3)}
            className="flex items-center"
          >
            <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Modules</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-2)}
            to={() => navigate(-2)}
            className="flex items-center"
          >
            <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Module Overview</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-1)}
            to={() => navigate(-1)}
            className="flex items-center"
          >
            <QuizIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Surveys</p>
          </NavLink>
        </Breadcrumbs>
      </div>
      {data?.data?.questions && (
        <SurveyDisclosureWrapper
          title={`Questions in ${surveyName}`}
          data={data?.data?.questions || []}
          toggleModal={toggleDeleteModal}
          setQuestionId={setQuestionId}
        />
      )}
      <form
        className="text-gray-700 space-y-2 p-4 bg-white mt-4 rounded-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="lessonContent" className="text-lg font-semibold">
            Question
          </label>
          <div ref={quillRef} />
          {isQuestionEmpty && (
            <span className="text-red-600 text-xs mt-2">
              Please Enter Question
            </span>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="option1" className="text-base">
              Option 1
            </label>
            <textarea
              {...register('option1', { required: true })}
              type="text"
              id="option1"
              placeholder="Option 1"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.option1 ? 'border-red-600' : ''
              }`}
              rows={2}
            />
            {errors.option1 && (
              <span className="text-red-600 text-xs mt-2">
                {errors.option1?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="option2" className="text-base">
              Option 2
            </label>
            <textarea
              {...register('option2', { required: true })}
              type="text"
              id="option2"
              placeholder="Option 2"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.option2 ? 'border-red-600' : ''
              }`}
              rows={2}
            />
            {errors.option2 && (
              <span className="text-red-600 text-xs mt-2">
                {errors.option2?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="option3" className="text-base">
              Option 3
            </label>
            <textarea
              {...register('option3', { required: true })}
              type="text"
              id="option3"
              placeholder="Option 3"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.moduleName ? 'border-red-600' : ''
              }`}
              rows={2}
            />
            {errors.option3 && (
              <span className="text-red-600 text-xs mt-2">
                {errors.option3?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="option4" className="text-base">
              Option 4
            </label>
            <textarea
              {...register('option4', { required: true })}
              type="text"
              id="option4"
              placeholder="Option 4"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.option4 ? 'border-red-600' : ''
              }`}
              rows={2}
            />
            {errors.option4 && (
              <span className="text-red-600 text-xs mt-2">
                {errors.option4?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="option5" className="text-base">
              Option 5
            </label>
            <textarea
              {...register('option5', { required: true })}
              type="text"
              id="option5"
              placeholder="Option 5"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.option5 ? 'border-red-600' : ''
              }`}
              rows={2}
            />
            {errors.option5 && (
              <span className="text-red-600 text-xs mt-2">
                {errors.option5?.message}
              </span>
            )}
          </div>
        </div>

        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-10">
            <div className="" />
            <div className="">
              <button
                disabled={disableButton}
                className={`px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center ${
                  disableButton
                    ? 'bg-gray-400 text-black cursor-not-allowed'
                    : 'bg-claret-500 '
                }`}
                type="submit"
              >
                <SaveIcon fontSize="inherit" />
                <p>Create Question</p>
              </button>
            </div>
          </div>
        )}
      </form>
      <Modal title="Delete Question" modalOpen={openDeleteModal}>
        <p>Are you sure you want to delete this question?</p>

        {deletingQuestion ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-10">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleDeleteModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="button"
              onClick={deleteQuestionHandler}
            >
              <DeleteIcon fontSize="inherit" />
              <p>Delete Question</p>
            </button>
          </div>
        )}
      </Modal>
    </MainContent>
  );
}

export default AddSurveyQuestions;
