/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Select from 'react-select';
import Spinner from './spinner/Spinner';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import useFetchData from '../hooks/useFetchData';
import TableWrapper from '../utils/TableWrapper';

export default function SponsoredCohortModuleProgressRawData() {
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [cohortCourseId, setCohortCourseId] = useState('');
  const [cohortModules, setCohortModules] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [courseName, setCourseName] = useState('');
  const [moduleId, setModuleId] = useState('');
  const [moduleName, setModuleName] = useState('');

  const columns = [
    { title: 'Email', field: 'email' },
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row?.firstname}</p>,
    },
    {
      title: 'Middle Name',
      field: 'middlename',
      render: (row) => <p className="capitalize">{row?.middlename}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row?.lastname}</p>,
    },
    {
      title: 'Completion (%)',
      field: 'percentage_completion',
      render: (row) => (
        <div
          className={`p-2 font-semibold text-white text-center border rounded-md  ${
            row?.percentage_completion >= 50
              ? 'bg-persian-500'
              : 'bg-claret-500'
          }`}
        >
          <p>{row?.percentage_completion}%</p>
        </div>
      ),
    },
    { title: 'Country', field: 'country' },
    { title: 'Phone Number', field: 'phone_number' },
    { title: 'Gender', field: 'gender' },
    { title: 'Academic Level', field: 'level_of_education' },
  ];

  const { data: cohortData } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohortData
    ? cohortData?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: `${cohort.name} (${cohort.no_of_students || 0} Students)`,
        enrolled_course: cohort?.course_id ? cohort.course_id : null,
        enrolled_modules: cohort?.modules ? cohort?.modules : [],
      }))
    : [];

  // get courses
  const { data: courses } = useFetchData(
    ['courses', cohortCourseId],
    '/courses',
    { params: { courses_ids: JSON.stringify([cohortCourseId]) } },
    "Couldn't fetch courses. Please try again!",
    !!cohortId
  );

  const courseOptions = courses
    ? courses?.data?.courses?.map((course) => ({
        value: course.id,
        label: course.courseName,
      }))
    : [];

  // get modules
  const { data: courseModules } = useFetchData(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't fetch modules. Please try again!",
    courseId
  );

  const moduleOptions = courseModules
    ? courseModules?.data?.course_modules
        .filter((courseModule) => cohortModules.includes(courseModule.id))
        .map((courseModule) => ({
          value: courseModule.id,
          label: courseModule.moduleName,
        }))
    : [];

  const { data: cohortModuleProgressRawData, isLoading } = useFetchAnalytics(
    ['cohort-module-progress-raw-data', cohortId, moduleId],
    `/raw-data/cohort-module-progress/${cohortId}/${moduleId}`,
    {},
    "Couldn't get cohort module progress raw data. Please try again",
    cohortId && moduleId
  );

  const flattenData = (data) => {
    return data.map((row) => ({
      firstname: row.student?.firstname || 'N/A',
      middlename: row.student?.middlename || 'N/A',
      lastname: row.student?.lastname || 'N/A',
      email: row.student?.email || 'N/A',
      phone_number: row.student?.phone_number || 'N/A',
      gender: row.student?.gender || 'N/A',
      level_of_education: row.student?.level_of_education || 'N/A',
      country: row.student?.country || 'N/A',
      module_name: row.module_name || 'N/A',
      percentage_completion:
        Math.round((row.percentage_completion || 0) * 100) / 100, // 2 decimal places
    }));
  };

  return (
    <div className=" space-y-5">
      <div className="bg-white px-2 py-5 rounded-lg">
        <p>
          <span className="font-medium">
            Sponsored Cohort learners Module Progress Raw Data:{' '}
          </span>
          <span className="underline underline-offset-1">
            Sponsored cohort learners module percentage completion, segmented by
            module.
          </span>
        </p>
      </div>
      <div className="grid grid-cols-4 bg-white p-3 gap-4">
        <Select
          //   {...field}
          isClearable
          placeholder="Select Scholarship Cohort"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={cohortOptions}
          onChange={(event) => {
            setCohortCourseId(event?.enrolled_course);
            setCohortModules(event?.enrolled_modules);
            setCohortId(event?.value || null);
            setCohortName(event?.label || '');
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Course"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={courseOptions}
          onChange={(event) => {
            setCourseId(event?.value || null);
            setCourseName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Module"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={moduleOptions}
          onChange={(event) => {
            setModuleId(event?.value || null);
            setModuleName(event?.label || null);
          }}
        />
      </div>
      <div className="mt-4">
        {isLoading && cohortId && moduleId ? (
          <div className="flex items-center justify-center bg-white">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title={`${cohortName}-${courseName}-${moduleName}-Module Progress Data`}
            columns={columns}
            data={flattenData(cohortModuleProgressRawData?.data?.data || [])}
            options={{
              rowStyle: {
                overflowWrap: 'break-word',
              },
            }}
          />
        )}
      </div>
    </div>
  );
}
