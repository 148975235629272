import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
export function PieChart({ labels, title, colours, dataset }) {
  const data = {
    labels,

    datasets: [
      {
        label: title,
        data: dataset,
        backgroundColor: colours,
        borderColor: colours,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  return <Pie data={data} options={options} />;
}
