import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function ViewerEditor({
  initialValue,
  min_height = 400,
  max_height = 900,
}) {
  return (
    <div className="mt-2">
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        licenseKey="gpl"
        initialValue={initialValue}
        init={{
          plugins: 'link image table tinycomments autoresize',
          toolbar: 'showcomments',
          menubar: false,
          readonly: true,
          editable_root: false,
          autoresize_bottom_margin: 10,
          autoresize_overflow_padding: 10,
          min_height,
          max_height,
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </div>
  );
}
