import React from 'react';

export default function OnboardStudentsCSVTemplate() {
  // Define headers for the CSV template in object format
  const headers = [
    { name: 'First Name', optional: false }, // Mandatory
    { name: 'Last Name', optional: false }, // Mandatory
    { name: 'Email', optional: false }, // Mandatory
    { name: 'Country of Residence', optional: false }, // Mandatory
    { name: 'Gender', optional: false }, // Mandatory
    { name: 'Phone Number', optional: true }, // Optional
    { name: 'Level of Education', optional: true }, // Optional
    { name: 'Age', optional: true }, // Optional
    { name: 'Middle Name', optional: true }, // Optional
  ];

  // Convert array of header objects to CSV format
  const convertToCSV = (data) => {
    // Map header objects to just their names
    const headerRow = data.map((header) => header.name);
    return [headerRow].map((row) => row.join(',')).join('\n');
  };

  // Function to handle download of the CSV template
  const downloadCSV = () => {
    const csvContent = convertToCSV(headers);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'onboard_cohort_template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex justify-center">
      <button
        onClick={downloadCSV}
        className="bg-persian-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
        type="button" // Changed to "button" to prevent form submission
      >
        Download Onboard Cohort Template (CSV)
      </button>
    </div>
  );
}
