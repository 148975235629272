import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import Modal from './Modal';
import Spinner from './spinner/Spinner';

function LessonVideoList({ videos }) {
  const { lessonId } = useParams();
  const queryClient = useQueryClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [videoId, setVideoId] = useState('');

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const onVideoDeletionSuccess = () => {
    toast.success('Video successfuly deleted');
    toggleModal();
    queryClient.invalidateQueries(['lesson-videos', lessonId]);
  };

  const onVideoDeletionError = () => {
    toast.error("Couldn't delete video. Please try again");
  };

  const { mutate, isLoading } = useMutation(
    (data) => {
      return axiosInstancev2.delete(`/week-lessons/videos/${data}`);
    },
    {
      onSuccess: onVideoDeletionSuccess,
      onError: onVideoDeletionError,
    }
  );

  const deleteVideoHandler = () => {
    mutate(videoId);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 4x gap-4 mt-4">
      {videos ? (
        videos?.map((video) => (
          // <video controls src={video.video_url} key={video.id} className="">
          //   {video.video_url}
          // </video>
          <div className="bg-gray-100 p-6 rounded-lg space-y-4" key={video.id}>
            <p className="text-sm">{video.name}</p>
            <video
              className="max-h-36 w-full"
              width="300"
              height="100"
              controls
            >
              <source src={video.video_url} type="video/mp4" />
              <track
                src="captions_en.vtt"
                kind="captions"
                srcLang="en"
                label="english_captions"
              />
            </video>
            <div className=" flex justify-between">
              <CopyToClipboard
                text={video.video_url}
                onCopy={() => {
                  toast.success('Video url copied');
                }}
              >
                <button
                  type="button"
                  className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer "
                >
                  Copy URL
                </button>
              </CopyToClipboard>
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer "
                onClick={() => {
                  setVideoId(video.id);
                  toggleModal();
                }}
              >
                Delete Video
              </button>
            </div>
          </div>
        ))
      ) : (
        <p className="text-lg font-semibold px-2">No videos added yet</p>
      )}
      <Modal title="Delete Video" modalOpen={modalOpen}>
        <p>Are you sure you want to delete this video?</p>

        {isLoading ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-10">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="button"
              onClick={deleteVideoHandler}
            >
              <DeleteIcon fontSize="inherit" />
              <p>Delete Video</p>
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default LessonVideoList;
