import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

function Weeks({ weeks }) {
  // const [lessons, setLessons] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [weekId, setWeekId] = useState('');
  const navigate = useNavigate();

  const { moduleId } = useParams();

  const toggle = (index) => {
    if (clicked === index) {
      setClicked(null);
      return;
    }

    setClicked(index);
  };

  // fetch lessons by course week
  const getLessonsByWeek = async () => {
    const data = await axiosInstancev2.get('/week-lessons', {
      params: { weekId },
    });
    return data;
  };

  const { data: lessons, isLoading } = useQuery(
    ['week-lessons', weekId],
    getLessonsByWeek,
    {
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
      enabled: !!weekId,
    }
  );

  return (
    <div className="w-full bg-white px-2">
      <div className="space-y-5 flex flex-col justify-start py-2">
        {weeks &&
          weeks?.map((week, key) => {
            return (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setWeekId(week.id);
                  toggle(key);
                }}
                key={week.id}
              >
                <div className="flex flex-row border-[1px] border-lms-custom-100 py-2 px-1 rounded-sm">
                  {clicked === key ? (
                    <p className="text-[#00999E] mr-2">-</p>
                  ) : (
                    <p className="mr-2">+</p>
                  )}

                  <div>
                    <h4>{week.weekName}</h4>
                  </div>
                </div>
                <div className={`${clicked === key ? 'block' : 'hidden'}`}>
                  {isLoading && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                  {!isLoading &&
                  lessons.data &&
                  lessons?.data?.lessons?.length > 0
                    ? lessons?.data?.lessons?.map((lesson, i) => (
                        <div
                          onClick={() =>
                            navigate(
                              `/courses/modules/${moduleId}/week/${weekId}/lesson/${lesson.id}`
                            )
                          }
                          key={lesson.id}
                          className={`flex space-x-5  py-4 ${
                            (i + 1) % 2 === 0 ? '' : 'bg-[#D0EFED]'
                          }`}
                        >
                          {/* <LibraryBooksIcon className={`text-[#00999E] `} /> */}
                          <p>
                            Lesson {i + 1} : {lesson.lessonTopic}
                          </p>
                        </div>
                      ))
                    : !isLoading && (
                        <p className="text-xl text-center mt-4">
                          No Lessons uploaded yet.
                        </p>
                      )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Weeks;
