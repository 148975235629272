import React, { Fragment, useState } from 'react';
import { Tab } from '@headlessui/react';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useParams } from 'react-router-dom';
import Spinner from './spinner/Spinner';
import { fileToBase64 } from '../helpers/convertToBase64';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import OnboardStudentsXLSXTemplate from './OnboardStudentsXLSXTemplate';
import OnboardStudentsCSVTemplate from './OnboardStudentsCsvTemplate';

const schema = yup
  .object({
    uploadedFile: yup
      .mixed()
      .required('Please select a csv file')
      .test('fileSize', 'Please upload a csv file less than 5MB', (value) => {
        return value && value[0].size <= 5000000;
      }),
  })
  .required();

function OnboardStudents({ toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { cohortId } = useParams();
  const [spreadSheetFileType, setSpreadSheetFileType] = useState('');
  const [spreadSheetFileTypeName, setSpreadSheetFileTypeName] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();
  const onStudentCreationSuccess = () => {
    toast.success('Onboarding successful! Generating DIDs may take a while.');
    toggleModal();
    setIsSubmitting(false);
  };
  const onStudentCreationFailure = () => {
    toast.error('Onboarding failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/users/onboard-students', data);
    },
    {
      onSuccess: () => {
        onStudentCreationSuccess();
        queryClient.invalidateQueries(['students', cohortId]);
        queryClient.invalidateQueries(['cohorts']);
      },
      onError: onStudentCreationFailure,
    }
  );
  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const base64Url = await fileToBase64(data?.uploadedFile[0]);
    const requestBody = {
      applicants_base64_data: base64Url,
      cohort_id: cohortId,
      spreadsheet_file_type: spreadSheetFileType,
    };

    mutation.mutate(requestBody);
  };

  const spreadSheetFileTypeOptions = [
    { label: 'XLSX', value: '.xlsx' },
    { label: 'XLS', value: '.xls' },
    { label: 'CSV', value: '.csv' },
  ];

  const tabs = [
    {
      id: 1,
      title: 'Onboard Cohort Template',
      component: (
        <div className="flex justify-between mt-6 py-2">
          <OnboardStudentsXLSXTemplate />
          <OnboardStudentsCSVTemplate />
        </div>
      ),
    },
    {
      id: 2,
      title: 'Onboard Learners',
      component: (
        <div>
          <form
            className="text-gray-700 space-y-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col space-y-1">
              <label htmlFor="spreadSheetFileType" className="text-base">
                Select SpreadSheet File Type
              </label>
              <Select
                isClearable
                placeholder="Select SpreadSheet File Type"
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={spreadSheetFileTypeOptions}
                onChange={(event) => {
                  setSpreadSheetFileType(event?.value || null);
                  setSpreadSheetFileTypeName(event?.label || null);
                }}
              />
            </div>
            {spreadSheetFileType && spreadSheetFileTypeName && (
              <div className="flex flex-col space-y-1">
                <label htmlFor="uploadedFile" className="text-base">
                  Upload {spreadSheetFileTypeName} File
                </label>
                <input
                  {...register('uploadedFile', {
                    required: true,
                  })}
                  accept={spreadSheetFileType}
                  type="file"
                  id="uploadedFile"
                  className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                    errors.uploadedFile ? 'border-red-600' : ''
                  }`}
                />
                {errors.uploadedFile && (
                  <span className="text-red-600 text-xs mt-2">
                    {errors.uploadedFile?.message}
                  </span>
                )}
              </div>
            )}
            {isSubmitting ? (
              <div className="w-full flex align-middle justify-center items-center">
                <Spinner />
              </div>
            ) : (
              <div className=" flex justify-end mt-8">
                <button
                  className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
                  type="submit"
                >
                  <SaveIcon fontSize="inherit" />
                  <p>Onboard Students</p>
                </button>
              </div>
            )}
          </form>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Tab.Group>
        <Tab.List className="flex border-b-2 border-gray-300 space-x-5 mb-6 px-2 pt-2">
          {tabs.map((tab) => (
            <Tab as={Fragment} key={tab.id}>
              {({ selected }) => (
                <div
                  className={` focus:outline-none cursor-pointer p-2 hover:bg-lms-custom-50
                     ${
                       selected
                         ? ' text-[#8B173B] font-semibold border-b-2 border-[#8B173B]'
                         : ' hover:border-b-2 hover:border-lms-custom-100'
                     }
                    `}
                >
                  <p>{tab.title}</p>
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="px-2">
          {tabs.map((tab) => (
            <Tab.Panel key={tab.id}>{tab.component}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <div className="flex justify-end mt-6 border-t-2 border-gray-300 py-2">
        <button
          className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
          type="button"
          onClick={toggleModal}
        >
          <CancelIcon fontSize="inherit" />
          <p>Close</p>
        </button>
      </div>
    </div>
  );
}

export default OnboardStudents;
