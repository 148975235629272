import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import DeleteIcon from '@mui/icons-material/Delete';
import parse from 'html-react-parser';
import useGetUserRole from '../hooks/useGetUserRole';

export default function SurveyDisclosureWrapper({
  data,
  title,
  toggleModal,
  setQuestionId,
}) {
  const userRole = useGetUserRole();
  return (
    <div className="w-full">
      <div className="mx-auto w-full rounded-md bg-white p-2">
        {title && <h3>{title}</h3>}
        {data.map((item) => (
          <Disclosure key={item.id} as="div" className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>{parse(`${item?.question}`)}</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-purple-500`}
                  />
                </Disclosure.Button>
                {item.choices.map((choice) => (
                  <Disclosure.Panel
                    key={choice.id}
                    className="px-4 pt-4 pb-2 text-sm flex items-center align-middle bg-gray-100 rounded-md mt-2"
                  >
                    <div className=" flex justify-between w-full">
                      <p>{choice?.answer_choice}</p>
                      {(userRole === 'SuperAdmin' || userRole === 'Admin') && (
                        <p>
                          <span className="font-medium">
                            {choice?.frequency || 0}
                          </span>{' '}
                          {choice?.frequency === 1 ? 'student' : 'students'}{' '}
                          selected this option
                        </p>
                      )}
                    </div>
                  </Disclosure.Panel>
                ))}
                {toggleModal && (
                  <Disclosure.Panel className=" my-4">
                    <button
                      className={`px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center bg-claret-500 `}
                      type="submit"
                      onClick={() => {
                        toggleModal();
                        setQuestionId(item.id);
                      }}
                    >
                      <DeleteIcon fontSize="inherit" />
                      <p>Delete Question</p>
                    </button>
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}
