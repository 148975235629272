import React, { useState } from 'react';
import Select from 'react-select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import useFetchData from '../hooks/useFetchData';
import Spinner from './spinner/Spinner';
import TableWrapper from '../utils/TableWrapper';
import useFetchAnalytics from '../hooks/useFetchAnalytics';

export default function SponsoredCohortQuizResultsRawData() {
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [cohortCourseId, setCohortCourseId] = useState('');
  const [cohortModules, setCohortModules] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [courseName, setCourseName] = useState('');
  const [moduleId, setModuleId] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [weekId, setWeekId] = useState('');
  const [weekName, setWeekName] = useState('');
  const [quizId, setQuizId] = useState('');
  const [quizName, setQuizName] = useState('');
  const [isFirstAttempt, setIsFirstAttempt] = useState(null);

  const columns = [
    { title: 'Email', field: 'email' },
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row?.firstname}</p>,
    },
    {
      title: 'Middle Name',
      field: 'middlename',
      render: (row) => <p className="capitalize">{row?.middlename}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row?.lastname}</p>,
    },
    {
      title: 'Is First Attempt',
      field: 'is_first_attempt',
    },
    {
      title: 'Passed',
      field: 'passed',
      render: (row) => <p>{row?.passed ? 'Yes' : 'No'}</p>,
    },
    {
      title: 'Result (%)',
      field: 'percentage_result',
      render: (row) => (
        <div
          className={`flex justify-between p-2 font-semibold text-white text-center border rounded-md  ${
            row?.passed ? 'bg-persian-500' : 'bg-claret-500'
          }`}
        >
          <p>{row?.percentage_result}%</p>
          {row?.passed ? (
            <CheckCircleIcon className="text-green-500 ml-2" />
          ) : (
            <CancelIcon className="text-red-500 ml-2" />
          )}
        </div>
      ),
    },
    { title: 'Country', field: 'country' },
    { title: 'Phone Number', field: 'phone_number' },
    { title: 'Gender', field: 'gender' },
    { title: 'Academic Level', field: 'level_of_education' },
  ];

  // get cohorts
  const { data: cohorts } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohorts
    ? cohorts?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: cohort.name,
        enrolled_course: cohort?.course_id ? cohort.course_id : null,
        enrolled_modules: cohort?.modules ? cohort?.modules : [],
      }))
    : [];

  // get courses
  const { data: courses } = useFetchData(
    ['courses', cohortCourseId],
    '/courses',
    { params: { courses_ids: JSON.stringify([cohortCourseId]) } },
    "Couldn't fetch courses. Please try again!",
    !!cohortId
  );

  const courseOptions = courses
    ? courses?.data?.courses?.map((course) => ({
        value: course.id,
        label: course.courseName,
      }))
    : [];

  // get modules
  const { data: courseModules } = useFetchData(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't fetch modules. Please try again!",
    courseId
  );

  const moduleOptions = courseModules
    ? courseModules?.data?.course_modules
        .filter((courseModule) => cohortModules.includes(courseModule.id))
        .map((courseModule) => ({
          value: courseModule.id,
          label: courseModule.moduleName,
        }))
    : [];

  // get module weeks

  const { data: moduleWeeks } = useFetchData(
    ['module-weeks', moduleId],
    `/module-weeks`,
    {
      params: { moduleId },
    },
    "Couldn't fetch weeks. Please try again!",
    moduleId
  );
  const weekOptions = moduleWeeks
    ? moduleWeeks?.data?.weeks?.map((week) => ({
        value: week.id,
        label: week.weekName,
      }))
    : [];

  // get quizes

  const { data: quizes } = useFetchData(
    ['week-quizes', weekId],
    '/quizes',
    {
      params: { weekId },
    },
    "Couldn't get quizes. Please try again!",
    weekId
  );
  const quizOptions = quizes
    ? quizes?.data?.quizes?.map((quiz) => ({
        value: quiz.id,
        label: quiz.quizName,
      }))
    : [];

  const isFirstAttemptOptions = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];

  const { data: cohortQuizResultsRawData, isLoading } = useFetchAnalytics(
    ['cohort-quiz-results-raw-data', cohortId, quizId, isFirstAttempt],
    `/raw-data/quiz-results/${cohortId}/${quizId}`,
    { params: { is_first_attempt: isFirstAttempt } },
    "Couldn't get cohort quiz results raw data. Please try again",
    cohortId && quizId && [true, false].includes(isFirstAttempt)
  );

  const flattenData = (data) => {
    return data.map((row) => ({
      firstname: row.student?.firstname || 'N/A',
      middlename: row.student?.middlename || 'N/A',
      lastname: row.student?.lastname || 'N/A',
      email: row.student?.email || 'N/A',
      phone_number: row.student?.phone_number || 'N/A',
      gender: row.student?.gender || 'N/A',
      level_of_education: row.student?.level_of_education || 'N/A',
      country: row.student?.country || 'N/A',
      is_first_attempt: row.is_first_attempt ? 'Yes' : 'No',
      passed: row.passed || false,
      percentage_result: row.percentage_result || 0,
    }));
  };

  return (
    <>
      <div className="bg-white px-2 py-5 rounded-lg">
        <p>
          <span className="font-medium">Cohort Quiz Results Raw Data: </span>
          <span className="underline underline-offset-1">
            Retrieve raw quiz results for a specific cohort and quiz. You can
            view detailed student results, and optionally filter the data by
            whether it was the student&apos;s first attempt at the quiz.
          </span>
        </p>
      </div>
      <div className="grid grid-cols-4 bg-white p-3 gap-4">
        <Select
          //   {...field}
          isClearable
          placeholder="Select Cohort"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={cohortOptions}
          onChange={(event) => {
            setCohortCourseId(event?.enrolled_course);
            setCohortModules(event?.enrolled_modules);
            setCohortId(event?.value || null);
            setCohortName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Course"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={courseOptions}
          onChange={(event) => {
            setCourseId(event?.value || null);
            setCourseName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Module"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={moduleOptions}
          onChange={(event) => {
            setModuleId(event?.value || null);
            setModuleName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Week"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={weekOptions}
          onChange={(event) => {
            setWeekId(event?.value || null);
            setWeekName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Quiz"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={quizOptions}
          onChange={(event) => {
            setQuizId(event?.value || null);
            setQuizName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Filter By Is First Attempt"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={isFirstAttemptOptions}
          onChange={(event) => {
            setIsFirstAttempt(event?.value);
          }}
        />
      </div>{' '}
      <div className="mt-4">
        {isLoading &&
        cohortId &&
        quizId &&
        [true, false].includes(isFirstAttempt) ? (
          <div className="flex items-center justify-center bg-white">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title={`${cohortName}-${courseName}-${moduleName}-${weekName}-${quizName}-Students Results Data(${
              isFirstAttempt ? 'FirstAttempts' : 'NonFirstAttempts'
            })`}
            columns={columns}
            data={flattenData(cohortQuizResultsRawData?.data || [])}
            options={{
              rowStyle: {
                overflowWrap: 'break-word',
              },
            }}
          />
        )}
      </div>
    </>
  );
}
