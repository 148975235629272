/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

function CommunityPostMedia({ mimeType, url }) {
  //   console.log('mimeType', mimeType);
  //   console.log('url', url);
  return (
    <div className="">
      {mimeType.split('/')[0] === 'image' && (
        <img src={url} alt="post" className="w-full" />
      )}
      {mimeType.split('/')[0] === 'video' && (
        <video controls className="w-full">
          <source src={url} type={mimeType} />
        </video>
      )}
      {mimeType.split('/')[0] === 'audio' && (
        <audio controls className="w-full px-4">
          <source src={url} type={mimeType} />
        </audio>
      )}
      {/* <p>{url}</p> */}
    </div>
  );
}

export default CommunityPostMedia;
