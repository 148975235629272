/* eslint-disable no-param-reassign */
import axios from 'axios';
import { toast } from 'react-hot-toast';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const baseURL =
  process.env.REACT_APP_BASE_URL_v2 ||
  'https://api.lms.v2.powerlearnprojectafrica.org/gateway/api';

const notificationSocketUrl =
  process.env.REACT_APP_NOTIFICATION_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnprojectafrica.org/notification/socket';

const initialState = {
  isAuthenticated: !!JSON.parse(localStorage.getItem('userDetails')),
  userDetails: JSON.parse(localStorage.getItem('userDetails')) || {},
  permissions: JSON.parse(localStorage.getItem('permissions')) || [],
  loading: false,
  error: '',
};

// send Login request
export const login = createAsyncThunk('auth/login', async (info) => {
  try {
    const { data } = await axios.post(
      `${baseURL}/auth/login/administrator`,
      info
    );
    localStorage.setItem('token', data.token);
    localStorage.setItem(
      'userDetails',
      JSON.stringify(data.loggedInAdministrator)
    );

    // eslint-disable-next-line no-unused-vars
    const connection = new WebSocket(
      `${notificationSocketUrl}/notification?Authorization=${data.token}`
    );

    if (data.permissions) {
      localStorage.setItem('permissions', JSON.stringify(data.permissions));
    } else {
      localStorage.setItem('permissions', JSON.stringify([]));
    }

    toast.success('Login Successful!');

    return data;
  } catch (error) {
    localStorage.clear();
    let errorMessage = '';
    if (error?.response?.status === 404) {
      errorMessage = "Account doesn't exist";
    } else if (error?.response?.status === 400) {
      errorMessage = 'Invalid Credentials';
    } else {
      errorMessage = error.message;
    }

    throw Error(errorMessage);
  }
});

// send register student request
// export const registerStudent = createAsyncThunk(
//   'auth/registerStudent',
//   async (info) => {
//     try {
//       const { data } = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/users/student`,
//         info
//       );
//       localStorage.setItem('token', data.token);
//       localStorage.setItem('userDetails', JSON.stringify(data.loggedInAdministrator));
//       return data;
//     } catch (error) {
//       localStorage.clear();
//       throw Error(error.response.data);
//     }
//   }
// );

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.isAuthenticated = false;
      state.userDetails = {};
      state.loading = false;
      state.permissions = [];
      state.error = '';
    },
    updateDetails: (state, action) => {
      localStorage.setItem('userDetails', JSON.stringify(action.payload));
      state.userDetails = action.payload;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.isAuthenticated = false;
      state.error = '';
    },
    [login.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.userDetails = action.payload.loggedInAdministrator;
      state.permissions = action.payload.permissions;
      state.error = '';
    },
    [login.rejected]: (state, action) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export const { logout, updateDetails } = authSlice.actions;

export default authSlice.reducer;
