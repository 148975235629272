import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function AnalyticsCard({ cardName, cardDescription, componentLink, children }) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        maxWidth: 300,
        transform: 'translateZ(0)', // Prevents blurriness on scale transform
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        },
      }}
      className="shadow-md rounded-md m-4 hover:scale-105 ease-in-out duration-300 hover:drop-shadow-lg"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px', // Add some padding for spacing
        }}
      >
        {children}
      </div>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 160, // Fixed height to ensure the button doesn't move
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            maxHeight: 60,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            textAlign: 'center',
          }}
        >
          {cardName}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            maxHeight: 60,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            textAlign: 'center',
          }}
        >
          {cardDescription}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          className="w-full"
          size="small"
          onClick={() => componentLink && navigate(componentLink)}
          sx={{
            backgroundColor: '#8B173B', // claret-500 color
            paddingX: '16px', // px-4 in Tailwind
            paddingY: '4px', // py-1 in Tailwind
            borderRadius: '8px', // rounded-md in Tailwind
            color: 'white', // text-white in Tailwind
            fontWeight: 'bold', // font-bold
            '&:hover': {
              backgroundColor: '#2B0712', // slightly darker shade on hover, claret-700
            },
          }}
        >
          View Analytics
        </Button>
      </CardActions>
    </Card>
  );
}

export default AnalyticsCard;
