import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Spinner from './spinner/Spinner';
import { fileToBase64 } from '../helpers/convertToBase64';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import useFetchDatav2 from '../hooks/useFetchDatav2';

const schema = yup
  .object({
    headerCsv: yup
      .mixed()
      .required('Please select a csv file')
      .test('fileSize', 'Please upload a csv file less than 5MB', (value) => {
        return value && value[0].size <= 5000000;
      })
      .test('fileType', 'Only .csv files supported', (value) =>
        ['text/csv'].includes(value[0].type)
      ),
    paid_module: yup
      .object()
      .shape({
        label: yup.string().required('paid_module is required (from label)'),
        value: yup.string().required('paid_module is required'),
      })
      .nullable()
      .required('Please select a paid module'),
  })
  .required();

function EnrolStudentsToAdvancedModules({ paidCohortId, toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const is_paid_module = true;

  const { data: paidModules } = useFetchDatav2(
    ['course-modules', is_paid_module],
    `/course-modules`,
    {
      params: { is_paid_module },
    },
    "Couldn't fetch paid modules. Please try again!",
    true
  );

  const paidoduleOptions = paidModules
    ? paidModules?.data?.course_modules
        ?.filter((paidModule) => paidModule.is_published === true)
        ?.map((paidModule) => ({
          value: paidModule.id,
          label: paidModule.moduleName,
        }))
    : [];

  const queryClient = useQueryClient();

  const onEnrolAdvancedStudentsSuccess = (response_data) => {
    toast.success(
      response_data?.data?.message ||
        'Enrolling advanced students! Await Notification.'
    );
    toggleModal();
    setIsSubmitting(false);
  };

  const onEnrolAdvancedStudentsFailure = (error) => {
    toast.error(
      error?.response?.data?.error ||
        'Advanced Students Enrollment failed! Please try again'
    );
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/student-paid-modules/by-admin', data);
    },
    {
      onSuccess: (response_data) => {
        onEnrolAdvancedStudentsSuccess(response_data);
        queryClient.invalidateQueries(['students', paidCohortId]);
      },
      onError: onEnrolAdvancedStudentsFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const base64Url = await fileToBase64(data.headerCsv[0]);
    const requestBody = {
      paid_cohort_id: paidCohortId,
      paid_module_id: data.paid_module.value,
      students_csv: base64Url,
    };

    mutation.mutate(requestBody);
  };

  return (
    <div>
      {' '}
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="paid_module" className="text-base">
            Select Paid Module
          </label>
          <Controller
            name="paid_module"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isClearable
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={paidoduleOptions}
                onChange={(event) => {
                  field.onChange(event);
                }}
              />
            )}
          />
          {errors.paid_module && (
            <span className="text-red-600 text-xs mt-2">
              {errors.paid_module?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="headerCsv" className="text-base">
            Header CSV
          </label>
          <input
            {...register('headerCsv', {
              required: true,
            })}
            accept=".csv"
            type="file"
            id="headerCsv"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.headerCsv ? 'border-red-600' : ''
            }`}
          />
          {errors.headerCsv && (
            <span className="text-red-600 text-xs mt-2">
              {errors.headerCsv?.message}
            </span>
          )}
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Enrol Students</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default EnrolStudentsToAdvancedModules;
