import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import useFetchCommunityData from '../hooks/useFetchCommunityData';
import Spinner from '../components/spinner/Spinner';
import MainContent from '../components/MainContent';
import CommunityPostItem from '../components/community/CommunityPostItem';

const communityPostNotifierSocketUrl = `${
  process.env.REACT_APP_COMMUNITY_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnprojectafrica.org/community/api'
}/new_post_event?Authorization=`;

function AllPosts() {
  const { data, isLoading } = useFetchCommunityData(
    ['community-posts'],
    '/posts',
    {
      params: { $limit: 200 },
    },
    "couldn't get posts. please try again",
    true
  );

  const queryClient = useQueryClient();

  const [newPosts, setNewPosts] = useState(false);

  const handleNewPosts = () => {
    queryClient.invalidateQueries(['community-posts']);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setNewPosts(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    const newPostNotifierSocketConnection =
      token && new WebSocket(communityPostNotifierSocketUrl + token);

    newPostNotifierSocketConnection.onerror = () => {
      toast.error("Couldn't connect to new community post notifier socket");
    };

    newPostNotifierSocketConnection.onmessage = () => {
      setNewPosts(true);
    };
  }, []);

  return (
    <MainContent>
      <div className="mt-24 w-full">
        {newPosts && (
          <div
            onClick={handleNewPosts}
            className=" w-full -mt-2 text-white "
            style={{ zIndex: 100000 }}
          >
            <p className="bg-claret-500 w-fit px-4 py-1 rounded-full mx-auto   z-50">
              New Posts
            </p>
          </div>
        )}
        {isLoading && <Spinner />}
        {!isLoading &&
          data?.data?.posts &&
          data?.data?.posts.map((post) => (
            <CommunityPostItem key={post.id} post={post} />
          ))}
      </div>
    </MainContent>
  );
}

export default AllPosts;
