import React, { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MainContent from '../components/MainContent';
import WebinarAnalyticsOverview from '../components/WebinarAnalyticsOverview';
import WebinarRegistrantsRawData from '../components/WebinarRegistrantsRawData';
import WebinarParticipantsRawData from '../components/WebinarParticipantsRawData';
import WebinarAbsenteesRawData from '../components/WebinarAbsenteesRawData';

export default function ClassSessionAnalytics() {
  const { webinarID } = useParams();

  const tabs = [
    {
      id: 1,
      title: 'Webinar Overview',
      component: <WebinarAnalyticsOverview webinarID={webinarID} />,
    },
    {
      id: 2,
      title: 'Webinar Registrants',
      component: <WebinarRegistrantsRawData webinarID={webinarID} />,
    },
    {
      id: 3,
      title: 'Webinar Participants',
      component: <WebinarParticipantsRawData webinarID={webinarID} />,
    },
    {
      id: 4,
      title: 'Webinar Absentee Registrants',
      component: <WebinarAbsenteesRawData webinarID={webinarID} />,
    },
  ];

  return (
    <MainContent full>
      <div className="flex flex-col space-y-2">
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/class-sessions"
              className="flex items-center"
            >
              <CalendarMonthIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Class Sessions</p>
            </NavLink>
          </Breadcrumbs>
        </div>{' '}
        <Tab.Group>
          <Tab.List className="flex rounded-md border bg-white space-x-5 mb-6 p-2">
            {tabs.map((tab) => (
              <Tab as={Fragment} key={tab.id}>
                {({ selected }) => (
                  <div
                    className={` focus:outline-none cursor-pointer p-2 hover:bg-lms-custom-50
                     ${
                       selected
                         ? ' text-claret-500 font-semibold border-b-2 border-[#8B173B]'
                         : ' hover:border-b-2 hover:border-lms-custom-100'
                     }
                    `}
                  >
                    <p>{tab.title}</p>
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels className="px-2">
            {tabs.map((tab) => (
              <Tab.Panel key={tab.id}>{tab.component}</Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </MainContent>
  );
}
