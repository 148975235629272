import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import MainContent from '../components/MainContent';
import CreateModule from '../components/CreateModule';

function AddModule() {
  const navigate = useNavigate();

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex justify-between mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/courses"
            className="flex items-center"
          >
            <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Courses</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-1)}
            to={() => navigate(-1)}
            className="flex items-center"
          >
            <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Modules</p>
          </NavLink>
        </Breadcrumbs>
      </div>
      <CreateModule />
    </MainContent>
  );
}

export default AddModule;
