import { useSelector } from 'react-redux';
import ReactTimeAgo from 'react-time-ago';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import MainContent from '../components/MainContent';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';

const notificationsSocketUrl =
  process.env.REACT_APP_NOTIFICATION_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnprojectafrica.org/notification/socket';

const socketConnection = new WebSocket(
  `${notificationsSocketUrl}/notification?Authorization=${localStorage.getItem(
    'token'
  )}`
);

function Notifications() {
  const notifications = useSelector((state) => state.notifications.messages);

  const handleReadNotification = (id) => {
    return socketConnection.send(JSON.stringify({ notification_ids: [id] }));
  };

  const handleReadAllNotifications = () => {
    const messageIds = notifications.map((notification) => notification.id);
    socketConnection.send(messageIds);

    return socketConnection.send(
      JSON.stringify({ notification_ids: messageIds })
    );
  };

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg">
          {notifications.length > 0 && (
            <button
              onClick={handleReadAllNotifications}
              type="button"
              className="mb-4 mr-0 hover:bg-gray-200 px-2 py-1 capitalize text-blue-700 rounded-md"
            >
              Read all ({notifications.length})
            </button>
          )}
          <div className="space-y-4">
            {notifications.length ? (
              notifications.map((message) => (
                <div key={message.id} className="bg-green-100 p-4">
                  <div className="  flex justify-between">
                    <div className="" />
                    <IconButton
                      size="small"
                      className=""
                      onClick={() => handleReadNotification(message.id)}
                    >
                      <CloseIcon className="" fontSize="" />
                    </IconButton>
                  </div>
                  <div className=" flex justify-between mb-4">
                    <p className=" font-semibold">{message.title}</p>
                    <ReactTimeAgo
                      date={new Date(message.logged_at)}
                      locale="en-US"
                    />
                  </div>
                  <p className="">{message.message}</p>
                </div>
              ))
            ) : (
              <p className="text-lg">No new notifications</p>
            )}
          </div>
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default Notifications;
