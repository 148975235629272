import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import countryOptions from '../utils/constants/countryOptions';

const schema = yup
  .object({
    firstname: yup.string().required('This is a required field'),
    middlename: yup.string(),
    lastname: yup.string().required('This is a required field'),
    email: yup.string().required('Email required').email('Enter a valid email'),
    country: yup.object().nullable().required('This is a required field'),
  })
  .required();

function EditStudentProfile({ toggleModal, currentStudentDetails }) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Initialize fields with currentStudentDetails
  useEffect(() => {
    if (currentStudentDetails) {
      setValue('firstname', currentStudentDetails.firstname);
      setValue('middlename', currentStudentDetails.middlename);
      setValue('lastname', currentStudentDetails.lastname);
      setValue('email', currentStudentDetails.email);
      // Find the selected country object
      const selectedCountry = countryOptions.find(
        (option) => option.value === currentStudentDetails.country
      );

      setValue('country', selectedCountry || null); // Set to null if not found
    }
  }, [currentStudentDetails, setValue]);

  const queryClient = useQueryClient();

  const onStudentUpdateSuccess = () => {
    toast.success('Student profile successfully updated!');
    toggleModal();
    setIsSubmitting(false);
  };

  const onStudentUpdateFailure = () => {
    toast.error('Student profile update failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) =>
      axiosInstancev2.put(
        `/users/student/${currentStudentDetails.id}/by-admin`,
        data
      ),
    {
      onSuccess: () => {
        onStudentUpdateSuccess();
        queryClient.invalidateQueries([
          'student-details',
          currentStudentDetails.id,
        ]);
      },
      onError: onStudentUpdateFailure,
    }
  );

  const onSubmit = async (formData) => {
    setIsSubmitting(true);

    const transformedData = {
      ...formData,
      country: formData.country ? formData.country.value : null, // Use .value or .label as needed
    };

    mutation.mutate(transformedData);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="text-gray-700 space-y-2"
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="email" className="text-base">
              Email Address
            </label>
            <input
              id="email"
              {...register('email', { required: true })}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.email ? 'border-red-600' : ''
              }`}
              type="email"
              placeholder="Email"
            />
            {errors.email && (
              <span className="text-red-600 text-xs mt-2">
                {errors.email?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="firstname" className="text-base">
              First Name
            </label>
            <input
              id="firstname"
              {...register('firstname', { required: true })}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.firstname ? 'border-red-600' : ''
              }`}
              type="text"
              placeholder="First Name"
            />
            {errors.firstname && (
              <span className="text-red-600 text-xs mt-2">
                {errors.firstname?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="middlename" className="text-base">
              Middle Name
            </label>
            <input
              id="middlename"
              {...register('middlename')}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.middlename ? 'border-red-600' : ''
              }`}
              type="text"
              placeholder="Middle Name"
            />
            {errors.middlename && (
              <span className="text-red-600 text-xs mt-2">
                {errors.middlename?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lastname" className="text-base">
              Last Name
            </label>
            <input
              id="lastname"
              {...register('lastname', { required: true })}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.lastname ? 'border-red-600' : ''
              }`}
              type="text"
              placeholder="Last Name"
            />
            {errors.lastname && (
              <span className="text-red-600 text-xs mt-2">
                {errors.lastname?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label
              htmlFor="country"
              className="text-xs text-mine-shaft-500 mb-1 pl-1"
            >
              Country
            </label>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  placeholder="Select Country"
                  classNamePrefix="dropdown"
                  options={countryOptions}
                  onChange={(option) => field.onChange(option)}
                  value={
                    field.value
                      ? countryOptions.find(
                          (option) => option.value === field.value.value
                        )
                      : null
                  }
                />
              )}
            />

            {errors.country && (
              <span className="text-red-600 text-xs mt-1">
                {errors.country?.message}
              </span>
            )}
          </div>
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex justify-between mt-8">
            <button
              className="border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center"
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Update</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default EditStudentProfile;
