import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    name: yup.string().required('This is a required field'),
  })
  .required();

function CreateSurvey({ toggleModal }) {
  const { weekId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();

  const onSurveyCreationSuccess = () => {
    toast.success('Survey successfully created!');
    toggleModal();
    setIsSubmitting(false);
  };
  const onSurveyCreationFailure = () => {
    toast.error('Survey creation failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/surveys', data);
    },
    {
      onSuccess: () => {
        onSurveyCreationSuccess();
        queryClient.invalidateQueries(['week-surveys', weekId]);
      },
      onError: onSurveyCreationFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const requestData = {
      ...data,
      week_id: weekId,
    };
    mutation.mutate(requestData);
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid  gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="name" className="text-base">
              Survey Name
            </label>
            <input
              {...register('name', { required: true })}
              type="text"
              id="name"
              placeholder="Survey Name"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.name ? 'border-red-600' : ''
              }`}
            />
            {errors.name && (
              <span className="text-red-600 text-xs mt-2">
                {errors.name?.message}
              </span>
            )}
          </div>
        </div>

        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Survey</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateSurvey;
