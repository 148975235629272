import React from 'react';
import parse from 'html-react-parser';

function CourseOverview({ moduleDescription }) {
  return (
    <div className="list-disc list-inside richtext">
      {parse(`${moduleDescription}`)}
    </div>
  );
}

export default CourseOverview;
