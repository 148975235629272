import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import TableWrapper from '../utils/TableWrapper';
import Spinner from './spinner/Spinner';

export default function StudentLoginsTrail({
  studentId,
  studentEmail,
  studentName,
  toggleModal,
}) {
  const columns = [
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row?.firstname}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row?.lastname}</p>,
    },
    { title: 'Email', field: 'email' },
    { title: 'TimeStamp', field: 'timestamp' },
  ];

  // Function to flatten the data structure
  const flattenData = (data) => {
    return data.map((row) => ({
      firstname: row.student?.firstname || 'N/A',
      lastname: row.student?.lastname || 'N/A',
      email: row.student?.email || 'N/A',
      timestamp: row.timestamp
        ? new Date(row.timestamp).toLocaleString(undefined, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
          })
        : 'No Record',
    }));
  };

  const { data: studentLoginActivities, isLoading } = useFetchAnalytics(
    ['student-login-activities', studentId],
    `/raw-data/student-login-activities/${studentId}`,
    {},
    "Couldn't get student's login activities. Please try again",
    studentId
  );

  return (
    <div className="mt-4">
      {isLoading && studentId ? (
        <div className="flex items-center justify-center bg-white">
          <Spinner />
        </div>
      ) : (
        <TableWrapper
          title={`${studentName} - ${studentEmail} Logins Trail`}
          columns={columns}
          data={flattenData(
            studentLoginActivities?.data ? studentLoginActivities?.data : []
          )}
          options={{
            rowStyle: {
              overflowWrap: 'break-word',
            },
          }}
        />
      )}
      <div className="flex justify-between mt-4">
        <button
          className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
          type="button"
          onClick={() => {
            toggleModal();
          }}
        >
          <CancelIcon fontSize="inherit" />
          <p>Close</p>
        </button>
      </div>
    </div>
  );
}
