/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import Spinner from '../spinner/Spinner';
import { communityInterceptor } from '../../utils/Axios/communityInterceptor';

const schema = yup
  .object({
    text_message: yup.string().required('This is a required field'),
  })
  .required();

function CreatePost({ toggleModal }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mediaFile, setMediaFile] = useState('');
  const [mediaFileError, setMediaFileError] = useState('');

  const handleFileInputChange = (file) => {
    setMediaFileError('');
    if (file?.length >= 1 && file[0]?.size > 20000000) {
      setMediaFileError('Please select a file less than 20Mb');
      return;
    }
    setMediaFile(file);
  };

  const onPostCreationSuccess = () => {
    toast.success('Post created successfully!');
    toggleModal();
    setIsSubmitting(false);
  };
  const onPostCreationFailure = (error) => {
    toast.error(
      `Couldn't create post. ${error?.response?.data?.error ?? '.'} Try again!`
    );
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return communityInterceptor.post('/posts', data);
    },
    {
      onSuccess: onPostCreationSuccess,
      onError: onPostCreationFailure,
    }
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();

    formData.append('text_message', data.text_message);
    if (mediaFile[0]) {
      formData.append('media_file', mediaFile[0]);
    }

    mutation.mutate(formData);
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="descriptbodyion" className="">
            Add Media (if any)
          </label>
          <input
            name="media_file"
            onChange={(event) => {
              handleFileInputChange(event.target.files);
            }}
            type="file"
            id="media_file"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              mediaFileError ? 'border-red-600' : ''
            }`}
          />
          {mediaFileError && (
            <span className="text-red-600 text-xs mt-2">{mediaFileError}</span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <textarea
            {...register('text_message', { required: true })}
            type="text"
            id="body"
            placeholder="Share your thoughts"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.text_message ? 'border-red-600' : ''
            }`}
          />
          {errors.text_message && (
            <span className="text-red-600 text-xs mt-2">
              {errors.text_message?.message}
            </span>
          )}
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
              disabled={mediaFileError.trim() !== ''}
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Post</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreatePost;
