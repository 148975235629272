import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import Spinner from '../../components/spinner/Spinner';

function ForgotPassword() {
  const formSchema = yup
    .object({
      email: yup
        .string()
        .required('Email required')
        .email('Enter a valid email'),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(formSchema) });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const baseURL =
    process.env.REACT_APP_BASE_URL_v2 ||
    'https://api.lms.v2.powerlearnprojectafrica.org/gateway/api';

  // reset inputs if submitted
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(`${baseURL}/auth/administrator/forgot-password`, data)
      .then(() => {
        toast.success(
          'Password successfully reset. Please check email for reset link'
        );
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // reset inputs if successfully submitted

  return (
    <div>
      <div className="flex flex-row h-screen max-h-screen">
        <div className="img-container flex flex-1 relative justify-center items-center">
          <div className="copyright absolute bottom-3">
            <p className="text-white">
              &#169; Power Learn Project 2022 all rights reserved
            </p>
          </div>
        </div>

        <div className="form flex-1 flex flex-col justify-center items-center max-h-screen">
          <div className="flex flex-col justify-center items-center">
            <img src={logo} alt="" className="h-14" />
            <h4 className="text-xl font-medium mb-5">Forgot Password</h4>
            <div
              className="bg-silver-500 rounded-md py-2 pl-3 text-xs w-96 mb-3
            "
            >
              <p className="text-xs font-small">
                Please provide the email address you used when you signed up to
                your Power Learn Project Account. A password reset link shall be
                sent to your account.
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="w-96 ">
            <div className="flex flex-col mb-5">
              <label
                htmlFor="email"
                className="text-xs text-mine-shaft-500 mb-1 pl-1"
              >
                Email Address
              </label>
              <input
                id="email"
                {...register('email', { required: true, maxLength: 15 })}
                className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
                  errors.email ? 'border-red-600 focus:outline-red-600' : ''
                }`}
                type="email"
                placeholder="Email"
              />
              {errors.email && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.email?.message}
                </span>
              )}
            </div>
            {isLoading ? (
              <div className="grid place-items-center">
                <Spinner />
              </div>
            ) : (
              <button
                type="submit"
                className="bg-claret-500 text-white w-full text-xs rounded-md py-2 mb-4"
              >
                REQUEST PASSWORD RESET
              </button>
            )}
            <Link to="/login">
              <small className="text-persian-500">Back to Login</small>{' '}
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
