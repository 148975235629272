import React from 'react';

function StudentEnrolledModule({ enrolled_module }) {
  return (
    <div className="border-[1px] border-[#C8C8C8] w-56  mx-auto h-[330px] max-h-[330px] rounded-xl overflow-hidden flex flex-col justify-between  relative mt-2">
      <img
        src={enrolled_module.image}
        alt={enrolled_module.name}
        className=" h-60 bg-cover bg-no-repeat bg-center object-cover border-b-[1px] border-[#C8C8C8]"
      />
      <div className="p-4 flex-1 flex justify-between flex-col">
        <div className="">
          <p className="text-sm font-light font-roboto text-gray-600/70">
            {enrolled_module.category}
          </p>
        </div>
        <div className="">
          <p className="text-sm font-light font-roboto text-gray-600/70">
            {enrolled_module?.is_paid_module
              ? 'Paid Module'
              : 'Scholarship Module'}
          </p>
        </div>
        <span className="capitalize font-roboto font-medium line-clamp-1 ">
          {enrolled_module?.name ?? ''}
        </span>
        <div className="  my-2 ">
          <p className="font-roboto text-xs text-claret-500 text-right">
            {Number(enrolled_module?.completion).toFixed(2) || 0}% Complete
          </p>
          <div className="w-full bg-[#C2C2C2] h-1 rounded-lg">
            <div
              style={{ width: `${Number(enrolled_module?.completion)}%` }}
              className="bg-claret-500 h-1 rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentEnrolledModule;
