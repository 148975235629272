import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import Spinner from './spinner/Spinner';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import useFetchAnalytics from '../hooks/useFetchAnalytics';

function ViewStudentPaidModules({ studentId, paidCohortId, toggleModal }) {
  const {
    data: studentPaidModulesData,
    isLoading: isLoadingStudentPaidModules,
  } = useFetchDatav2(
    ['student-paid-modules', studentId, paidCohortId],
    `/student-paid-modules/${studentId}`,
    { params: { paid_cohort_id: paidCohortId } },
    "Couldn't fetch student paid modules.",
    studentId
  );

  const {
    data: studentModulesProgressData,
    isLoading: isLoadingStudentModulesProgress,
  } = useFetchAnalytics(
    ['students-progress', studentId],
    `/students-progress/${studentId}`,
    {},
    "Couldn't fetch student modules progress.",
    studentId
  );

  const moduleCompletionMap =
    studentModulesProgressData?.data?.student_progress?.modules?.reduce(
      (acc, module) => {
        if (module && module.module_id) {
          acc[module.module_id] = module.completion ?? 0;
        }
        return acc;
      },
      {}
    ) ?? {};

  if (isLoadingStudentPaidModules || isLoadingStudentModulesProgress) {
    return (
      <div className="p-2">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      {studentPaidModulesData?.data?.student_paid_modules?.length > 0 ? (
        <div className="bg-white shadow-md py-2 mt-2 mb-4 rounded-md max-w-[90%] mx-auto">
          {studentPaidModulesData?.data?.student_paid_modules?.map(
            (student_paid_module) => (
              <div key={student_paid_module?.module?.id} className="p-2">
                <div className="bg-gray-400/20 flex flex-col md:flex-row md:justify-between p-2 rounded-md text-justify">
                  <div className="flex items-start mb-2">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-md object-cover"
                        src={student_paid_module?.module?.moduleImage}
                        alt={student_paid_module?.module?.moduleName}
                      />
                    </div>
                    <div className="px-2 space-y-2">
                      <div className="text-xs font-roboto">
                        <p className="capitalize">
                          {student_paid_module?.module?.moduleName}
                        </p>
                      </div>
                      {student_paid_module?.start_date && (
                        <div className="text-xs font-roboto">
                          <p className="text-sm">
                            Learning Start Date:{' '}
                            <span className="text-claret-500">
                              {new Date(
                                student_paid_module?.start_date
                              ).toLocaleDateString('en-us', {
                                month: 'long',
                                year: 'numeric',
                                day: 'numeric',
                              })}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="  my-2 ">
                    <p className="font-roboto text-xs text-claret-500 text-right">
                      {Number(
                        moduleCompletionMap[student_paid_module?.module?.id] ??
                          0
                      ).toFixed(2)}
                      % Complete
                    </p>
                    <div className="w-full bg-[#C2C2C2] h-1 rounded-lg">
                      <div
                        style={{
                          width: `${Number(
                            moduleCompletionMap[
                              student_paid_module?.module?.id
                            ] ?? 0
                          ).toFixed(2)}%`,
                        }}
                        className="bg-claret-500 h-1 rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <p className="text-center mb-2">
          Student is not enrolled in any paid module
        </p>
      )}
      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
          onClick={toggleModal}
        >
          <CancelIcon fontSize="inherit" />
          Close
        </button>
      </div>
    </div>
  );
}

export default ViewStudentPaidModules;
