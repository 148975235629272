import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Spinner from '../components/spinner/Spinner';
import MainContent from '../components/MainContent';
import { roles } from '../utils/roles';
import Modal from '../components/Modal';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    role: yup.string().required('This is a required field'),
  })
  .required();

function RolesAndPermissions() {
  const [role, setRole] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // fetch all user permissions
  const fetchAllActions = async () => {
    const response = await axiosInstancev2.get('/actions');
    return response;
  };

  const onFetchActionsFailure = () => {
    toast.error("Couldn't fetch actions. Please try again!");
  };

  const { data, isLoading } = useQuery(['actions'], fetchAllActions, {
    staleTime: 3600 * 1000,
    cacheTime: 3600 * 1000,
    onError: onFetchActionsFailure,
  });
  const fetchPermissionByRole = async () => {
    const response = await axiosInstancev2.get('/role-permissions', {
      params: { role },
    });
    return response;
  };

  const onFetchPermissionsFailure = () => {
    toast.error("Couldn't fetch permissions. Please try again!");
  };

  const { data: filteredRoles, isFetching: filteredRolesLoading } = useQuery(
    ['filtered-role-permissions', role],
    fetchPermissionByRole,
    {
      enabled: !!role,
      onError: onFetchPermissionsFailure,
    }
  );

  // confirmation modal
  const [modalOpen, setModalOpen] = useState(false);
  const [submissionData, setSubmissionData] = useState({});

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const onRoleCreationSuccess = () => {
    toast.success('Role successfully updated!');
    toggleModal();
  };

  const onRoleCreationFailure = () => {
    toast.error("Couldn't update role! Please try again");
    toggleModal();
  };
  const queryClient = useQueryClient();

  const { mutate, isLoading: updatingRoles } = useMutation(
    (requestData) => {
      return axiosInstancev2.post('/role-permissions', requestData);
    },
    {
      onSuccess: () => {
        onRoleCreationSuccess();
        queryClient.invalidateQueries(['filtered-role-permissions', role]);
      },
      onError: onRoleCreationFailure,
    }
  );

  const onSubmit = async (requestData) => {
    setSubmissionData(requestData);
    toggleModal();
  };

  const updatePermissionsHandler = () => {
    mutate(submissionData);
  };

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex w-full justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
        </Breadcrumbs>
      </div>
      <div className="mt-4 bg-white">
        <form onSubmit={handleSubmit(onSubmit)} className="bg-white p-5">
          <div className="flex flex-col mb-7 w-1/2">
            <label htmlFor="skillLevel" className="text-base mb-2">
              Select Role
            </label>
            <select
              {...register('role', { required: true })}
              required
              name="role"
              id="role"
              className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
              onChange={(event) => setRole(event.target.value)}
            >
              <option value="">Select Option</option>
              {roles?.map((roleOption) => (
                <option key={roleOption.id} value={roleOption.value}>
                  {roleOption.label}
                </option>
              ))}
            </select>
            {errors.role && (
              <span className="text-red-600 text-xs mt-2">
                {errors.role?.message}
              </span>
            )}
          </div>
          <div className="mt-4 ">
            {isLoading || filteredRolesLoading ? (
              <div className="flex justify-center items-center w-full">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="grid grid-cols-3">
                  {data &&
                    data?.data?.actions.map((action) => (
                      <label key={action.id}>
                        <input
                          {...register(`action_id`)}
                          // name={action.action.description}
                          type="checkbox"
                          value={action.id}
                          defaultChecked={filteredRoles?.data?.permissions
                            ?.map((filteredRole) => filteredRole.action.id)
                            .includes(action.id)}
                        />{' '}
                        {action.name}
                      </label>
                    ))}
                </div>

                <div className="flex justify-end  mt-4">
                  <button
                    type="submit"
                    className="capitalize border-[1px] bg-claret-500 px-4 text-white rounded-md py-1 text-sm
"
                  >
                    submit
                  </button>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Update Role Permissions"
      >
        <>
          <h4 className="text-lg">
            Are you sure you want to update the Permisions for{' '}
            <span className="font-semibold">{role}</span> ?
          </h4>
          {updatingRoles ? (
            <div className="flex justify-center items-center align-middle">
              <Spinner />
            </div>
          ) : (
            <div className=" flex justify-between mt-8">
              <button
                className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
                type="button"
                onClick={toggleModal}
              >
                <CancelIcon fontSize="inherit" />
                <p>Cancel</p>
              </button>
              <button
                className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
                type="button"
                onClick={updatePermissionsHandler}
              >
                <SaveIcon fontSize="inherit" />
                <p>Update Permissions</p>
              </button>
            </div>
          )}
        </>
      </Modal>
    </MainContent>
  );
}

export default RolesAndPermissions;
