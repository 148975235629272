import useFetchCommunityData from '../hooks/useFetchCommunityData';
import Spinner from '../components/spinner/Spinner';
import MainContent from '../components/MainContent';
import FlaggedCommunityPost from '../components/community/FlaggedPost';

function FlaggedPosts() {
  const { data, isLoading } = useFetchCommunityData(
    ['flagged-posts'],
    '/posts/flagged',
    {},
    "couldn't get posts. please try again",
    true
  );

  return (
    <MainContent>
      <div className="mt-24 w-full">
        {isLoading && <Spinner />}
        {!isLoading &&
          data?.data?.posts &&
          data?.data?.posts.map((post) => (
            <FlaggedCommunityPost key={post.id} post={post} />
          ))}
      </div>
    </MainContent>
  );
}

export default FlaggedPosts;
