import React, { useRef, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { Editor } from '@tinymce/tinymce-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Select from 'react-select';
import CancelIcon from '@mui/icons-material/Cancel';
import { useParams } from 'react-router-dom';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import Spinner from './spinner/Spinner';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import tinyMCEConfigs from '../utils/tinymce';

const groupAssignmentSchema = yup
  .object({
    cohort: yup
      .object()
      .shape({
        label: yup.string().required('cohort is required (from label)'),
        value: yup.string().required('cohort is required'),
      })
      .nullable()
      .required('Please select a cohort'),
    title: yup.string().required('This is a required field'),
    purpose: yup
      .object()
      .shape({
        label: yup.string().required('Purpose is required (from label)'),
        value: yup.string().required('Purpose is required'),
      })
      .nullable()
      .required('Please select a group purpose'),
    deadline: yup.string().required('This is a required field'),
  })
  .required();

function CreateScholarshipGroupAssignment({ toggleModal }) {
  const [cohortId, setCohortId] = useState('');
  const { groupId } = useParams();
  const [bodyContentIsDirty, setBodyContentIsDirty] = useState(false);
  const [instructionContentIsDirty, setInstructionsContentIsDirty] =
    useState(false);
  const [pseudocodeSolutionIsDirty, setPseudocodeSolutionIsDirty] =
    useState(false);
  const [assignmentBody, setAssignmentBody] = useState('');
  const [assignmentInstructions, setAssignmentInstructions] = useState('');
  const [assignmentPseudocodeSolution, setAssignmentPseudocodeSolution] =
    useState('');

  const isBodyEmpty = assignmentBody === '<p><br></p>' || assignmentBody === '';

  const isInstructionsEmpty =
    assignmentInstructions === '<p><br></p>' || assignmentInstructions === '';

  const isPseudocodeSolutionEmpty =
    assignmentPseudocodeSolution === '<p><br></p>' ||
    assignmentPseudocodeSolution === '';

  const instructionEditorRef = useRef(null);
  const bodyEditorRef = useRef(null);
  const pseudocodeSolutionRef = useRef(null);

  const saveBodyContent = () => {
    if (bodyEditorRef.current) {
      const content = bodyEditorRef.current.getContent();
      setBodyContentIsDirty(false);
      bodyEditorRef.current.setDirty(false);
      setAssignmentBody(content);
    }
  };

  const saveInstructionsContent = () => {
    if (instructionEditorRef.current) {
      const content = instructionEditorRef.current.getContent();
      setInstructionsContentIsDirty(false);
      instructionEditorRef.current.setDirty(false);
      setAssignmentInstructions(content);
    }
  };

  const savePseudocodeSolutionContent = () => {
    if (pseudocodeSolutionRef.current) {
      const content = pseudocodeSolutionRef.current.getContent();
      setPseudocodeSolutionIsDirty(false);
      pseudocodeSolutionRef.current.setDirty(false);
      setAssignmentPseudocodeSolution(content);
    }
  };

  const permissions = usePermissions();
  const role = useGetUserRole();

  const canCreateGroupAssignment =
    permissions?.includes('CreateGroupAssignment') || role === 'SuperAdmin';

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(groupAssignmentSchema),
    defaultValues: {},
  });

  const queryClient = useQueryClient();
  const onGroupAssignmentCreationSuccess = () => {
    toast.success('Group Assignment successfully created!');
    queryClient.invalidateQueries(['group-assignments', groupId]);
    toggleModal();
  };
  const onGroupAssignmentCreationFailure = () => {
    toast.error('Groups Assignment creation failed! Please try again');
  };

  const { mutate, isLoading: isSubmitting } = useMutation(
    (data) => {
      return axiosInstancev2.post('/group-assignments', data);
    },
    {
      onSuccess: () => {
        onGroupAssignmentCreationSuccess();
      },
      onError: onGroupAssignmentCreationFailure,
    }
  );

  const { data: cohortData, isLoading: isCohortsDataLoading } = useFetchDatav2(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const scholarshipCohortOptions = cohortData
    ? cohortData?.data.cohorts
        // .filter((cohort) => cohort.status !== 'ended')
        .map((cohort) => ({
          label: cohort.name,
          value: cohort.id,
        }))
    : [];

  const { data: purposeList, isLoading: isPurposeLoading } = useFetchDatav2(
    ['purposes', cohortId],
    '/groups/purposes',
    {
      params: { cohort_id: cohortId },
    },
    "Couldn't get purposes. Please try again!",
    cohortId
  );

  const purposeOptions = purposeList
    ? purposeList?.data.group_purposes?.map((purpose) => ({
        value: purpose,
        label: purpose,
      }))
    : [];

  const onSubmit = async (requestData) => {
    const requestBody = {
      ...requestData,
      instructions: assignmentInstructions,
      body: assignmentBody,
      pseudocodeSolution: assignmentPseudocodeSolution,
      cohort_id: requestData?.cohort?.value,
      group_purpose: requestData?.purpose?.value,
    };

    mutate(requestBody);
  };

  const buttonDisabled =
    instructionContentIsDirty ||
    bodyContentIsDirty ||
    pseudocodeSolutionIsDirty ||
    isBodyEmpty ||
    isInstructionsEmpty ||
    isPseudocodeSolutionEmpty ||
    Object.keys(errors).length > 0;

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-2 gap-4 ">
          <div className="flex flex-col space-y-1">
            <label htmlFor="cohort" className="text-base font-semibold">
              Select Scholarship Cohort
            </label>
            <Controller
              name="cohort"
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <Select
                  {...rest}
                  value={value}
                  isClearable
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={scholarshipCohortOptions}
                  isLoading={isCohortsDataLoading}
                  onChange={(selectedOption) => {
                    onChange(selectedOption); // Pass selected option to react-hook-form
                    setCohortId(selectedOption?.value); // Update your state with cohort ID
                  }}
                />
              )}
            />
            {errors?.cohort && (
              <span className="text-red-600 text-xs mt-2">
                {errors?.cohort?.message}
              </span>
            )}
          </div>

          {cohortId && (
            <div className="flex flex-col space-y-1" key={cohortId}>
              <label htmlFor="purpose" className="text-base font-semibold">
                Select Group Purpose
              </label>
              <Controller
                name="purpose"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    className="react-dropdown"
                    classNamePrefix="dropdown"
                    options={purposeOptions}
                    isLoading={isPurposeLoading}
                  />
                )}
              />
              {errors.purpose && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.purpose?.message}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="title" className="text-base font-semibold">
              Assignment Title
            </label>
            <input
              {...register('title', { required: true })}
              type="text"
              id="title"
              placeholder="Title"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.title ? 'border-red-600' : ''
              }`}
            />
            {errors.title && (
              <span className="text-red-600 text-xs mt-2">
                {errors.title?.message}
              </span>
            )}
          </div>

          <div className="flex flex-col space-y-1">
            <label htmlFor="deadline" className="text-base font-semibold">
              Assignment Submission Deadline
            </label>
            <input
              {...register('deadline', { required: true })}
              min={new Date().toISOString().split('T')[0]}
              type="date"
              id="deadline"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.deadline ? 'border-red-600' : ''
              }`}
            />
            {errors.deadline && (
              <span className="text-red-600 text-xs mt-2">
                {errors.deadline?.message}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          <label
            htmlFor="assignmentInstructions"
            className="text-base font-semibold"
          >
            Assignment Instructions
          </label>
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey="gpl"
            initialValue={assignmentInstructions}
            onInit={(evt, editor) => {
              instructionEditorRef.current = editor;
            }}
            onDirty={() => setInstructionsContentIsDirty(true)}
            init={{
              ...tinyMCEConfigs,
              editable_root: canCreateGroupAssignment,
              save_onsavecallback: saveInstructionsContent,
            }}
          />
          {instructionContentIsDirty && (
            <p>You have unsaved assignment instructions content!</p>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="assignmentBody" className="text-base font-semibold">
            Assignment Body
          </label>
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey="gpl"
            initialValue={assignmentBody}
            onInit={(evt, editor) => {
              bodyEditorRef.current = editor;
            }}
            onDirty={() => setBodyContentIsDirty(true)}
            init={{
              ...tinyMCEConfigs,
              editable_root: canCreateGroupAssignment,
              save_onsavecallback: saveBodyContent,
            }}
          />
          {bodyContentIsDirty && (
            <p>You have unsaved assignment body content!</p>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label
            htmlFor="assignmentPseudocodeSolution"
            className="text-base font-semibold"
          >
            Step by Step Solution
          </label>
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey="gpl"
            initialValue={assignmentPseudocodeSolution}
            onInit={(evt, editor) => {
              pseudocodeSolutionRef.current = editor;
            }}
            onDirty={() => setPseudocodeSolutionIsDirty(true)}
            init={{
              ...tinyMCEConfigs,
              editable_root: canCreateGroupAssignment,
              save_onsavecallback: savePseudocodeSolutionContent,
            }}
          />
          {pseudocodeSolutionIsDirty && (
            <p>You have unsaved step by step solution content!</p>
          )}
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              type="submit"
              className={`p-2 rounded-md text-white text-sm space-x-2 flex items-center ${
                buttonDisabled
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-persian-500'
              }`}
              disabled={buttonDisabled}
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Group Assignment</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateScholarshipGroupAssignment;
