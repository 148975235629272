import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import Spinner from '../components/spinner/Spinner';
import Calendar from '../components/calendar/Calendar';
import RightBar from '../components/RightBar';
import MainContent from '../components/MainContent';
import useFetchData from '../hooks/useFetchData';

function TutorialDetail() {
  const { tutorialId } = useParams();

  const { data, isLoading } = useFetchData(
    ['tutorial', tutorialId],
    `/tutorials/${tutorialId}`,
    {},
    "Couldn't fetch tutorial. Please try again.",
    tutorialId
  );
  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          {/* <p>Home > Courses > Modules </p> */}
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/tutorials"
              className="flex items-center"
            >
              <OndemandVideoIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Tutorials</p>
            </NavLink>
          </Breadcrumbs>
          {/* {permissions?.includes("CreateModule") && (
            <button
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              // onClick={toggleModal}
            >
              Add Tutorial
            </button>
          )} */}
        </div>
        {isLoading ? (
          <div className="grid items-center justify-center my-auto mt-10">
            <Spinner />
          </div>
        ) : (
          <div className="bg-white rounded mt-5 p-10">
            {parse(`${data?.data?.tutorial?.content}`)}
          </div>
        )}
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default TutorialDetail;
