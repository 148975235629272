import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ForumOutlined from '@mui/icons-material/ForumOutlined';
import { useQuill } from 'react-quilljs';
import MainContent from '../components/MainContent';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';
import useGetLoggedInUserId from '../hooks/useGetLoggedInUserId';
import Spinner from '../components/spinner/Spinner';
import useGetLoggedInUserDetails from '../hooks/useGetLoggedInUserDetails';
import Message from '../components/Message';

const chatSocketUrl =
  process.env.REACT_APP_CHAT_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnprojectafrica.org/chat/socket';

function ForumDetail() {
  const user_id = useGetLoggedInUserId();
  const user = useGetLoggedInUserDetails();
  const username = `${user.firstname.trim()} ${user.lastname.trim()}`;

  const { forumId } = useParams();
  const [newMessage, setNewMessage] = useState('');
  // const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { quill, quillRef, Quill } = useQuill({
    modules: { magicUrl: true, toolbar: false },
  });

  if (Quill && !quill) {
    // eslint-disable-next-line global-require
    const MagicUrl = require('quill-magic-url').default;
    Quill.register('modules/magicUrl', MagicUrl);
  }

  const isEmpty = newMessage === '<p><br></p>' || newMessage === '';

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setNewMessage(quill.root.innerHTML);
      });
    }
  }, [quill, quillRef]);

  // const wsConnection = useSendChat('forum', token, forumId);
  const [socketConnection] = useState(
    new WebSocket(
      `${chatSocketUrl}/forum_chat?forum_id=${forumId}&Authorization=${localStorage.getItem(
        'token'
      )}`
    )
  );
  const [forumMessages, setForumMessages] = useState([]);

  useEffect(() => {
    socketConnection.onmessage = (event) => {
      setForumMessages(JSON.parse(event.data));
      setIsLoading(false);
    };
  }, [socketConnection]);

  const handleSendMessage = () => {
    socketConnection.send(
      JSON.stringify({
        message: quill.root.innerHTML,
        user_name: username,
      })
    );
    setNewMessage('');
    quill.setText('\n');
  };

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/forums"
              className="flex items-center"
            >
              <ForumOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
              <p className="">Forums</p>
            </NavLink>
          </Breadcrumbs>
        </div>
        <div className="bg-white mt-5 p-3 rounded-md">
          <div id="chat" className="bg-chatbg p-2 space-y-4">
            {isLoading && <Spinner />}
            {forumMessages?.map((message) => (
              <Message
                fromMe={message.user_id === user_id}
                key={message.messageId}
                text={message.message}
                sender={message.username}
                timeStamp={message.created_at}
              />
            ))}
            <div className="mt-2 space-y-4">
              <div ref={quillRef} />
              <button
                className={` uppercase font-medium rounded-sm ${
                  isEmpty
                    ? 'bg-gray-500 text-black px-3 py-1 cursor-not-allowed'
                    : 'bg-claret-500 text-white px-3 py-1'
                }`}
                type="button"
                onClick={handleSendMessage}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default ForumDetail;
