import React, { useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

function AssignInstructor({
  moduleLeads,
  toggleModal,
  instructors,
  assistantInstructors,
  moduleInstructors,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { moduleId } = useParams();
  const hasInstructor = moduleInstructors?.some(
    (instructor) => instructor.instructor.role === 'Instructor'
  );

  // eslint-disable-next-line no-unused-vars
  const schema = yup
    .object({
      moduleLead: yup.object().shape({
        label: yup.string().required('Module Lead is required (from label)'),
        value: yup.string().required('Module Lead is required'),
      }),
      instructor: yup
        .object()
        .shape({
          label: yup.string().required('Instructor is required (from label)'),
          value: yup.string().required('Instructor is required'),
        })
        .when('hasInstructor', {
          is: true,
          then: yup.string().required('Please select an instructor'),
        }),
      hasInstructor: yup
        .string()
        .required('Instructor is required (from label)'),
    })
    .required();

  const {
    control,
    register,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm();

  // add instructors
  const queryClient = useQueryClient();
  const onAssignInstructorsSuccess = () => {
    toast.success('Instructors assigned!');
    toggleModal();
    setIsLoading(false);
  };
  const onAssignInstructorsFailure = () => {
    toast.error('Failed to assign instructors. Try again.');
    setIsLoading(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('module-instructors', data);
    },
    {
      onSuccess: () => {
        onAssignInstructorsSuccess();
        queryClient.invalidateQueries(['module-instructors', moduleId]);
      },
      onError: onAssignInstructorsFailure,
    }
  );
  // add instructors

  const onSubmit = async (requestData) => {
    setIsLoading(true);

    let formattedData = [];
    if (requestData.assistantInstructors) {
      const assistants = requestData.assistantInstructors?.map(
        (assistant) => assistant.value
      );

      formattedData = [requestData.instructor?.value, ...assistants];
    }
    if (requestData.instructor) {
      const instructorList = requestData.instructor?.map(
        (instructor) => instructor.value
      );

      formattedData = [
        requestData.instructor.value,
        ...instructorList,
        ...formattedData,
      ];
    }
    if (requestData.moduleLead) {
      formattedData = [requestData.moduleLead?.value, ...formattedData];
    }

    mutation.mutate({
      module_id: moduleId,
      instructor_ids: formattedData.filter((user) => user),
    });
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        // onSubmit={handleAssignInstructor}
        className="space-y-4"
      >
        <input {...register('hasInstructor')} value={hasInstructor} hidden />
        <div className="flex flex-col space-y-1">
          <label htmlFor="moduleLead" className="text-base">
            Select Module Lead
          </label>
          <Controller
            name="moduleLead"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isClearable
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={moduleLeads}
              />
            )}
          />
          {errors.moduleLead && (
            <span className="text-red-600 text-xs mt-2">
              {errors.moduleLead?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="instructor" className="text-base">
            Select Instructors
          </label>
          <Controller
            name="instructor"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isMulti
                isClearable
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={instructors}
              />
            )}
          />
          {errors.instructor && (
            <span className="text-red-600 text-xs mt-2">
              {errors.instructor?.message}
            </span>
          )}
        </div>

        <div className="flex flex-col space-y-1">
          <label htmlFor="assistantInstructors" className="text-base">
            Select Assistant Instructors
          </label>
          <Controller
            name="assistantInstructors"
            control={control}
            render={({ field }) => (
              <Select
                isMulti
                {...field}
                isClearable
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={assistantInstructors}
                defaultValue=""
              />
            )}
          />

          {errors.assistantInstructors && (
            <span className="text-red-600 text-xs mt-2">
              {errors.assistantInstructors?.message}
            </span>
          )}
        </div>
        {isLoading ? (
          <div className="grid justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Assign Instructors</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default AssignInstructor;
