import React, { useState } from 'react';
import Select from 'react-select';
import { LineGraph } from './charts/LineGraph';
import Spinner from './spinner/Spinner';
import useFetchData from '../hooks/useFetchData';
import useFetchAnalytics from '../hooks/useFetchAnalytics';

export default function SponsoredCohortLoginAnalytics() {
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [interval, setInterval] = useState('');

  // get cohorts
  const { data: cohorts } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohorts
    ? cohorts?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: cohort.name,
      }))
    : [];

  const intervalOptions = [
    {
      value: 'daily',
      label: 'Daily',
    },
    {
      value: 'weekly',
      label: 'Weekly',
    },
    {
      value: 'monthly',
      label: 'Monthly',
    },
  ];

  const { data: cohortLogins, isLoading } = useFetchAnalytics(
    ['cohort-logins', cohortId, interval],
    `/${interval}-logins/${cohortId}`,
    {},
    "Couldn't get cohort logins. Please try again",
    cohortId && interval
  );

  return (
    <>
      <div className="bg-white px-2 py-5 rounded-lg">
        <p>
          <span className="font-medium">Cohort Logins Analytics: </span>
          <span className="underline underline-offset-1">
            Login statistics for learners, broken down by cohort
          </span>
        </p>
      </div>
      <div className="mt-4 grid grid-cols-4 bg-white p-3 gap-4 rounded-lg">
        <Select
          isClearable
          placeholder="Select Cohort"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={cohortOptions}
          onChange={(event) => {
            setCohortId(event?.value || null);
            setCohortName(event?.label || null);
          }}
        />
        <Select
          isClearable
          placeholder="Select Interval"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={intervalOptions}
          onChange={(event) => {
            setInterval(event?.value || null);
          }}
        />
      </div>
      {isLoading && cohortId && interval ? (
        <div className="flex items-center justify-center mt-5 bg-white py-4">
          <Spinner />
        </div>
      ) : (
        <div className="mt-4 grid grid-cols-1 gap-2 bg-white">
          <LineGraph
            title={
              cohortLogins?.data?.title ||
              `Student Logins ${cohortName ? `in ${cohortName}` : ''}`
            }
            colour="#8b173b"
            datasets={!cohortId ? [] : cohortLogins?.data?.datasets || []}
            labels={cohortLogins?.data?.labels}
            label="Number of learners"
          />
        </div>
      )}
    </>
  );
}
