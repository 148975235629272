import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useQuery } from '@tanstack/react-query';
import './Calendar.css';
import toast from 'react-hot-toast';
import { axiosInstancev2 } from '../../utils/Axios/axiosInterceptorv2';

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
function Calendar() {
  const [startDate, setStartDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(true);
  const [showEvents, setShowEvents] = useState(true);
  const [eventsInDay, setEventsInDay] = useState([]);

  const fetchEvents = async () => {
    const data = await axiosInstancev2.get('/events');
    return data;
  };

  const onFetchEventsError = () => {
    toast.error("Couldn't get Event. Please try again!");
  };

  const { data } = useQuery(['events'], fetchEvents, {
    staleTime: 3600 * 1000,
    cacheTime: 3600 * 1000,
    onError: onFetchEventsError,
  });

  const extractDayFromTimestamp = (eventDate) => {
    return new Date(eventDate).toLocaleDateString();
  };

  useEffect(() => {
    const getDay = new Date(startDate).toLocaleDateString();

    const events = data?.data?.events;
    if (events) {
      const eventsInADay = events.filter((event) => {
        return extractDayFromTimestamp(event.eventStartDate) === getDay;
      });
      return setEventsInDay(eventsInADay);
    }
    return setEventsInDay([]);
  }, [data?.data?.events, startDate]);

  const toggleCalendar = () => setShowCalendar(!showCalendar);
  const toggleEvents = () => setShowEvents(!showEvents);

  return (
    <div className=" h-screen bg-white flex-2 flex flex-col  rounded-md p-3 static no-scrollbar overflow-auto items-center pb-60">
      <div
        className="flex justify-between w-4/5 cursor-pointer"
        onClick={toggleCalendar}
      >
        <div className="flex space-x-2 mb-3">
          <InsertInvitationIcon />
          <p className="font-semibold">Calendar</p>
        </div>
        {showCalendar ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
      <div className={`${showCalendar ? '' : 'hidden'}`}>
        <ReactDatePicker
          inline
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          wrapperClassName="datePickerWrapper"
        />
      </div>
      <div className="mt-3  w-4/5 cursor-pointer">
        <div className="flex justify-between" onClick={toggleEvents}>
          <div className="flex space-x-2 mb-3 ">
            <EventAvailableIcon />
            <p className="font-semibold">Events</p>
          </div>
          {showEvents ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        <div className={`${showEvents ? '' : 'hidden'} space-y-5 `}>
          <p className="tracking-wide font-medium mb-1">{`${
            daysOfWeek[new Date(startDate).getDay()]
          } ${new Date(startDate).getDate()}`}</p>
          {/* event */}
          {eventsInDay.length < 1 && <p>No events on this day</p>}
          {eventsInDay.map((event) => {
            return (
              <div key={event.id}>
                <div className=" border-l-2 border-[#8b173b] pl-3 space-y-1">
                  <p className="text-sm tracking-wide font-semibold text-[#8b173b]">
                    {event.eventName}
                  </p>
                  <p className="text-sm tracking-wide font-semibold ">
                    {event.eventDescription}
                  </p>
                  <p>{new Date(event.eventStartDate).toDateString()}</p>
                  <p>
                    <small>
                      {new Date(event.eventStartDate).toLocaleTimeString()} -{' '}
                      {event.eventEndDate &&
                        new Date(event.eventEndDate).toLocaleTimeString()}
                    </small>
                  </p>
                  <div className=" flex text-xs align-middle items-center ">
                    {/* <LocationOnIcon fontSize="inherit" className="h-5 w-5" /> */}
                    <a
                      className="text-blue-700"
                      target="_blank"
                      href={event.eventVenue}
                      rel="noreferrer"
                    >
                      Join Meeting Here
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
          {/* event */}
        </div>
      </div>
    </div>
  );
}

export default Calendar;
