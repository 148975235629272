import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import Spinner from './spinner/Spinner';
import { roles } from '../utils/roles';
import useGetUserRole from '../hooks/useGetUserRole';
import useGetLoggedInUserId from '../hooks/useGetLoggedInUserId';
import ChangePassword from './ChangePassword';
import Modal from './Modal';
import { updateDetails } from '../features/auth/authSlice';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    firstname: yup.string().required('This is a required field'),
    lastname: yup.string().required('This is a required field'),
    email: yup.string().required('This is a required field'),
    role: yup.string().required('This is a required field'),
  })
  .required();

function UpdateUserDetails({ userDetails }) {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userId } = useParams();
  const role = useGetUserRole();
  const loggedInUserId = useGetLoggedInUserId();
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: userDetails?.firstname,
      lastname: userDetails?.lastname,
      email: userDetails?.email,
      role: userDetails?.role,
      profile_desc: userDetails?.profile_desc,
    },
  });
  const dispatch = useDispatch();

  const onProfileUpdateSuccess = () => {
    toast.success(
      `Successfully updated ${userDetails?.firstname} ${userDetails?.lastname}'s details!`
    );
    setIsSubmitting(false);
  };
  const onProfileUpdateFailure = () => {
    toast.error('Profile update failed! Please try again');
    setIsSubmitting(false);
  };

  const canUpdate = () => {
    if (loggedInUserId === userId) {
      return true;
    }
    return false;
  };

  const mutation = useMutation(
    (data) => {
      const url = canUpdate()
        ? '/users/administrator/me'
        : `/users/administrator/${userId}`;

      return axiosInstancev2.put(url, data);
    },
    {
      onSuccess: (data) => {
        if (canUpdate()) {
          dispatch(updateDetails(data.data.administrator));
        }
        onProfileUpdateSuccess();
        queryClient.invalidateQueries(['user-details', userId]);
      },
      onError: onProfileUpdateFailure,
    }
  );

  const onSubmit = async (requestData) => {
    setIsSubmitting(true);
    mutation.mutate(requestData);
  };

  return (
    <>
      <form
        className="bg-white px-4 py-5 mt-4 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <h4 className="mb-2 font-medium">Basic Information</h4>
        <div className="grid grid-cols-3 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="firstname" className="text-base">
              First Name
            </label>
            <input
              {...register('firstname', { required: true })}
              readOnly={!canUpdate()}
              type="text"
              id="firstname"
              name="firstname"
              placeholder={userDetails.firstname}
              className={`border-[#CBCBCB]  ${
                canUpdate() ? '' : 'bg-[#CBCBCB]'
              } border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.firstname ? 'border-red-600' : ''
              }`}
            />
            {errors.firstname && (
              <span className="text-red-600 text-xs mt-2">
                {errors.firstname?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lastname" className="text-base">
              Last Name
            </label>
            <input
              {...register('lastname', { required: true })}
              readOnly={!canUpdate()}
              type="text"
              id="lastname"
              placeholder={userDetails.lastname}
              className={`border-[#CBCBCB]  ${
                canUpdate() ? '' : 'bg-[#CBCBCB]'
              } border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.lastname ? 'border-red-600' : ''
              }`}
            />
            {errors.lastname && (
              <span className="text-red-600 text-xs mt-2">
                {errors.lastname?.message}
              </span>
            )}
          </div>
          {role === 'SuperAdmin' ? (
            <>
              <div className="flex flex-col space-y-1">
                <label htmlFor="Email" className="text-base">
                  Email
                </label>
                <input
                  {...register('email', { required: true })}
                  type="email"
                  id="Email"
                  placeholder={userDetails.email}
                  className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                    errors.Email ? 'border-red-600' : ''
                  }`}
                />
                {errors.Email && (
                  <span className="text-red-600 text-xs mt-2">
                    {errors.Email?.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col mb-7">
                <label htmlFor="skillLevel" className="text-base">
                  Select Role
                </label>
                <select
                  {...register('role', { required: true })}
                  name="role"
                  id="role"
                  className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
                  // onChange={(event) => setRole(event.target.value)}
                >
                  <option value="">Select Option</option>
                  {roles?.map((roleOption) => (
                    <option key={roleOption.value} value={roleOption.value}>
                      {roleOption.label}
                    </option>
                  ))}
                </select>
                {errors.role && (
                  <span className="text-red-600 text-xs mt-2">
                    {errors.role?.message}
                  </span>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col space-y-1">
                <label htmlFor="Email" className="text-base">
                  Email
                </label>
                <input
                  readOnly={!canUpdate()}
                  {...register('email', { required: true })}
                  type="email"
                  id="Email"
                  placeholder="Email Address"
                  className={`border-[#CBCBCB] ${
                    canUpdate() ? '' : 'bg-[#CBCBCB]'
                  } border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                    errors.Email ? 'border-red-600' : ''
                  }`}
                />
                {errors.Email && (
                  <span className="text-red-600 text-xs mt-2">
                    {errors.Email?.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col space-y-1">
                <label htmlFor="role" className="text-base">
                  Role
                </label>
                <input
                  {...register('role', { required: true })}
                  readOnly={!canUpdate()}
                  type="text"
                  id="role"
                  placeholder="Last Name"
                  className={`border-[#CBCBCB] bg-[#CBCBCB]  border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                    errors.role ? 'border-red-600' : ''
                  }`}
                />
              </div>
            </>
          )}
          {canUpdate() && (
            <>
              <div className="flex flex-col align-middle items-center justify-center space-y-1">
                <button
                  type="button"
                  className="w-full bg-claret-500 text-white text-sm py-2 rounded-md cursor-pointer uppercase font-medium"
                  onClick={toggleModal}
                >
                  Change Password
                </button>
              </div>
              <div className="flex flex-col col-span-3 space-y-1">
                <label htmlFor="profile_desc" className="text-base">
                  Bio
                </label>
                <textarea
                  rows={5}
                  readOnly={!canUpdate()}
                  {...register('profile_desc', { required: true })}
                  type="profile_desc"
                  id="profile_desc"
                  placeholder="Enter Bio"
                  className={`border-[#CBCBCB] ${
                    canUpdate() ? '' : 'bg-[#CBCBCB]'
                  } h-fit border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                    errors.profile_desc ? 'border-red-600' : ''
                  }`}
                />
                {errors.profile_desc && (
                  <span className="text-red-600 text-xs mt-2">
                    {errors.profile_desc?.message}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
        {isSubmitting ? (
          <div className="flex justify-center align-middle items-center">
            <Spinner />
          </div>
        ) : (
          (role === 'SuperAdmin' || canUpdate()) && (
            <div className="mt-4 flex justify-end">
              <button
                type="submit"
                className="bg-claret-500 text-white text-sm py-1 px-3 rounded-md cursor-pointer uppercase font-medium flex items-center align-middle space-x-3"
              >
                <p>Update Details</p>
                <SaveIcon fontSize="inherit" />
              </button>
            </div>
          )
        )}
      </form>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Change Password"
      >
        <ChangePassword toggleModal={toggleModal} />
      </Modal>
    </>
  );
}

export default UpdateUserDetails;
