import React from 'react';
import { useNavigate } from 'react-router-dom';

function TutorialItem({ tutorial }) {
  const navigate = useNavigate();

  return (
    <div
      className="py-2 px-2 border-[1px] border-[#00999E] rounded-md"
      onClick={() => navigate(`/tutorials/${tutorial.id}`)}
    >
      <div className=" flex align-top items-start space-x-4">
        <img
          className="object-contain shadow-lg"
          src={tutorial.image}
          alt="tutorial-thumbnail"
          height={100}
          width={100}
        />
        <p>{tutorial.description}</p>
      </div>
    </div>
  );
}

export default TutorialItem;
