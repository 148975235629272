import React, { Fragment, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Tab } from '@headlessui/react';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupMembers from '../components/GroupMembers';
import GroupAssignments from '../components/GroupAssignments';
import GroupSubmissions from '../components/GroupSubmissions';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import usePermissions from '../hooks/usePermissions';
import Modal from '../components/Modal';
import AddGroupMember from '../components/AddGroupMember';
import GroupChat from '../components/GroupChat';

const tabs = [
  {
    id: 1,
    title: 'Group Members',
    component: <GroupMembers />,
  },
  {
    id: 2,
    title: 'Assignments',
    component: <GroupAssignments />,
  },
  { id: 3, title: 'Submissions', component: <GroupSubmissions /> },
  { id: 3, title: 'Group Chat', component: <GroupChat /> },
];

function GroupDetail() {
  const permissions = usePermissions();
  const role = useGetUserRole();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/groups"
            className="flex items-center"
          >
            <GroupsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p className="">Groups</p>
          </NavLink>
        </Breadcrumbs>
        <div className=" flex space-x-2">
          {(permissions?.includes('AddGroupMember') ||
            role === 'SuperAdmin') && (
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleModal}
            >
              Add Group Member
            </button>
          )}
        </div>
      </div>
      <div className="mt-4 p-2 bg-white">
        <Tab.Group manual>
          <Tab.List className=" flex space-x-5 ">
            {tabs.map((tab) => (
              <Tab as={Fragment} key={tab.id}>
                {({ selected }) => (
                  <div
                    className={` focus:outline-none cursor-pointer p-2 hover:bg-lms-custom-50
                     ${
                       selected
                         ? ' text-[#8B173B] border-b-2 border-[#8B173B]'
                         : '  hover:border-b-2 hover:border-lms-custom-100'
                     }
                    `}
                  >
                    <p>{tab.title}</p>
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {tabs.map((tab) => (
              <Tab.Panel key={tab.id}>{tab.component}</Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Add Group Member"
      >
        <AddGroupMember toggleModal={toggleModal} />
      </Modal>
    </MainContent>
  );
}

export default GroupDetail;
