import React, { useState } from 'react';
import Select from 'react-select';
import useFetchData from '../hooks/useFetchData';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import TableWrapper from '../utils/TableWrapper';
import Spinner from './spinner/Spinner';

export default function SponsoredCohortFinalProjects() {
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');

  const columns = [
    { title: 'Email', field: 'email' },
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row?.firstname}</p>,
    },
    {
      title: 'Middle Name',
      field: 'middlename',
      render: (row) => <p className="capitalize">{row?.middlename}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row?.lastname}</p>,
    },
    { title: 'Project Link', field: 'project_link' },
    { title: 'Country', field: 'country' },
    { title: 'Phone Number', field: 'phone_number' },
    { title: 'Gender', field: 'gender' },
    { title: 'Academic Level', field: 'level_of_education' },
  ];

  // get cohorts
  const { data: cohorts } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohorts
    ? cohorts?.data?.cohorts
        ?.filter((cohort) => cohort?.status === 'ended')
        .map((cohort) => ({
          value: cohort.id,
          label: cohort.name,
        }))
    : [];

  const { data: cohortStudentsProjectsData, isLoading } = useFetchAnalytics(
    ['cohort-projects-raw-data', cohortId],
    `/raw-data/cohort-students-projects/${cohortId}`,
    {},
    "Couldn't get cohort learners' projects raw data. Please try again",
    !!cohortId
  );

  return (
    <>
      <div className="bg-white px-2 py-5 rounded-lg">
        <p>
          <span className="font-medium">Cohort Learners&apos; Projects: </span>
          <span className="underline underline-offset-1">
            Retrieve detailed information of students who have submitted their
            projects, including their personal details along with their
            respective project submission links.
          </span>
        </p>
      </div>
      <div className="mt-4 grid grid-cols-4 bg-white p-3 gap-4 rounded-lg">
        <Select
          isClearable
          placeholder="Select Cohort"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={cohortOptions}
          onChange={(event) => {
            setCohortId(event?.value || '');
            setCohortName(event?.label || '');
          }}
        />
      </div>{' '}
      <div className="mt-4">
        {isLoading && cohortId ? (
          <div className="flex items-center justify-center bg-white">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title={`${cohortName} Learners' Submitted Projects`}
            columns={columns}
            data={
              cohortStudentsProjectsData ? cohortStudentsProjectsData?.data : []
            }
            options={{
              rowStyle: {
                overflowWrap: 'break-word',
              },
            }}
          />
        )}
      </div>
    </>
  );
}
