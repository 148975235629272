import React, { useState, useEffect } from 'react';
import GroupsIcon from '@mui/icons-material/Groups';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';
import { PieChart } from '../components/charts/PieChart';
import MainContent from '../components/MainContent';
import { VerticalBar } from '../components/charts/VerticalBar';
import { RadarChart } from '../components/charts/Radar';
import { DoughnutChart } from '../components/charts/DoughnutChart';
import Spinner from '../components/spinner/Spinner';
import { LineGraph } from '../components/charts/LineGraph';
import useFetchAnalytics from '../hooks/useFetchAnalytics';

import { getNotifications } from '../features/notification/notificationSlice';

const notificationSocketUrl =
  process.env.REACT_APP_NOTIFICATION_SOCKET_URL ||
  'wss://api.lms.v2.powerlearnprojectafrica.org/notification/socket';

function Dashboard() {
  const [genderData, setGenderData] = useState({});
  const [countryData, setCountryData] = useState({});
  const [cohortData, setCohortData] = useState({});
  const [cohorts, setCohorts] = useState({});
  const [loginData, setLoginData] = useState({});
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [notifications, setNotifications] = useState({
    count: 0,
    messages: [],
  });

  useEffect(() => {
    const socketConnection = new WebSocket(
      `${notificationSocketUrl}/notification?Authorization=${localStorage.getItem(
        'token'
      )}`
    );

    socketConnection.onmessage = (event) => {
      setNotifications({
        count: JSON.parse(event.data)?.length || 0,
        messages: JSON.parse(event.data) || [],
      });
      dispatch(
        getNotifications({
          count: JSON.parse(event.data)?.length || 0,
          messages: JSON.parse(event.data) || [],
        })
      );
    };
    // setSocket(socketConnection);
  }, [dispatch]);

  const { data: analyticsData, isLoading } = useFetchAnalytics(
    ['dashboard-analytics'],
    '/',
    {},
    "Couldn't fetch data. Please try again!",
    true
  );

  useEffect(() => {
    if (analyticsData) {
      setGenderData(
        analyticsData?.data?.graphs_data?.find(
          (data) => data.title === 'number of students per gender'
        )
      );
      setCountryData(
        analyticsData?.data?.graphs_data?.find(
          (data) => data.title === 'number of students per country'
        )
      );
      setCohortData(
        analyticsData?.data?.graphs_data?.find(
          (data) => data.title === 'number of students per cohort'
        )
      );
      setCohorts(
        analyticsData?.data?.graphs_data?.find(
          (data) => data.title === 'Cohorts'
        )
      );
      setLoginData(
        analyticsData?.data?.graphs_data?.find(
          (data) => data.title === 'admins daily logins'
        )
      );
    }
  }, [analyticsData]);

  return (
    <>
      <MainContent>
        {isLoading ? (
          <div className="mt-5 flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className=" space-y-5">
              <div className="bg-white px-2 py-5 rounded-lg">
                <p>Dashboard</p>
              </div>
              <div className="grid md:grid-cols-4 grid-cols-1  gap-2">
                <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
                  <div className=" flex items-center justify-between">
                    <div className=" flex flex-col  space-y-2">
                      <p className="font-extralight text-xs">Total Students</p>
                      <CountUp
                        duration={1}
                        className="font-semibold text-center text-xl"
                        end={analyticsData?.data?.number_of_students}
                      />
                    </div>
                    <div className="h-14 w-14 bg-[#8B173B] text-center text-4xl rounded-full flex items-center justify-center">
                      <GroupsIcon
                        fontSize="inherit"
                        className="text-center text-white"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
                  <div className=" flex items-center justify-between">
                    <div className=" flex flex-col  space-y-2">
                      <p className="font-extralight text-xs">Total Staff</p>
                      <CountUp
                        duration={1}
                        className="font-semibold text-center text-xl"
                        end={analyticsData?.data?.number_of_admin_users}
                      />
                    </div>
                    <div className="h-14 w-14 bg-[#00999e] text-center text-4xl rounded-full flex items-center justify-center">
                      <GroupsIcon
                        fontSize="inherit"
                        className="text-center text-white"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
                  <div className=" flex items-center justify-between">
                    <div className=" flex flex-col  space-y-2">
                      <p className="font-extralight text-xs">
                        Number of Courses
                      </p>
                      <CountUp
                        duration={1}
                        className="font-semibold text-center text-xl"
                        end={analyticsData?.data?.number_of_courses}
                      />
                    </div>
                    <div className="h-14 w-14 bg-[#57C13E] text-center text-4xl rounded-full flex items-center justify-center">
                      <TimelapseIcon
                        fontSize="inherit"
                        className="text-center text-white"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-white py-3 rounded-lg col-span-1 px-2 space-y-3">
                  <div className=" flex items-center justify-between">
                    <div className=" flex flex-col  space-y-2">
                      <p className="font-extralight text-xs">
                        Number of Cohorts
                      </p>
                      <CountUp
                        duration={1}
                        className="font-semibold text-center text-xl"
                        end={analyticsData?.data?.number_of_cohorts}
                      />
                    </div>
                    <div className="h-14 w-14 bg-[#DB60FF] text-center text-4xl rounded-full flex items-center justify-center">
                      <WorkspacePremiumIcon
                        fontSize="inherit"
                        className="text-center text-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 grid grid-cols-1 gap-2 bg-white">
              <LineGraph
                title="Adminstrator Daily Logins"
                colour="#8b173b"
                datasets={loginData?.datasets || []}
                labels={loginData?.labels}
                label="Number of Administrators"
              />
            </div>
            <div className="mt-5 grid grid-cols-2 gap-2">
              {genderData && (
                <div className="bg-white">
                  <PieChart
                    labels={genderData.labels}
                    colours={['#8b173b', '#00999e', '#ffe9f0']}
                    title="Students by Gender"
                    dataset={genderData.datasets || []}
                  />
                </div>
              )}
              {countryData && (
                <div className="bg-white">
                  <VerticalBar
                    labels={countryData.labels}
                    colour="#00999e"
                    title="Numbers of Students Per Country"
                    label="Students"
                    datasets={countryData.datasets || []}
                  />
                </div>
              )}
              {cohortData && (
                <div className="bg-white">
                  {/* <HorizontalBar */}
                  <RadarChart
                    labels={cohortData.labels}
                    colour="#8b173b"
                    title="Numbers of Students Per Cohort"
                    label="Students"
                    datasets={cohortData.datasets || []}
                  />
                </div>
              )}
              {cohorts && (
                <div className="bg-white">
                  <DoughnutChart
                    datasets={cohorts.datasets || []}
                    labels={cohorts.labels}
                    title="Number of Cohorts"
                    label="Number of Cohorts"
                    backgroundColor={[
                      'rgba(255, 159, 64, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(255, 99, 132, 0.2)',
                    ]}
                    borderColor={[
                      'rgba(255, 159, 64, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(255, 99, 132, 1)',
                    ]}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default Dashboard;
