import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HomeIcon from '@mui/icons-material/Home';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import toast from 'react-hot-toast';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate, useParams } from 'react-router';
import Modal from '../components/Modal';
import TableWrapper from '../utils/TableWrapper';
import Spinner from '../components/spinner/Spinner';
import MainContent from '../components/MainContent';
import CreateQuiz from '../components/CreateQuiz';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import UpdateQuiz from '../components/UpdateQuiz';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';

function QuizList() {
  const { weekId, weekName } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [quizId, setQuizId] = useState('');
  const [quizName, setQuizName] = useState('');
  const [quizDuration, setQuizDuration] = useState('');
  const [quizPassMark, setQuizPassMark] = useState('');
  const [quizDescription, setQuizDescription] = useState('');
  const [quizIsCompulsory, setQuizIsCompulsory] = useState('');
  const permissions = usePermissions();
  const role = useGetUserRole();

  const queryClient = useQueryClient();

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const toggleUpdateModal = () => {
    setUpdateModalOpen(!updateModalOpen);
  };

  const columns = [
    { title: 'Quiz Name', field: 'quizName', width: '15%' },
    { title: 'Duration (mins)', field: 'duration', width: '15%' },
    { title: 'Pass Mark (%)', field: 'percent_pass_mark', width: '15%' },
    {
      title: 'Compulsory',
      field: 'percent_pass_mark',
      width: '15%',
      render: (row) => (row.is_compulsory ? <p>Yes</p> : <p>No</p>),
    },
    {
      title: 'Actions',
      render: (row) => (
        <div className="space-x-2">
          {(permissions?.includes('CreateQuiz') || role === 'SuperAdmin') && (
            <button
              onClick={() =>
                navigate(
                  `/courses/modules/weeks/quiz-${row.quizName}/${row.id}/questions`
                )
              }
              type="button"
              className="p-1 px-2 bg-[#00999E] text-white rounded-md"
            >
              Add Questions
            </button>
          )}
          {(permissions?.includes('UpdateQuiz') || role === 'SuperAdmin') && (
            <button
              onClick={() => {
                setQuizId(row.id);
                setQuizName(row.quizName);
                setQuizPassMark(row.percent_pass_mark);
                setQuizDuration(row.duration);
                setQuizDescription(row.description);
                setQuizIsCompulsory(row.is_compulsory || false);
                toggleUpdateModal();
              }}
              type="button"
              className="p-1 px-2 bg-blue-700 text-white rounded-md"
            >
              Edit Quiz
            </button>
          )}
          {(permissions?.includes('DeleteQuiz') || role === 'SuperAdmin') && (
            <button
              onClick={() => {
                setQuizId(row.id);
                setQuizName(row.quizName);
                toggleDeleteModal();
              }}
              type="button"
              className="p-1 px-2 bg-red-700 text-white rounded-md"
            >
              Delete Quiz
            </button>
          )}
        </div>
      ),
    },
  ];

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const fetchQuizes = async () => {
    const response = await axiosInstancev2.get('/quizes', {
      params: { weekId },
    });
    return response;
  };

  const onFetchQuizesFailure = () => {
    toast.error("Couldn't get quizes. Please try again!");
  };
  const { data, isLoading } = useQuery(['week-quizes', weekId], fetchQuizes, {
    staleTime: 3600 * 1000,
    cacheTime: 3600 * 1000,
    onError: onFetchQuizesFailure,
  });

  const onVQuizDeletionSuccess = () => {
    toast.success('Quiz successfuly deleted');
    toggleDeleteModal();
    queryClient.invalidateQueries(['week-quizes', weekId]);
  };

  const onVideoDeletionError = () => {
    toast.error("Couldn't delete quiz. Please try again");
  };

  const { mutate, isLoading: deletingQuiz } = useMutation(
    () => {
      return axiosInstancev2.delete(`/quizes/${quizId}`);
    },
    {
      onSuccess: onVQuizDeletionSuccess,
      onError: onVideoDeletionError,
    }
  );

  const deleteQuizHandler = () => {
    mutate(quizId);
  };

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/courses"
              className="flex items-center"
            >
              <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Courses</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={() => navigate(-2)}
              to={() => navigate(-2)}
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Modules</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={() => navigate(-1)}
              to={() => navigate(-1)}
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Module Overview</p>
            </NavLink>
          </Breadcrumbs>
          {(permissions?.includes('CreateQuiz') || role === 'SuperAdmin') && (
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleModal}
            >
              Create Quiz
            </button>
          )}
        </div>
        <div className="mt-4">
          {isLoading ? (
            <div className="w-full flex align-middle justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <TableWrapper
              title={`Quizes in ${weekName}`}
              columns={columns}
              // eslint-disable-next-line no-unsafe-optional-chaining
              data={data ? (data?.data).quizes : []}
              // onRowClick={(event, rowData) => navigate(`/group/${rowData.id}`)}
            />
          )}
        </div>
      </MainContent>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Create Quiz"
      >
        <CreateQuiz toggleModal={toggleModal} />
      </Modal>
      <Modal title="Delete Quiz" modalOpen={deleteModalOpen}>
        <p>
          Are you sure you want to delete the quiz:{' '}
          <span className="font-medium">{quizName}</span>?
        </p>

        {deletingQuiz ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-10">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleDeleteModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="button"
              onClick={deleteQuizHandler}
            >
              <DeleteIcon fontSize="inherit" />
              <p>Delete Quiz</p>
            </button>
          </div>
        )}
      </Modal>
      <Modal title="Update Quiz" modalOpen={updateModalOpen}>
        <UpdateQuiz
          toggleModal={toggleUpdateModal}
          quizId={quizId}
          quizName={quizName}
          quizPassMark={quizPassMark}
          quizDuration={quizDuration}
          quizDescription={quizDescription}
          quizIsCompulsory={quizIsCompulsory}
        />
      </Modal>
    </>
  );
}

export default QuizList;
