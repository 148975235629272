/* eslint-disable no-unused-vars */
import { useNavigate, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ReactTimeAgo from 'react-time-ago';
import { Avatar, Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { NavLink } from 'react-router-dom';
import { AirplaneTicketOutlined } from '@mui/icons-material';
import MainContent from '../components/MainContent';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';
import useParsedData from '../hooks/useParsedData';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';

function TicketDetail() {
  const navigate = useNavigate();

  const { ticketid } = useParams();
  const permissions = usePermissions();
  const role = useGetUserRole();

  const fetchTicket = async () => {
    const res = await axiosInstancev2.get(`support/tickets/${ticketid}`);
    return res;
  };

  const closeTicket = async () => {
    const res = await axiosInstancev2.put(`support/tickets/${ticketid}/close`);
    toast.success('Ticket closed successfully');
    navigate('/tickets');
  };

  const {
    data: ticketData,
    isLoading,
    isError,
  } = useQuery(['ticket'], fetchTicket, {
    staleTime: 3600,
    cacheTime: 3600 * 10000,
  });

  const ticket = ticketData?.data?.ticket;

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const day = date.getDate();
    const time = date.toLocaleTimeString('en-US', { hour12: false });
    return `${month} ${day} ${year}, ${time}`;
  };

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/tickets"
              className="flex items-center"
            >
              <AirplaneTicketOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Tickets</p>
            </NavLink>
          </Breadcrumbs>
        </div>
        <div className="mt-8 px-2 bg-white py-5 rounded-lg">
          <div className="flex justify-between items-center  px-4">
            <div className="flex items-center mb-2">
              <Avatar sx={{ width: 50, height: 50 }}>
                {ticket?.user_full_name?.toUpperCase().split(' ')[0][0]}
              </Avatar>
              <div className="text-md font-roboto px-2">
                <p className="capitalize ">
                  <span className="font-bold">{ticket?.user_full_name}</span>
                  <span className="ml-2 text-claret-500">
                    &lt;{ticket?.user_email?.toLowerCase()}&gt;
                  </span>
                </p>
                <p className="text-xs font-bold mt-3">
                  Posted {formatTimestamp(ticket?.created_at)}
                </p>
              </div>
            </div>
            {(permissions?.includes('UpdateTicket') ||
              role === 'SuperAdmin') && (
              <div className="flex space-x-2">
                <button
                  type="button"
                  className={` text-sm py-1 px-5 rounded-md  ${
                    (ticket?.status && ticket?.status) === 'CLOSED'
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-claret-500 text-white cursor-pointer'
                  }`}
                  onClick={closeTicket}
                  disabled={ticket?.status === 'CLOSED'}
                >
                  Close
                </button>
              </div>
            )}
          </div>
          <div className="mt-5">
            <h2>
              <span className="font-semibold mr-2">Subject: </span>{' '}
              {ticket?.subject
                ?.toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase())}
            </h2>
          </div>
          <div className="mt-5 space-y-4">
            <h2 className="font-semibold">Description</h2>
            {useParsedData(ticket?.description || '')}
          </div>
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default TicketDetail;
