import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Select from 'react-select';
// import { toolbarOptions } from '../utils/editorToolbar';
import { fileToBase64 } from '../helpers/convertToBase64';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const categories = [
  'Web Development',
  'Mobile Development',
  'Programming Languages',
  'Entrepreneurship ',
  'Communication ',
  'Network & Security',
  'Operating Systems',
  'Web Design',
];

const categoryOptions = categories?.map((category) => ({
  value: category,
  label: category,
}));

const schema = yup
  .object({
    description: yup.string().required('This is a required field'),
    content: yup.string().required('This is a required field'),
    title: yup
      .string()
      .required('This is a required field')
      .min(50, 'Title should be at least 50 characters'),
    thumbnailImage: yup
      .mixed()
      .required('Please select an image')
      .test('fileSize', 'Please upload image less than 2MB', (value) => {
        return value && value[0]?.size <= 2000000;
      }),
    category: yup
      .object()
      .shape({
        label: yup.string().required('course is required (from label)'),
        value: yup.string().required('category is required'),
      })
      .nullable()
      .required('Please select a category'),
  })
  .required();

function CreateTutorial({ toggleModal }) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // // wysiwyg
  // // check if is empty
  // const [content, setContent] = useState('');

  // const isEmpty = content === '<p><br></p>' || content === '';

  // const onEditorStateChange = (editorState) => {
  //   setContent(editorState);
  // };
  // // wysiwyg

  const queryClient = useQueryClient();
  const onTutorialCreationSuccess = () => {
    toast.success('Tutorial successfully created!');
    toggleModal();
    setIsSubmitting(false);
  };
  const onTutorialCreationFailure = () => {
    toast.error('Tutorial creation failed! Please try again');
    setIsSubmitting(false);
  };
  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/tutorials', data);
    },
    {
      onSuccess: () => {
        onTutorialCreationSuccess();
        queryClient.invalidateQueries(['tutorials']);
      },
      onError: onTutorialCreationFailure,
    }
  );
  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const base64Url = await fileToBase64(data.thumbnailImage[0]);
    const requestBody = {
      ...data,
      category: data.category.value,
      image: base64Url,
    };
    mutation.mutate(requestBody);
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="title" className="text-base">
            Title
          </label>
          <input
            {...register('title', { required: true })}
            type="text"
            id="title"
            placeholder="title"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.title ? 'border-red-600' : ''
            }`}
          />
          {errors.title && (
            <span className="text-red-600 text-xs mt-2">
              {errors.title?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="category" className="text-base">
            Select Category
          </label>
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <Select
                // defaultValue={options[0]}
                {...field}
                isClearable
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={categoryOptions}
              />
            )}
          />
          {errors.category && (
            <span className="text-red-600 text-xs mt-2">
              {errors.category?.message}
            </span>
          )}
        </div>

        <div className="flex flex-col space-y-1">
          <label htmlFor="description" className="text-base">
            Tutorial Description
          </label>
          <input
            {...register('description', { required: true })}
            type="text"
            id="description"
            placeholder="Description"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.description ? 'border-red-600' : ''
            }`}
          />
          {errors.description && (
            <span className="text-red-600 text-xs mt-2">
              {errors.description?.message}
            </span>
          )}
        </div>

        <div className="flex flex-col space-y-1">
          <label htmlFor="thumbnailImage" className="text-base">
            Thumbnail Image
          </label>
          <input
            {...register('thumbnailImage', {
              required: true,
            })}
            accept="image/gif, image/jpeg"
            type="file"
            id="thumbnailImage"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.thumbnailImage ? 'border-red-600' : ''
            }`}
          />
          {errors.thumbnailImage && (
            <span className="text-red-600 text-xs mt-2">
              {errors.thumbnailImage?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="content" className="text-base">
            Video Url
          </label>
          <input
            {...register('content', { required: true })}
            type="text"
            id="content"
            placeholder="Enter video url"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.content ? 'border-red-600' : ''
            }`}
          />
          {errors.content && (
            <span className="text-red-600 text-xs mt-2">
              {errors.content?.message}
            </span>
          )}
        </div>
        {/* <div className="flex flex-col space-y-1 max-w-3xl">
          <label htmlFor="content" className="text-base">
            Tutorial Content
          </label>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={onEditorStateChange}
            modules={{ toolbar: toolbarOptions }}
          />
          {isEmpty && (
            <span className="text-red-600 text-xs mt-2">
              Please Enter Tutorial Content
            </span>
          )}
        </div> */}
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Tutorial</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateTutorial;
