/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Select from 'react-select';
import Calendar from '../components/calendar/Calendar';
import { MultiVerticalBar } from '../components/charts/MultiVerticalBar';
import MainContent from '../components/MainContent';
import RightBar from '../components/RightBar';
import Spinner from '../components/spinner/Spinner';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import useFetchData from '../hooks/useFetchData';
import { LineGraph } from '../components/charts/LineGraph';

function PaidModulesStudentProgressAnalytics() {
  const [paidModuleId, setPaidModuleId] = useState('');
  const [paidModuleName, setPaidModuleName] = useState('');
  const [paidCohortId, setPaidCohortId] = useState('');
  const [paidCohortName, setPaidCohortName] = useState('');

  // get modules
  const { data: paidModules } = useFetchData(
    ['course-modules', true],
    `/course-modules`,
    {
      params: { is_paid_module: true },
    },
    "Couldn't fetch paid modules. Please try again!",
    true
  );

  const moduleOptions = paidModules
    ? paidModules?.data?.course_modules
        .filter((paidModule) => paidModule.is_published)
        .map((paidModule) => ({
          value: paidModule.id,
          label: paidModule.moduleName,
        }))
    : [];

  const { data: paidCohorts } = useFetchData(
    ['paid-cohorts'],
    '/paid-cohorts',
    {},
    "Couldn't get paid cohorts. Please try again!",
    true
  );

  const cohortOptions = paidCohorts
    ? paidCohorts?.data?.paid_cohorts?.map((paid_cohort) => ({
        value: paid_cohort.id,
        label: `${paid_cohort.name} (${
          paid_cohort.no_of_students || 0
        } Students)`,
      }))
    : [];

  const { data, isLoading } = useFetchAnalytics(
    ['paid-cohort-students-progress', paidCohortId, paidModuleId],
    `/students-progress/paid-cohort`,
    { params: { paid_cohort_id: paidCohortId, module_id: paidModuleId } },
    "Couldn't get students progress results. Please try again",
    paidCohortId && paidModuleId
  );

  return (
    <>
      <MainContent>
        <div className=" space-y-5">
          <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
            >
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/"
                className="flex items-center"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Home</p>
              </NavLink>
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/analytics"
                className="flex items-center"
              >
                <AnalyticsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Analytics</p>
              </NavLink>
            </Breadcrumbs>
          </div>
          <div className="bg-white px-2 py-5 rounded-lg">
            <p>
              <span className="font-medium">
                Paid Modules Student Progress Analytics:{' '}
              </span>
              <span className="underline underline-offset-1">
                Comprehensive breakdown of students completion rates for each
                week within each module, segmented by paid cohort.
              </span>
            </p>
          </div>
          <div className="grid grid-cols-4 bg-white p-3 gap-4">
            <Select
              //   {...field}
              isClearable
              placeholder="Select Paid Cohort"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={cohortOptions}
              onChange={(event) => {
                setPaidCohortName(event?.label ?? null);
                setPaidCohortId(event?.value || null);
              }}
            />
            <Select
              //   {...field}
              isClearable
              placeholder="Select Role-Based Module"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={moduleOptions}
              onChange={(event) => {
                setPaidModuleId(event?.value || null);
                setPaidModuleName(event?.label || null);
              }}
            />
          </div>
          {paidModuleId && paidCohortId && isLoading ? (
            <div className="mt-5 flex justify-center bg-white">
              <Spinner />
            </div>
          ) : (
            <div className="mt-5 grid grid-cols-2 gap-2 bg-white">
              <div className=" col-span-2">
                <LineGraph
                  title={`${paidModuleName ?? ''} Module Progress ${
                    paidCohortName ? ` - ${paidCohortName}` : ''
                  }`}
                  colour="#8b173b"
                  datasets={data ? data?.data?.graph_data.datasets : []}
                  labels={data ? data?.data?.graph_data.labels : []}
                  label="Number of students"
                />
              </div>
            </div>
          )}
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default PaidModulesStudentProgressAnalytics;
