import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Spinner from './spinner/Spinner';
import { roles } from '../utils/roles';
import { fileToBase64 } from '../helpers/convertToBase64';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    firstname: yup.string().required('This is a required field'),
    lastname: yup.string().required('This is a required field'),
    email: yup.string().required('Email required').email('Enter a valid email'),
    role: yup.string().required('This is a required field'),
    profile_image: yup
      .mixed()
      .required('Please select an image')
      .test('fileSize', 'Please upload image less than 2MB', (value) => {
        return value && value[0]?.size <= 2000000;
      }),
    profile_desc: yup.string().when('role', {
      is: (role) =>
        role.toLowerCase() === 'instructor' ||
        role.toLowerCase() === 'assistantinstructor',
      then: yup.string().required('This is a required field'),
    }),
  })
  .required();

function CreateUser({ toggleModal }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [role, setRole] = useState('');

  // user funnctions
  const queryClient = useQueryClient();
  const onUserCreationSuccess = () => {
    toast.success('User successfully created!');
    toggleModal();
  };
  const onUserCreationFailure = () => {
    toast.error('User creation failed! Please try again');
  };
  const onSettled = () => setIsSubmitting(false);

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/users/administrator', data);
    },
    {
      onSuccess: () => {
        onUserCreationSuccess();
        queryClient.invalidateQueries(['users']);
      },
      onError: onUserCreationFailure,
      onSettled,
    }
  );
  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const base64Url = await fileToBase64(data.profile_image[0]);
    const requestData = { ...data, profile_image: base64Url };
    mutation.mutate(requestData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      <div className="flex flex-col mb-5">
        <label
          htmlFor="firstname"
          className="text-xs text-mine-shaft-500 mb-1 pl-1"
        >
          First Name
        </label>
        <input
          id="firstname"
          {...register('firstname', { required: true, maxLength: 15 })}
          className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
            errors.firstname ? 'border-red-600 focus:outline-red-600' : ''
          }`}
          type="text"
          placeholder="First Name"
        />
        {errors.firstname && (
          <span className="text-red-600 text-xs mt-1">
            {errors.firstname?.message}
          </span>
        )}
      </div>

      <div className="flex flex-col mb-5">
        <label
          htmlFor="lastname"
          className="text-xs text-mine-shaft-500 mb-1 pl-1"
        >
          Last Name
        </label>
        <input
          id="lastname"
          {...register('lastname', { required: true, maxLength: 15 })}
          className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
            errors.lastname ? 'border-red-600 focus:outline-red-600' : ''
          }`}
          type="text"
          placeholder="Last Name"
        />
        {errors.lastname && (
          <span className="text-red-600 text-xs mt-1">
            {errors.lastname?.message}
          </span>
        )}
      </div>

      <div className="flex flex-col mb-5">
        <label
          htmlFor="email"
          className="text-xs text-mine-shaft-500 mb-1 pl-1"
        >
          Email Address
        </label>
        <input
          id="email"
          {...register('email', { required: true, maxLength: 15 })}
          className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
            errors.email ? 'border-red-600 focus:outline-red-600' : ''
          }`}
          type="email"
          placeholder="Email"
        />
        {errors.email && (
          <span className="text-red-600 text-xs mt-1">
            {errors.email?.message}
          </span>
        )}
      </div>

      <div className="flex flex-col mb-7">
        <label htmlFor="skillLevel" className="text-base">
          Select Role
        </label>
        <select
          {...register('role', { required: true })}
          required
          name="role"
          id="role"
          className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
          onChange={(event) => setRole(event.target.value)}
        >
          <option value="">Select Option</option>
          {roles?.map((roleOption) => (
            <option key={roleOption.value} value={roleOption.value}>
              {roleOption.label}
            </option>
          ))}
        </select>
        {errors.role && (
          <span className="text-red-600 text-xs mt-2">
            {errors.role?.message}
          </span>
        )}
      </div>
      <div className="flex flex-col space-y-1 mb-7">
        <label htmlFor="profile_image" className="text-base">
          Profile Image
        </label>
        <input
          {...register('profile_image', {
            required: true,
          })}
          accept="image/jpg, image/jpeg"
          type="file"
          id="profile_image"
          className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
            errors.profile_image ? 'border-red-600' : ''
          }`}
        />
        {errors.profile_image && (
          <span className="text-red-600 text-xs mt-2">
            {errors.profile_image?.message}
          </span>
        )}
      </div>
      {(role.toLowerCase() === 'instructor' ||
        role.toLowerCase() === 'assistantinstructor' ||
        role.toLowerCase() === 'modulelead') && (
        <div className="flex flex-col mb-7">
          <label htmlFor="profile_desc" className="text-base">
            Instructor Bio
          </label>
          <textarea
            rows={3}
            id="profile_desc"
            {...register('profile_desc', { required: true, minLength: 15 })}
            className={`border border-silver-500 rounded-md py-2 pl-3 text-xs focus:outline-gray-600 ${
              errors.profile_desc ? 'border-red-600 focus:outline-red-600' : ''
            }`}
            type="text"
            placeholder="Bio"
          />
          {errors.profile_desc && (
            <span className="text-red-600 text-xs mt-2">
              {errors.profile_desc?.message}
            </span>
          )}
        </div>
      )}
      {isSubmitting ? (
        <div className="grid place-items-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
            type="submit"
          >
            <SaveIcon fontSize="inherit" />
            <p>Create User</p>
          </button>
        </div>
      )}
    </form>
  );
}

export default CreateUser;
