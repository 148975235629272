import React from 'react';
import * as XLSX from 'xlsx';

export default function OnboardStudentsXLSXTemplate() {
  // Data for the template, separating mandatory and optional fields
  const headers = [
    { name: 'First Name', optional: false }, // Mandatory
    { name: 'Last Name', optional: false }, // Mandatory
    { name: 'Email', optional: false }, // Mandatory
    { name: 'Country of Residence', optional: false }, // Mandatory
    { name: 'Gender', optional: false }, // Mandatory
    { name: 'Phone Number', optional: true }, // Optional
    { name: 'Level of Education', optional: true }, // Optional
    { name: 'Age', optional: true }, // Optional
    { name: 'Middle Name', optional: true }, // Optional
  ];

  // Function to create and download the Excel file
  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();

    // Extract the header names for the first row
    const ws_data = [headers.map((header) => header.name)];

    // Create a worksheet with the data
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    // Apply styles to each header cell
    headers.forEach((header, idx) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: idx }); // r: row, c: column
      ws[cellRef].s = {
        fill: {
          fgColor: {
            rgb: header.optional ? '00999E' : '8B173B', // Color for optional (greenish) vs mandatory (red) columns
          },
        },
        font: {
          color: { rgb: 'FFFFFF' }, // White text
          bold: true,
        },
      };
    });

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Onboard Cohort Template');

    // Trigger download
    XLSX.writeFile(wb, 'onboard_cohort_template.xlsx');
  };

  return (
    <div className="flex justify-center">
      <button
        onClick={downloadExcel}
        className={`bg-persian-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
        type="submit"
      >
        Download Onboard Cohort Template (Excel)
      </button>
    </div>
  );
}
