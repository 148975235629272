import React from 'react';

function Course({ image }) {
  return (
    <div className=" rounded-md py-2 bg-white">
      <div className="flex items-center justify-center">
        <img src={image} alt="" className="h-32  object-fill" />
      </div>
    </div>
  );
}

export default Course;
