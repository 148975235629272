import React, { useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CancelIcon from '@mui/icons-material/Cancel';
import BookIcon from '@mui/icons-material/Book';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { instructionOptions } from '../utils/editorToolbar';
import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
import DisclosureWrapper from '../components/DisclosureWrapper';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import Modal from '../components/Modal';

const schema = yup
  .object({
    correctAnswer1: yup.string().nullable().required('Please select an option'),
    correctAnswer2: yup.string().nullable().required('Please select an option'),
    correctAnswer3: yup.string().nullable().required('Please select an option'),
    correctAnswer4: yup.string().nullable().required('Please select an option'),
  })
  .required();

function AddQuizQuestions() {
  const [question, setQuestion] = useState('');
  const [option1, setOption1] = useState('');
  const [option2, setOption2] = useState('');
  const [option3, setOption3] = useState('');
  const [option4, setOption4] = useState('');

  const { quizId, quizName } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [questionId, setQuestionId] = useState();

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { name: '' },
  });

  // wysiwyg
  const { quill, quillRef } = useQuill({
    modules: { toolbar: instructionOptions },
  });
  const { quill: option1Quill, quillRef: option1QuillRef } = useQuill({
    modules: { toolbar: instructionOptions },
  });
  const { quill: option2Quill, quillRef: option2QuillRef } = useQuill({
    modules: { toolbar: instructionOptions },
  });
  const { quill: option3Quill, quillRef: option3QuillRef } = useQuill({
    modules: { toolbar: instructionOptions },
  });
  const { quill: option4Quill, quillRef: option4QuillRef } = useQuill({
    modules: { toolbar: instructionOptions },
  });
  // wysiwyg

  const isQuestionEmpty = question === '<p><br></p>' || question === '';
  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setQuestion(quill.root.innerHTML);
      });
    }
  }, [quill, quillRef]);

  const isOption1Empty = option1 === '<p><br></p>' || option1 === '';
  useEffect(() => {
    if (option1Quill) {
      option1Quill.on('text-change', () => {
        setOption1(option1Quill.root.innerHTML);
      });
    }
  }, [option1Quill, option1QuillRef]);

  const isOption2Empty = option2 === '<p><br></p>' || option2 === '';
  useEffect(() => {
    if (option2Quill) {
      option2Quill.on('text-change', () => {
        setOption2(option2Quill.root.innerHTML);
      });
    }
  }, [option2Quill, option2QuillRef]);

  const isOption3Empty = option3 === '<p><br></p>' || option3 === '';
  useEffect(() => {
    if (option3Quill) {
      option3Quill.on('text-change', () => {
        setOption3(option3Quill.root.innerHTML);
      });
    }
  }, [option3Quill, option3QuillRef]);

  const isOption4Empty = option4 === '<p><br></p>' || option4 === '';
  useEffect(() => {
    if (option4Quill) {
      option4Quill.on('text-change', () => {
        setOption4(option4Quill.root.innerHTML);
      });
    }
  }, [option4Quill, option4QuillRef]);
  const clearEditors = () => {
    quill.setText('');
    option1Quill.setText('');
    option2Quill.setText('');
    option3Quill.setText('');
    option4Quill.setText('');
  };

  const disableButton =
    isQuestionEmpty ||
    isOption1Empty ||
    isOption2Empty ||
    isOption3Empty ||
    isOption4Empty;

  // mutation to create quiz
  const queryClient = useQueryClient();
  const onQuestionCreationSuccess = () => {
    toast.success('Question successfully added!');
    clearEditors();
    setIsSubmitting(false);
  };
  const onQuestionCreationFailure = () => {
    toast.error("Couldn't add question! Please try again");
    setIsSubmitting(false);
  };

  const fetchQuizQuestions = async () => {
    const response = await axiosInstancev2.get('/quizes/multiple-choice', {
      params: { quizId },
    });
    return response;
  };

  const onFetchQuizesFailure = () => {
    toast.error("Couldn't fetch quizes! Please try again");
  };

  const { data } = useQuery(['quiz-questions', quizId], fetchQuizQuestions, {
    staleTime: 3600 * 1000,
    cacheTime: 3600 * 1000,
    onError: onFetchQuizesFailure,
  });

  const mutation = useMutation(
    (requestData) => {
      return axiosInstancev2.post('/quizes/multiple-choice', requestData);
    },
    {
      onSuccess: () => {
        onQuestionCreationSuccess();
        queryClient.invalidateQueries(['quiz-questions', quizId]);
      },
      onError: onQuestionCreationFailure,
    }
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const toggleDeleteModal = () => setOpenDeleteModal(!openDeleteModal);

  const onVQuizDeletionSuccess = () => {
    toast.success('Question successfuly deleted');
    toggleDeleteModal();
    queryClient.invalidateQueries(['quiz-questions', quizId]);
  };

  const onVideoDeletionError = () => {
    toast.error("Couldn't delete question. Please try again");
  };

  const { mutate, isLoading: deletingQuestion } = useMutation(
    () => {
      return axiosInstancev2.delete(`/quizes/multiple-choice/${questionId}`);
    },
    {
      onSuccess: onVQuizDeletionSuccess,
      onError: onVideoDeletionError,
    }
  );

  const deleteQuestionHandler = () => {
    mutate(questionId);
  };

  const onSubmit = async (requestData) => {
    setIsSubmitting(true);
    const formattedData = {
      quizId,
      questions: [
        {
          question,
          choices: [
            {
              answerChoice: option1,
              isCorrect: requestData.correctAnswer1 === 'true',
            },
            {
              answerChoice: option2,
              isCorrect: requestData.correctAnswer2 === 'true',
            },
            {
              answerChoice: option3,
              isCorrect: requestData.correctAnswer3 === 'true',
            },
            {
              answerChoice: option4,
              isCorrect: requestData.correctAnswer4 === 'true',
            },
          ],
        },
      ],
    };
    mutation.mutate(formattedData);
  };

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex justify-between mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/courses"
            className="flex items-center"
          >
            <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Courses</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-3)}
            to={() => navigate(-3)}
            className="flex items-center"
          >
            <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Modules</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-2)}
            to={() => navigate(-2)}
            className="flex items-center"
          >
            <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Module Overview</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-1)}
            to={() => navigate(-1)}
            className="flex items-center"
          >
            <QuizIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Quizes</p>
          </NavLink>
        </Breadcrumbs>
      </div>
      {data?.data?.multipleChoiceQuestions && (
        <div className="">
          <DisclosureWrapper
            title={`Questions in ${quizName}`}
            data={data?.data?.multipleChoiceQuestions || []}
            toggleModal={toggleDeleteModal}
            setQuestionId={setQuestionId}
          />
        </div>
      )}
      <form
        className="text-gray-700 space-y-2 p-4 bg-white mt-4 rounded-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="lessonContent" className="text-lg font-semibold">
            Question
          </label>
          <div ref={quillRef} />
          {isQuestionEmpty && (
            <span className="text-red-600 text-xs mt-2">
              Please Enter Question
            </span>
          )}
        </div>
        <div className="">
          <div className="flex flex-col space-y-1 border-gray-400 border-[1px] my-2 p-2 rounded-md">
            <label htmlFor="lessonContent" className="text-base">
              Option 1
            </label>
            <div ref={option1QuillRef} />
            <div className="">
              <p>Is this option correct?</p>
              <input
                {...register('correctAnswer1', { required: true })}
                type="radio"
                id="true1"
                name="correctAnswer1"
                value="true"
              />{' '}
              <label htmlFor="true1">True</label>
              <br />
              <input
                {...register('correctAnswer1', { required: true })}
                type="radio"
                id="false1"
                name="correctAnswer1"
                value="false"
                defaultChecked
              />{' '}
              <label htmlFor="false1">False</label>
              <br />
            </div>
            {isOption1Empty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter Option 1 content
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 border-gray-400 border-[1px] my-2 p-2 rounded-md">
            <label htmlFor="lessonContent" className="text-base">
              Option 2
            </label>
            <div ref={option2QuillRef} />
            <div className="">
              <p>Is this option correct?</p>
              <input
                {...register('correctAnswer2', { required: true })}
                type="radio"
                id="true2"
                name="correctAnswer2"
                value="true"
              />{' '}
              <label htmlFor="true2">True</label>
              <br />
              <input
                {...register('correctAnswer2', { required: true })}
                type="radio"
                id="false2"
                name="correctAnswer2"
                value="false"
                defaultChecked
              />{' '}
              <label htmlFor="false2">False</label>
              <br />
            </div>
            {isOption2Empty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter Option 2 content
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 border-gray-400 border-[1px] my-2 p-2 rounded-md">
            <label htmlFor="lessonContent" className="text-base">
              Option 3
            </label>
            <div ref={option3QuillRef} />
            <div className="">
              <p>Is this option correct?</p>
              <input
                {...register('correctAnswer3', { required: true })}
                type="radio"
                id="true3"
                name="correctAnswer3"
                value="true"
              />{' '}
              <label htmlFor="true3">True</label>
              <br />
              <input
                {...register('correctAnswer3', { required: true })}
                type="radio"
                id="false3"
                name="correctAnswer3"
                value="false"
                defaultChecked
              />{' '}
              <label htmlFor="false3">False</label>
              <br />
            </div>
            {isOption3Empty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter Option 3 content
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 border-gray-400 border-[1px] my-2 p-2 rounded-md">
            <label htmlFor="lessonContent" className="text-base">
              Option 4
            </label>
            <div ref={option4QuillRef} />
            <div className="">
              <p>Is this option correct?</p>
              <input
                {...register('correctAnswer4', { required: true })}
                type="radio"
                id="true4"
                name="correctAnswer4"
                value="true"
              />{' '}
              <label htmlFor="true4">True</label>
              <br />
              <input
                {...register('correctAnswer4', { required: true })}
                type="radio"
                id="false4"
                name="correctAnswer4"
                value="false"
                defaultChecked
              />{' '}
              <label htmlFor="false4">False</label>
              <br />
            </div>
            {isOption4Empty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter Option 4 content
              </span>
            )}
          </div>
        </div>

        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-10">
            <div className="">
              <button
                className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
                type="button"
              >
                <CancelIcon fontSize="inherit" />
                <p>Cancel</p>
              </button>
            </div>
            <div className="">
              <button
                disabled={disableButton}
                className={`px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center ${
                  disableButton
                    ? 'bg-gray-400 text-black cursor-not-allowed'
                    : 'bg-claret-500 '
                }`}
                type="submit"
              >
                <SaveIcon fontSize="inherit" />
                <p>Create Question</p>
              </button>
            </div>
          </div>
        )}
      </form>
      <Modal title="Delete Question" modalOpen={openDeleteModal}>
        <p>Are you sure you want to delete this question?</p>

        {deletingQuestion ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-10">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleDeleteModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="button"
              onClick={deleteQuestionHandler}
            >
              <DeleteIcon fontSize="inherit" />
              <p>Delete Question</p>
            </button>
          </div>
        )}
      </Modal>
    </MainContent>
  );
}

export default AddQuizQuestions;
