import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import useFetchDataV2 from '../hooks/useFetchDatav2';
import GroupSubmissionReviewItem from './GroupSubmissionReviewItem';
import Spinner from './spinner/Spinner';

function P2PReviewsDetails({ submissionData, toggleModal }) {
  const { data: reviewsData, isLoading: isFetchingReviewsData } =
    useFetchDataV2(
      ['group-submission-reviews', submissionData?.id],
      `/group-submissions/review/${submissionData?.id}`,
      {},
      "Couldn't fetch group submission reviews. Please try again!",
      submissionData?.id
    );

  let content = null;
  if (isFetchingReviewsData) {
    content = <Spinner />;
  } else if (reviewsData?.data?.reviews?.length > 0) {
    content = (
      <div className="bg-white shadow-md py-2 mt-2 mb-4 rounded-md max-w-[90%] mx-auto max-h-60 overflow-y-auto relative">
        {reviewsData?.data?.reviews?.map((review) => (
          <div key={review.id} className="p-2">
            <GroupSubmissionReviewItem review={review} />
          </div>
        ))}
        <div className="absolute top-0 right-0 h-full bg-gray-300 w-1" />
      </div>
    );
  } else {
    content = <p className="text-center">No reviews yet</p>;
  }

  return (
    <div>
      {content}
      <div className="flex flex-row justify-between">
        <button
          type="button"
          className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
          onClick={toggleModal}
        >
          <CancelIcon fontSize="inherit" />
          Close
        </button>
      </div>
    </div>
  );
}

export default P2PReviewsDetails;
