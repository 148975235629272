import React, { useState } from 'react';
import Select from 'react-select';
import Spinner from './spinner/Spinner';
import TableWrapper from '../utils/TableWrapper';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import useFetchData from '../hooks/useFetchData';

export default function SponsoredCohortAssignmentRawData() {
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [cohortCourseId, setCohortCourseId] = useState('');
  const [cohortModules, setCohortModules] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [courseName, setCourseName] = useState('');
  const [moduleId, setModuleId] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [weekId, setWeekId] = useState('');
  const [weekName, setWeekName] = useState('');
  const [assignmentId, setAssignmentId] = useState('');
  const [assignmentTitle, setAssignmentTitle] = useState('');

  const columns = [
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row?.firstname}</p>,
    },
    {
      title: 'Middle Name',
      field: 'middlename',
      render: (row) => <p className="capitalize">{row?.middlename}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row?.lastname}</p>,
    },
    {
      title: 'Submission Url',
      field: 'submission_url',
      render: (row) => (
        <a
          href={row?.submission_url}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          {row?.submission_url}
        </a>
      ),
    },
    {
      title: 'Submitted On',
      field: 'submitted_at',
      render: (row) => (
        <p>
          {row?.submitted_at
            ? new Date(row?.submitted_at).toLocaleString(undefined, {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short',
              })
            : 'No Record'}
        </p>
      ),
    },
    { title: 'Country', field: 'country' },
    { title: 'Phone Number', field: 'phone_number' },
    { title: 'Gender', field: 'gender' },
    { title: 'Academic Level', field: 'level_of_education' },
  ];

  const { data: cohortData } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohortData
    ? cohortData?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: `${cohort.name} (${cohort.no_of_students || 0} Students)`,
        enrolled_course: cohort?.course_id ? cohort.course_id : null,
        enrolled_modules: cohort?.modules ? cohort?.modules : [],
      }))
    : [];

  // get courses

  const { data: courses } = useFetchData(
    ['courses', cohortCourseId],
    '/courses',
    { params: { courses_ids: JSON.stringify([cohortCourseId]) } },
    "Couldn't fetch courses. Please try again!",
    !!cohortId
  );

  const courseOptions = courses
    ? courses?.data?.courses?.map((course) => ({
        value: course.id,
        label: course.courseName,
      }))
    : [];

  // get modules
  const { data: courseModules } = useFetchData(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't fetch modules. Please try again!",
    courseId
  );

  const moduleOptions = courseModules
    ? courseModules?.data?.course_modules
        .filter((courseModule) => cohortModules.includes(courseModule.id))
        .map((courseModule) => ({
          value: courseModule.id,
          label: courseModule.moduleName,
        }))
    : [];

  // get module weeks
  const { data: moduleWeeks } = useFetchData(
    ['module-weeks', moduleId],
    `/module-weeks`,
    {
      params: { moduleId },
    },
    "Couldn't fetch weeks. Please try again!",
    moduleId
  );

  const weekOptions = moduleWeeks
    ? moduleWeeks?.data?.weeks?.map((week) => ({
        value: week.id,
        label: week.weekName,
      }))
    : [];

  // get week Assignments
  const { data: weekAssignmentData } = useFetchData(
    ['week-assignments', weekId],
    `/assignments/week/${weekId}`,
    {},
    "Couldn't fetch weeks. Please try again!",
    !!weekId
  );

  const weeklyAssignmentOptions = weekAssignmentData
    ? weekAssignmentData?.data?.assignments?.map((assignment) => ({
        value: assignment.id,
        label: assignment.title,
      }))
    : [];

  const { data: cohortAssignmentSubmissionsRawData, isLoading } =
    useFetchAnalytics(
      ['cohort-assignment-submissions-raw-data', cohortId, assignmentId],
      `/raw-data/assignment-submissions/${cohortId}/${assignmentId}`,
      {},
      "Couldn't get cohort assignment submissions raw data. Please try again",
      cohortId && assignmentId
    );

  const flattenData = (data) => {
    return data.map((row) => ({
      firstname: row.student?.firstname || 'N/A',
      middlename: row.student?.middlename || 'N/A',
      lastname: row.student?.lastname || 'N/A',
      email: row.student?.email || 'N/A',
      phone_number: row.student?.phone_number || 'N/A',
      gender: row.student?.gender || 'N/A',
      level_of_education: row.student?.level_of_education || 'N/A',
      country: row.student?.country || 'N/A',
      assignment_title: row.assignment_title || 'N/A',
      submission_url: row.submission_url || 'N/A',
      submitted_at: row.submitted_at || 'N/A',
    }));
  };

  return (
    <>
      <div className="bg-white px-2 py-5 rounded-lg">
        <p>
          <span className="font-medium">
            Cohort Weekly Assignment Raw Data:{' '}
          </span>
          <span className="underline underline-offset-1">
            Retrieve comprehensive raw data related to student submissions for a
            specific cohort and assignment. This feature allows you to access
            in-depth details for each student, including their individual
            profiles, submission timestamps.
          </span>
        </p>
      </div>
      <div className="grid grid-cols-4 bg-white p-3 gap-4">
        <Select
          //   {...field}
          isClearable
          placeholder="Select Cohort"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={cohortOptions}
          onChange={(event) => {
            setCohortCourseId(event?.enrolled_course);
            setCohortModules(event?.enrolled_modules);
            setCohortId(event?.value || null);
            setCohortName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Course"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={courseOptions}
          onChange={(event) => {
            setCourseId(event?.value || null);
            setCourseName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Module"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={moduleOptions}
          onChange={(event) => {
            setModuleId(event?.value || null);
            setModuleName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Week"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={weekOptions}
          onChange={(event) => {
            setWeekId(event?.value || null);
            setWeekName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Assignment"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={weeklyAssignmentOptions}
          onChange={(event) => {
            setAssignmentId(event?.value || null);
            setAssignmentTitle(event?.label || null);
          }}
        />
      </div>
      <div className="mt-4">
        {isLoading && cohortId && assignmentId ? (
          <div className="flex items-center justify-center bg-white">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title={`${cohortName}-${courseName}-${moduleName}-${weekName}-${assignmentTitle}-Students Submissions Data`}
            columns={columns}
            data={flattenData(cohortAssignmentSubmissionsRawData?.data || [])}
            options={{
              rowStyle: {
                overflowWrap: 'break-word',
              },
            }}
          />
        )}
      </div>
    </>
  );
}
