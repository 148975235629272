import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import CancelIcon from '@mui/icons-material/Cancel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useQuery } from '@tanstack/react-query';
import MainContent from '../components/MainContent';
import CreateEmail from '../components/CreateEmail';
import Modal from '../components/Modal';
import Spinner from '../components/spinner/Spinner';
import TableWrapper from '../utils/TableWrapper';
import useParsedData from '../hooks/useParsedData';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

function Email() {
  const [modalOpen, setModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [email, setEmail] = useState({ cohort: '', subject: '', message: '' });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleEmailModal = () => {
    setEmailModalOpen(!emailModalOpen);
  };

  const columns = [
    {
      title: 'Cohort Name',
      field: 'cohort_name', // Use flattened field names
      render: (row) => <p>{row.cohort_name}</p>,
    },
    {
      title: 'Sent Emails',
      field: 'sent_emails',
      render: (row) => <p>{row.sent_emails || 0}</p>,
    },
    {
      title: 'Pending Emails',
      field: 'pending_emails',
      render: (row) => <p>{row.pending_emails || 0}</p>,
    },
    {
      title: 'Failed Emails',
      field: 'failed_emails',
      render: (row) => <p>{row.failed_emails || 0}</p>,
    },
    {
      title: 'Sent By',
      field: 'administrator_email', // Use flattened field names
      render: (row) => <p>{row.administrator_email}</p>,
    },
    {
      title: 'Actions',
      render: (row) => (
        <button
          type="button"
          className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
          onClick={() => {
            setEmail({
              cohort: `${row.cohort_name}`,
              subject: `${row.subject}`,
              message: `${row.message}`,
            });
            toggleEmailModal();
          }}
        >
          View Email
        </button>
      ),
    },
  ];

  // Flatten function to transform nested data into flat data
  const flattenData = (data) => {
    return data.map((row) => ({
      cohort_name: row.cohort?.name || 'N/A',
      sent_emails: row.sent_emails || 0,
      pending_emails: row.pending_emails || 0,
      failed_emails: row.failed_emails || 0,
      administrator_email: row.administrator?.email || 'N/A',
      subject: row.subject,
      message: row.message,
    }));
  };

  const getEmails = async () => {
    const response = await axiosInstancev2.get('cohorts/emails');
    return response;
  };

  const { data, isLoading } = useQuery(['emails'], getEmails, {
    staleTime: 3600,
    cacheTime: 3600 * 10000,
  });

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          <button
            type="button"
            className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
            onClick={toggleModal}
          >
            Send Email To Cohort
          </button>
        </div>
        <div className="mt-5">
          {isLoading ? (
            <div className="w-full flex align-middle justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <TableWrapper
              title="List Of Emails Sent To Cohorts"
              columns={columns}
              // eslint-disable-next-line no-unsafe-optional-chaining
              data={flattenData(data?.data?.cohort_emails || [])}
            />
          )}{' '}
        </div>
      </MainContent>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Send Email To Cohort"
      >
        <CreateEmail toggleModal={toggleModal} />
      </Modal>
      <Modal
        modalOpen={emailModalOpen}
        toggleModal={toggleEmailModal}
        title={`Email Sent To ${email.cohort} Cohort`}
      >
        <div className="">
          <h2>
            <span className="font-semibold mr-2">Subject: </span>{' '}
            {email.subject}
          </h2>

          <div className="mt-5 space-y-4">
            <h2 className="font-semibold">Message</h2>
            {useParsedData(email.message)}
          </div>
        </div>
        <hr className=" mt-5  border-gray-900 " />
        <div className=" flex justify-between mt-4">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleEmailModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Close</p>
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Email;
