import { useState, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, IconButton } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';
import ReactTimeAgo from 'react-time-ago';
import useTruncatedElement from '../../hooks/useTruncatedElement';
import CommunityPostComment from './CommunityPostComment';
import useFetchCommunityData from '../../hooks/useFetchCommunityData';
import Spinner from '../spinner/Spinner';
import ButtonSpinner from '../ButtonSpinner';
import { communityInterceptor } from '../../utils/Axios/communityInterceptor';
import CommunityPostMedia from './CommunityPostMedia';
import Modal from '../Modal';
import DeleteCommunityPost from '../modals/DeleteCommunityPost';

const schema = yup
  .object({
    comment: yup.string().required('This is a required field'),
  })
  .required();

function CommunityPostItem({
  post: {
    message,
    created_by,
    number_of_replies,
    id,
    created_at,
    file_url,
    file_mime_type,
  },
}) {
  const [showComments, setShowComments] = useState(false);
  const [postId, setPostId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteCommunityPost, setDeleteCommunityPost] = useState(false);
  const [modifyPostId, setModifyPostId] = useState('');

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();

  const ref = useRef(null);
  const { isTruncated, isShowingMore, toggleIsShowingMore } =
    useTruncatedElement({
      ref,
    });

  const { data, isLoading } = useFetchCommunityData(
    ['post-comments', postId],
    '/posts/comments',
    {
      params: { post_id: postId },
    },
    "couldn't get comments, please try again",
    postId
  );
  const toggleShowComments = () => {
    setPostId(id);
    setShowComments(!showComments);
  };

  const toggleDeleteCommunityPostModal = (modifyId) => {
    setModifyPostId(modifyId);
    setDeleteCommunityPost(!deleteCommunityPost);
  };

  const commentText = watch('comment');

  const onSubmit = async (formInputs) => {
    setIsSubmitting(true);
    communityInterceptor
      .post('/posts/comments', { post_id: postId, ...formInputs })
      .then(() => {
        reset();
        toast.success('Comment added');
        queryClient.invalidateQueries(['post-comments', postId]);
      })
      .catch(() => {
        toast.error(`Couldn't add comment. Try again!`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="bg-white shadow-md py-2 mt-4 rounded-md max-w-xl mx-auto">
      <div className="flex justify-between items-center  px-4">
        <div className=" flex items-center mb-2 ">
          <Avatar
            src={created_by?.profile_image}
            sx={{ width: 50, height: 50 }}
          >
            {created_by?.name?.toUpperCase().split(' ')[0][0]}
            {created_by?.name?.toUpperCase().split(' ')[1][0]}
          </Avatar>
          <div className="text-xs  font-roboto px-2">
            <p className="capitalize font-bold">
              {created_by?.name || 'Anonymous'}
            </p>
            <p className="text-xs font-light">
              Posted{' '}
              <ReactTimeAgo
                className="text-xs font-light"
                date={new Date(created_at)}
              />
            </p>

            {/* <p>Student</p> */}
          </div>
        </div>
        <div>
          <IconButton onClick={() => toggleDeleteCommunityPostModal(id)}>
            <DeleteIcon className="text-sm text-black/80" fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div className="col-span-3 space-y-4">
        <div className="">
          <p
            ref={ref}
            id="post-content"
            style={{ width: '100%', lineHeight: '20px' }}
            className={`${
              isShowingMore ? '' : 'line-clamp-5'
            } duration-300 ease-in-out text-justify leading-normal  px-4`}
          >
            {message}
          </p>
        </div>
        {isTruncated && (
          <div
            className=" w-fit ml-auto cursor-pointer text-claret-500 flex justify-end text-xs  px-4"
            onClick={toggleIsShowingMore}
          >
            <p>{isShowingMore ? 'Read less' : 'Read more'}</p>
          </div>
        )}
        {file_url && (
          <CommunityPostMedia mimeType={file_mime_type} url={file_url} />
        )}
        <div className=" flex justify-between items-center  px-4">
          {/* {number_of_replies && ( */}
          <div
            className=" text-sm cursor-pointer flex space-x-1"
            onClick={toggleShowComments}
          >
            <CommentIcon className="text-sm text-black/80" fontSize="small" />
            <p>
              {/* {`${showComments ? 'Hide' : 'Show'}`}{' '} */}
              {`${number_of_replies === 1 ? 'comment' : 'comments'}`}
            </p>
          </div>
          {/* )} */}
          {number_of_replies && (
            <div className="text-xs cursor-pointer">
              <p>
                {number_of_replies}{' '}
                {`${number_of_replies === 1 ? 'comment' : 'comments'}`}
              </p>
            </div>
          )}
        </div>
      </div>
      {showComments && isLoading && (
        <div className="p-2">
          <Spinner />
        </div>
      )}
      {showComments && !isLoading && (
        <div className=" px-4">
          <div className="border-gray-200 bg-gray-50  border-[1px] overflow-hidden w-full rounded-lg my-4">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" flex justify-between gap-2 align-middle items-center"
            >
              <input
                {...register('comment')}
                className="flex-1 p-1 outline-none bg-gray-50 caret-claret-500 "
              />
              {isSubmitting ? (
                <button
                  type="button"
                  className="bg-white flex items-center gap-2 px-1 text-sm font-roboto font-normal rounded-lg text-claret-500"
                  disabled
                >
                  <ButtonSpinner />
                  Processing...
                </button>
              ) : (
                <button
                  disabled={
                    errors.comment || commentText?.trim() === '' || isSubmitting
                  }
                  className={` px-4 text-sm py-1 font-roboto font-normal rounded-lg ${
                    errors.comment || !commentText || commentText?.trim() === ''
                      ? 'text-claret-500/10'
                      : 'text-claret-500'
                  }`}
                  type="submit"
                >
                  comment
                </button>
              )}
            </form>
          </div>
          {data?.data?.post_comments?.map((comment) => (
            <div key={comment.id} className="p-2">
              <CommunityPostComment comment={comment} />
            </div>
          ))}
        </div>
      )}
      <Modal
        title="Delete Post"
        modalOpen={deleteCommunityPost}
        toggleModal={toggleDeleteCommunityPostModal}
      >
        <DeleteCommunityPost
          postId={modifyPostId}
          setModifyPostId={setModifyPostId}
          toggleModal={toggleDeleteCommunityPostModal}
        />
      </Modal>
    </div>
  );
}

export default CommunityPostItem;
