import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

function RemoveGroupMember({ memberDetails, toggleModal, groupId }) {
  const queryClient = useQueryClient();

  const onRemoveGroupMemberSuccess = () => {
    toast.success(
      `Successfully removed ${memberDetails?.student?.firstname} ${memberDetails?.student?.lastname} from ${memberDetails?.group?.name} `
    );
    toggleModal();
    queryClient.invalidateQueries(['group-members', groupId]);
  };

  const onRemoveGroupMemberFailure = () => {
    toast.error(
      `Couldn't remove ${memberDetails?.student?.firstname} ${memberDetails?.student?.lastname} from ${memberDetails?.group?.name} `
    );
  };
  const { mutate, isLoading } = useMutation(
    (data) => {
      return axiosInstancev2.delete(`group-members/${memberDetails.id}`, data);
    },
    {
      onSuccess: onRemoveGroupMemberSuccess,
      onError: onRemoveGroupMemberFailure,
    }
  );

  return (
    <div>
      <p className="text-lg">
        Are you sure you want to
        <span className="font-semibold">
          {` remove ${memberDetails?.student?.firstname} ${memberDetails?.student?.lastname} from ${memberDetails?.group?.name} `}
        </span>
        ?
      </p>
      {isLoading ? (
        <div className="w-full flex align-middle justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
            type="button"
            onClick={mutate}
          >
            <SaveIcon fontSize="inherit" />
            <p>Remove Member</p>
          </button>
        </div>
      )}
    </div>
  );
}

export default RemoveGroupMember;
