import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Course from '../components/Course';
import Spinner from '../components/spinner/Spinner';
import { fileToBase64 } from '../helpers/convertToBase64';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';
import usePermissions from '../hooks/usePermissions';
import Modal from '../components/Modal';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import useFetchDatav2 from '../hooks/useFetchDatav2';

const schema = yup
  .object({
    courseName: yup.string().required('This is a required field'),
    courseDescription: yup.string().required('This is a required field'),
    courseImage: yup
      .mixed()
      .required('Please select an image')
      .test('fileSize', 'Please upload image less than 2MB', (value) => {
        return value && value[0]?.size <= 2000000;
      }),
  })
  .required();

function Courses() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const permissions = usePermissions();
  const role = useGetUserRole();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  // course functions
  const queryClient = useQueryClient();

  const onCourseCreationSuccess = () => {
    toast.success('Module successfully created!');
    toggleModal();
  };
  const onCourseCreationFailure = () => {
    toast.error('Module creation failed! Please try again');
  };

  const { data, isLoading } = useFetchDatav2(
    ['courses'],
    '/courses',
    {},
    "Couldn't fetch courses. Please try again!",
    true
  );

  const mutation = useMutation(
    (requestData) => {
      return axiosInstancev2.post('/courses', requestData);
    },
    {
      onSuccess: () => {
        onCourseCreationSuccess();
        queryClient.invalidateQueries(['courses']);
      },
      onError: onCourseCreationFailure,
    }
  );

  const onSubmit = async (requestData) => {
    const base64Url = await fileToBase64(requestData.courseImage[0]);
    mutation.mutate({
      CourseName: requestData.courseName,
      CourseDescription: requestData.courseDescription,
      CourseImage: base64Url,
    });
  };
  return (
    <>
      <MainContent>
        <div className=" space-y-5">
          <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
            {/* <p>Home > Courses </p> */}
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
            >
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/"
                className="flex items-center"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Home</p>
              </NavLink>
            </Breadcrumbs>
            {(permissions?.includes('CreateCourse') ||
              role === 'SuperAdmin') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleModal}
              >
                Create Course
              </button>
            )}
          </div>
          {isLoading ? (
            <div className="grid items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="px-2 py-5 rounded-lg grid grid-cols-4 gap-4">
              {data?.data?.courses?.map((course) => (
                <div
                  key={course.id}
                  onClick={() => navigate(`/courses/${course.id}`)}
                  className="cursor-pointer hover:scale-110 ease-in-out duration-300 "
                >
                  <Course
                    name={course.courseName}
                    description={course.courseDescription}
                    image={course.courseImage}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <Modal
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          title="Create New Course"
        >
          <form
            className="text-gray-700 space-y-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col space-y-1">
              <label htmlFor="courseName" className="text-base">
                Course Name
              </label>
              <input
                {...register('courseName', { required: true })}
                type="text"
                id="courseName"
                placeholder="Enter Course Name"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.courseName ? 'border-red-600' : ''
                }`}
              />
              {errors.courseName && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.courseName?.message}
                </span>
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <label htmlFor="courseDescription" className="text-base">
                Course Description
              </label>
              <textarea
                {...register('courseDescription', {
                  required: true,
                })}
                rows={3}
                type="text"
                id="courseDescription"
                placeholder="Enter Course Description"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.courseDescription ? 'border-red-600' : ''
                }`}
              />
              {errors.courseDescription && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.courseDescription?.message}
                </span>
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <label htmlFor="courseImage" className="text-base">
                Course Image
              </label>
              <input
                {...register('courseImage', {
                  required: true,
                })}
                accept="image/gif, image/jpeg"
                rows={3}
                type="file"
                id="courseImage"
                placeholder="Upload Course Image"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.courseImage ? 'border-red-600' : ''
                }`}
              />
              {errors.courseImage && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.courseImage?.message}
                </span>
              )}
            </div>
            {mutation.isLoading ? (
              <div className="grid items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <div className=" flex justify-between mt-8">
                <button
                  className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
                  type="button"
                  onClick={toggleModal}
                >
                  <CancelIcon fontSize="inherit" />
                  <p>Cancel</p>
                </button>
                <button
                  className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
                  type="submit"
                >
                  <SaveIcon fontSize="inherit" />
                  <p>Create Course</p>
                </button>
              </div>
            )}
          </form>
        </Modal>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default Courses;
