import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Select from 'react-select';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import useFetchDatav2 from '../hooks/useFetchDatav2';

const schema = yup
  .object({
    name: yup.string().required('This is a required field'),
    support_channel: yup.string().url().required().label('Support Link'),
    course: yup
      .object()
      .shape({
        label: yup.string().required('course is required (from label)'),
        value: yup.string().required('course is required'),
      })
      .nullable()
      .required('Please select a course'),
    modules: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().required('module is required (from label)'),
          value: yup.string().required('module is required'),
        })
      )
      .min(1, 'Please select at least one module')
      .required('Please select modules'),

    start_date: yup.string().required('This is a required field'),
    end_date: yup.string().required('This is a required field'),
  })
  .required();

function CreateSponsorshipCohort({ toggleModal }) {
  const [courseId, setCourseId] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();
  const onCohortCreationSuccess = () => {
    toast.success('Cohort successfully created!');
    toggleModal();
    setIsSubmitting(false);
  };
  const onCohortCreationFailure = () => {
    toast.error('Cohort creation failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/cohorts', data);
    },
    {
      onSuccess: () => {
        onCohortCreationSuccess();
        queryClient.invalidateQueries(['cohorts']);
      },
      onError: onCohortCreationFailure,
    }
  );

  // get courses
  const { data: courses } = useFetchDatav2(
    ['courses'],
    '/courses',
    {},
    "Couldn't fetch courses. Please try again!",
    true
  );

  const courseOptions = courses?.data?.courses.map((course) => ({
    value: course.id,
    label: course.courseName,
  }));

  // get modules
  const is_paid_module = false;

  const { data: courseModules } = useFetchDatav2(
    ['course-modules', courseId, is_paid_module],
    `/course-modules`,
    {
      params: { courseId, is_paid_module },
    },
    "Couldn't fetch modules. Please try again!",
    !!courseId
  );

  const moduleOptions = courseModules
    ? courseModules?.data?.course_modules?.map((courseModule) => ({
        value: courseModule.id,
        label: courseModule.moduleName,
      }))
    : [];

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const requestData = {
      ...data,
      start_date: new Date(data.start_date).toISOString(),
      end_date: new Date(data.end_date).toISOString(),
      course_id: data.course.value,
      modules: data.modules.map((module) => module.value),
    };
    mutation.mutate(requestData);
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="name" className="text-base">
              Cohort Name
            </label>
            <input
              {...register('name', { required: true })}
              type="text"
              id="name"
              placeholder="Cohort Name"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.name ? 'border-red-600' : ''
              }`}
            />
            {errors.name && (
              <span className="text-red-600 text-xs mt-2">
                {errors.name?.message}
              </span>
            )}
          </div>

          <div className="flex flex-col space-y-1">
            <label htmlFor="course" className="text-base">
              Select Course
            </label>
            <Controller
              name="course"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={courseOptions}
                  onChange={(event) => {
                    setCourseId(event?.value || null);
                    field.onChange(event);
                  }}
                />
              )}
            />
            {errors.course && (
              <span className="text-red-600 text-xs mt-2">
                {errors.course?.message}
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="modules" className="text-base">
              Select Modules
            </label>
            <Controller
              name="modules"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  isMulti
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={moduleOptions}
                />
              )}
            />
            {errors.modules && (
              <span className="text-red-600 text-xs mt-2">
                {errors.modules?.message}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="start_date" className="text-base">
              Start Date
            </label>
            <input
              {...register('start_date', { required: true })}
              min={new Date().toISOString().split('T')[0]}
              type="date"
              id="start_date"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.start_date ? 'border-red-600' : ''
              }`}
            />
            {errors.start_date && (
              <span className="text-red-600 text-xs mt-2">
                {errors.start_date?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="end_date" className="text-base">
              End Date
            </label>
            <input
              {...register('end_date', { required: true })}
              type="date"
              min={new Date().toISOString().split('T')[0]}
              id="end_date"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.end_date ? 'border-red-600' : ''
              }`}
            />
            {errors.end_date && (
              <span className="text-red-600 text-xs mt-2">
                {errors.end_date?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col col-span-2 mb-5">
            <label
              htmlFor="support_channel"
              className=" text-mine-shaft-500 mb-1 pl-1"
            >
              Support Link
            </label>
            <input
              id="support_channel"
              {...register('support_channel')}
              className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
                errors.support_channel
                  ? 'border-red-600 focus:outline-red-600'
                  : ''
              }`}
              type="text"
              placeholder="Channel Link"
            />
            {errors.support_channel && (
              <span className="text-red-600  mt-1">
                {errors.support_channel?.message}
              </span>
            )}
          </div>
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Sponsorship Cohort</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateSponsorshipCohort;
