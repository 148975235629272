import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TableWrapper from '../utils/TableWrapper';
import Spinner from './spinner/Spinner';
import useFetchData from '../hooks/useFetchData';

function GroupAssignments() {
  const { groupId, groupName } = useParams();
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Topic',
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      field: 'title',
      render: (row) => <p className="line-clamp-1">{row.title}</p>,
    },
    {
      title: 'Due Date',
      field: 'deadline',
      render: (row) => <p>{new Date(row.deadline).toLocaleDateString()}</p>,
    },
    {
      title: 'Actions',
      render: (row) => (
        <div className="space-x-4">
          <button
            onClick={() => navigate(`/group/${groupId}/assignment/${row.id}`)}
            type="button"
            className="p-1 px-2 bg-[#00999E] text-white rounded-md"
          >
            View Assignment
          </button>
        </div>
      ),
    },
  ];

  const { data, isLoading } = useFetchData(
    ['group-assignments', groupId],
    `/group-assignments/${groupId}`,
    {},
    "Couldn't get group assignments. Please try again!",
    true
  );

  return (
    <div className="mt-5">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <TableWrapper
          title={`${groupName}-Assignments`}
          data={data ? data?.data?.group_assignment?.assignments : []}
          columns={columns}
        />
      )}
    </div>
  );
}

export default GroupAssignments;
