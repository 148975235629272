import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuill } from 'react-quilljs';
import Spinner from './spinner/Spinner';
import { fileToBase64 } from '../helpers/convertToBase64';
import { toolbarOptions } from '../utils/editorToolbar';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    moduleName: yup.string().required('This is a required field'),
    moduleShortDescription: yup.string().required('This is a required field'),
    intro_video_url: yup.string().required('This is a required field'),
    skillLevel: yup.string().required('This is a required field'),
    category: yup.string().required('This is a required field'),
    headerImage: yup
      .mixed()
      .required('Please select an image')
      .test('fileSize', 'Please upload image less than 2MB', (value) => {
        return value && value[0].size <= 2000000;
      }),
  })
  .required();

function CreateCourseOverview({ toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { courseId } = useParams();
  const { quill, quillRef } = useQuill({
    modules: { toolbar: toolbarOptions },
  });
  const { quill: requirementsQuill, quillRef: requirementsQuillRef } = useQuill(
    {
      modules: { toolbar: toolbarOptions },
    }
  );
  const { quill: whatToLearnQuill, quillRef: whatToLearnQuillRef } = useQuill({
    modules: { toolbar: toolbarOptions },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();
  const onModuleCreationSuccess = () => {
    toast.success('Course successfully created!');
    toggleModal();
    setIsSubmitting(false);
  };
  const onModuleCreationFailure = () => {
    toast.error('Course creation failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/course-modules', data);
    },
    {
      onSuccess: () => {
        onModuleCreationSuccess();
        queryClient.invalidateQueries(['course-modules', courseId]);
      },
      onError: onModuleCreationFailure,
    }
  );

  // wysiwyg
  // check if is empty
  const [moduleDescription, setModuleDescription] = useState('');
  const [requirements, setRequirements] = useState('');
  const [whatToLearn, setWhatToLearn] = useState('');

  const isEmpty =
    moduleDescription === '<p><br></p>' || moduleDescription === '';

  const isRequirementEmpty =
    requirements === '<p><br></p>' || requirements === '';

  const isWhatToLearnEmpty =
    whatToLearn === '<p><br></p>' || whatToLearn === '';

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setModuleDescription(quill.root.innerHTML);
      });
    }
  }, [quill, quillRef]);

  useEffect(() => {
    if (requirementsQuill) {
      requirementsQuill.on('text-change', () => {
        setRequirements(requirementsQuill.root.innerHTML);
      });
    }
  }, [requirementsQuill, requirementsQuillRef]);

  useEffect(() => {
    if (whatToLearnQuill) {
      whatToLearnQuill.on('text-change', () => {
        setWhatToLearn(whatToLearnQuill.root.innerHTML);
      });
    }
  }, [whatToLearnQuill, whatToLearnQuillRef]);

  // wysiwyg

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const base64Url = await fileToBase64(data.headerImage[0]);
    const requestBody = {
      ...data,
      moduleImage: base64Url,
      courseId,
      moduleDescription: quill.root.innerHTML,
      requirements: requirementsQuill.root.innerHTML,
      what_to_be_learnt: whatToLearnQuill.root.innerHTML,
      technologies_to_be_mastered: ['python', 'cloud computing'],
    };

    mutation.mutate(requestBody);
  };
  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="moduleName" className="text-base">
            Title
          </label>
          <input
            {...register('moduleName', { required: true })}
            type="text"
            id="moduleName"
            placeholder="Module Name"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.moduleName ? 'border-red-600' : ''
            }`}
          />
          {errors.moduleName && (
            <span className="text-red-600 text-xs mt-2">
              {errors.moduleName?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="category" className="text-base">
            Module Category
          </label>
          <select
            {...register('category', { required: true })}
            required
            name="category"
            id="category"
            className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
          >
            <option value="">Select Category</option>
            <option value="Front-End">Front-End</option>
            <option value="Back-End">Back-End</option>
            <option value="Data Analytics">Data Analytics</option>
            <option value="Artificial Intelligence">
              Artificial Intelligence
            </option>
            <option value="Cyber Security">Cyber Security</option>
            <option value="Entrepreneurship">Entrepreneurship</option>
          </select>
          {errors.category && (
            <span className="text-red-600 text-xs mt-2">
              {errors.category?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="moduleShortDescription" className="text-base">
            Short Description
          </label>
          <textarea
            {...register('moduleShortDescription', { required: true })}
            type="text"
            id="moduleShortDescription"
            placeholder="Module Description"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.moduleName ? 'border-red-600' : ''
            }`}
            rows={2}
          />
          {errors.moduleShortDescription && (
            <span className="text-red-600 text-xs mt-2">
              {errors.moduleShortDescription?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="intro_video_url" className="text-base">
            Video Url
          </label>
          <input
            {...register('intro_video_url', { required: true })}
            type="text"
            id="intro_video_url"
            placeholder="Video Url"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.intro_video_url ? 'border-red-600' : ''
            }`}
          />
          {errors.intro_video_url && (
            <span className="text-red-600 text-xs mt-2">
              {errors.intro_video_url?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="headerImage" className="text-base">
            Header Image
          </label>
          <input
            {...register('headerImage', {
              required: true,
            })}
            accept="image/gif, image/jpeg"
            type="file"
            id="headerImage"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.headerImage ? 'border-red-600' : ''
            }`}
          />
          {errors.headerImage && (
            <span className="text-red-600 text-xs mt-2">
              {errors.headerImage?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="skillLevel" className="text-base">
            Skill Level
          </label>
          <select
            {...register('skillLevel', { required: true })}
            required
            name="skillLevel"
            id="skillLevel"
            className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
          >
            <option value="">Select Option</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>
          {errors.skillLevel && (
            <span className="text-red-600 text-xs mt-2">
              {errors.skillLevel?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1 max-w-full">
          <label htmlFor="moduleDescription" className="text-base">
            Module Description
          </label>
          <div ref={quillRef} />
          {isEmpty && (
            <span className="text-red-600 text-xs mt-2">
              Please Enter Module Description
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1 max-w-full">
          <label htmlFor="requirements" className="text-base">
            Module Requirements
          </label>
          <div ref={requirementsQuillRef} />
          {isRequirementEmpty && (
            <span className="text-red-600 text-xs mt-2">
              Please Enter module Requirements
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1 max-w-full">
          <label htmlFor="whatToLearn" className="text-base">
            What You Will Learn
          </label>
          <div ref={whatToLearnQuillRef} />
          {isWhatToLearnEmpty && (
            <span className="text-red-600 text-xs mt-2">
              Please Enter What To Learn
            </span>
          )}
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Course Module</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateCourseOverview;
