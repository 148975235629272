import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TranslateIcon from '@mui/icons-material/Translate';
import TuneIcon from '@mui/icons-material/Tune';
import VerifiedIcon from '@mui/icons-material/Verified';

function Stats({ weeks }) {
  return (
    <div className="bg-white flex-2 flex flex-col  rounded-md p-2  items-center">
      <div className="bg-[#F2F2F2] w-full">
        <div className=" bg-statsbg bg-no-repeat bg-cover bg-center h-52 mb-5 " />
        <hr className="my-2" />
        <div className="flex justify-between px-2 text-sm  divide-y ">
          <div className="flex items-center space-x-1 text-[#00999E]">
            <AccessTimeIcon fontSize="inherit" color="inherit" />{' '}
            <p className="text-black">Weeks</p>
          </div>
          <p className="text-sm">{weeks?.length}</p>
        </div>
        <hr className="my-2" />
        <hr className="my-2" />
        <div className="flex justify-between px-2 text-sm  divide-y ">
          <div className="flex items-center space-x-1 text-[#00999E]">
            <TranslateIcon fontSize="inherit" color="inherit" />{' '}
            <p className="text-black">Language</p>
          </div>
          <p className="text-sm">English</p>
        </div>
        <hr className="my-2" />
        <div className="flex justify-between px-2 text-sm  divide-y ">
          <div className="flex items-center space-x-1 text-[#00999E]">
            <TuneIcon fontSize="inherit" color="inherit" />{' '}
            <p className="text-black">Skill Level</p>
          </div>
          <p className="text-sm">Beginner</p>
        </div>
        <hr className="my-2" />
        <div className="flex justify-between px-2 text-sm  divide-y ">
          <div className="flex items-center space-x-1 text-[#00999E]">
            <VerifiedIcon fontSize="inherit" color="inherit" />{' '}
            <p className="text-black">Certificate</p>
          </div>
          <p className="text-sm">Yes</p>
        </div>
        <hr className="my-2" />
      </div>
    </div>
  );
}

export default Stats;
