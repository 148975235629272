import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import useFetchDatav2 from '../hooks/useFetchDatav2';

const schema = yup
  .object({
    name: yup.string().required('This is a required field'),
    start_date: yup.string().required('This is a required field'),
    end_date: yup
      .string()
      .required('This is a required field')
      .test(
        'is-after-start-date',
        'End date must be later than start date',
        function isAfterStartDate(value) {
          const { start_date } = this.parent;
          return new Date(value) > new Date(start_date);
        }
      ),
    certification_date: yup
      .string()
      .test(
        'is-after-end-date',
        'Certification date must be later than end date',
        function isAfterEndDate(value) {
          const { end_date } = this.parent;
          // Only run the test if a value is provided for certification_date
          if (!value) return true;
          return new Date(value) > new Date(end_date);
        }
      ),
  })
  .required();

function EditPaidCohort({ paidCohortId, toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const queryClient = useQueryClient();

  const { data: cohortData, isLoading: isFetchingCohortDetails } =
    useFetchDatav2(
      ['paid-cohorts', paidCohortId],
      `/paid-cohorts/${paidCohortId}`,
      {},
      "Couldn't fetch paid cohort details. Please try again!",
      true
    );

  const onCohortUpdateSuccess = () => {
    toast.success('Paid Cohort successfully updated!');
    toggleModal();
    setIsSubmitting(false);
  };

  const onCohortUpdateFailure = () => {
    toast.error('Paid Cohort update failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => axiosInstancev2.put(`/paid-cohorts/${paidCohortId}`, data),
    {
      onSuccess: () => {
        onCohortUpdateSuccess();
        queryClient.invalidateQueries(['paid-cohorts']);
        queryClient.invalidateQueries(['paid-cohorts', paidCohortId]);
      },
      onError: onCohortUpdateFailure,
    }
  );

  useEffect(() => {
    if (cohortData?.data?.paid_cohort) {
      setValue('name', cohortData?.data?.paid_cohort?.name);
      setValue(
        'start_date',
        cohortData?.data?.paid_cohort?.start_date?.split('T')[0]
      );
      setValue(
        'end_date',
        cohortData?.data?.paid_cohort?.end_date?.split('T')[0]
      );
      setValue(
        'certification_date',
        cohortData?.data?.paid_cohort?.certification_date?.split('T')[0]
      );
    }
  }, [cohortData, setValue]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const requestData = {
      ...data,
      start_date: new Date(data.start_date).toISOString(),
      end_date: new Date(data.end_date).toISOString(),
      certification_date: data.certification_date
        ? new Date(data.certification_date).toISOString()
        : null,
    };
    mutation.mutate(requestData);
  };

  return (
    <div>
      {isFetchingCohortDetails ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <form
          className="text-gray-700 space-y-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col space-y-1">
              <label htmlFor="name" className="text-base">
                Cohort Name
              </label>
              <input
                {...register('name', { required: true })}
                type="text"
                id="name"
                placeholder="Cohort Name"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.name ? 'border-red-600' : ''
                }`}
              />
              {errors.name && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.name?.message}
                </span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col space-y-1">
              <label htmlFor="start_date" className="text-base">
                Start Date
              </label>
              <input
                {...register('start_date', { required: true })}
                type="date"
                id="start_date"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.start_date ? 'border-red-600' : ''
                }`}
              />
              {errors.start_date && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.start_date?.message}
                </span>
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <label htmlFor="end_date" className="text-base">
                End Date
              </label>
              <input
                {...register('end_date', { required: true })}
                type="date"
                id="end_date"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.end_date ? 'border-red-600' : ''
                }`}
              />
              {errors.end_date && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.end_date?.message}
                </span>
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <label htmlFor="certification_date" className="text-base">
                Certification Date
              </label>
              <input
                {...register('certification_date', { required: false })}
                type="date"
                id="certification_date"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.certification_date ? 'border-red-600' : ''
                }`}
              />
              {errors.certification_date && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.certification_date?.message}
                </span>
              )}
            </div>
          </div>
          {isSubmitting ? (
            <div className="w-full flex align-middle justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <div className="flex justify-between mt-8">
              <button
                className="border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center"
                type="button"
                onClick={toggleModal}
              >
                <CancelIcon fontSize="inherit" />
                <p>Cancel</p>
              </button>
              <button
                className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
                type="submit"
              >
                <SaveIcon fontSize="inherit" />
                <p>Update Paid Cohort</p>
              </button>
            </div>
          )}
        </form>
      )}
    </div>
  );
}

export default EditPaidCohort;
