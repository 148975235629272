import React from 'react';
import { NavLink } from 'react-router-dom';
import useSidebarData from '../hooks/useSidebarData';
// import { SidebarData } from '../utils/sideBarData';

function LeftBar() {
  const sidebarData = useSidebarData();

  const activeLink =
    'flex flex-row items-center p-2 mb-1.5 bg-[#8B173B] text-white hover:text-white focus:bg-[#8B173B] focus:text-white rounded-md cursor-pointer space-x-3';
  const inactiveLink =
    'flex flex-row items-center p-2 mb-1.5  rounded-md cursor-pointer space-x-3 hover:bg-lms-custom-50';

  return (
    <div className=" h-screen top-32 fixed w-1/5 pl-6">
      {/* <div className="bg-white fixed w-[15%] left-[3.125%] h-screen "> */}
      <ul className="p-2 space-y-5 bg-white h-full rounded-lg overflow-auto pb-52 ">
        {sidebarData.map((val) => {
          return (
            <NavLink
              to={val.link}
              className={({ isActive }) =>
                isActive ? activeLink : inactiveLink
              }
              key={val.id}
              exact="false"
              strict="false"
            >
              <div className="mr-1">{val.icon}</div>

              <div>
                <small>{val.title}</small>
              </div>
            </NavLink>
          );
        })}
      </ul>
    </div>
  );
}

export default LeftBar;
