import React, { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import GridViewIcon from '@mui/icons-material/GridView';
import TableWrapper from '../utils/TableWrapper';
import Spinner from '../components/spinner/Spinner';
import MainContent from '../components/MainContent';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import EditPaidCohort from '../components/EditPaidCohort';
import useGetUserRole from '../hooks/useGetUserRole';
import Modal from '../components/Modal';
import EnrolStudentsToAdvancedModules from '../components/EnrolAdvancedStudents';
import ViewStudentPaidModules from '../components/ViewStudentPaidModules';

function PaidCohortDetail() {
  const navigate = useNavigate();

  const { paidCohortName, paidCohortId } = useParams();
  const [editPaidCohortModalOpen, setEditPaidCohortModalOpen] = useState(false);
  const [enrolAdvancedStudentsModalOpen, setEnrolAdvancedStudentsModalOpen] =
    useState(false);
  const [viewStudentPaidModulesModalOpen, setViewStudentPaidModulesModalOpen] =
    useState(false);
  const [studentId, setStudentId] = useState(null);
  const [studentEmail, setStudentEmail] = useState(null);

  const toggleViewStudentPaidModulesModal = () => {
    setViewStudentPaidModulesModalOpen((prev) => !prev);
  };

  const role = useGetUserRole();

  const toggleEditPaidCohortModal = () => {
    setEditPaidCohortModalOpen((prevState) => !prevState);
  };

  const toggleEnrolAdvancedStudentsModal = () => {
    setEnrolAdvancedStudentsModalOpen((prevState) => !prevState);
  };

  const columns = [
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row.firstname}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row.lastname}</p>,
    },

    { title: 'Email', field: 'email' },
    { title: 'Country', field: 'country' },
    {
      title: 'Decentralized ID',
      field: 'decentralized_id',

      render: (row) => (
        <p className="max-w-xs line-clamp-2">
          {row.decentralized_id ? row.decentralized_id : 'Not generated'}
        </p>
      ),
    },
    { title: 'Academic Level', field: 'level_of_education' },
    {
      title: 'Last Login',
      field: 'last_login',
      render: (row) => (
        <p className="">
          {row.last_login
            ? new Date(row.last_login).toLocaleString(undefined, {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : 'No Record'}
        </p>
      ),
    },
    {
      title: 'Modules',
      render: (row) => (
        <button
          className={`bg-persian-500 px-4 py-1 rounded-md text-white text-sm cursor-pointer hover:bg-persian-700 space-x-2 flex items-center `}
          type="button"
          onClick={() => {
            setStudentId(row?.id);
            setStudentEmail(row?.email);
            toggleViewStudentPaidModulesModal();
          }}
        >
          <p>View Modules</p>
        </button>
      ),
    },
    {
      title: 'Actions',
      render: (row) => (
        <button
          className={`bg-persian-500 px-4 py-1 rounded-md text-white text-sm cursor-pointer hover:bg-persian-700 space-x-2 flex items-center `}
          type="button"
          onClick={() => navigate(`/users/student/${row.id}`)}
        >
          <p>More Details</p>
        </button>
      ),
    },
  ];

  // TODO: configure pagination with the table
  const { data, isLoading } = useFetchDatav2(
    ['students', paidCohortId],
    '/users/student/paid-cohort',
    {
      params: { paid_cohort_id: paidCohortId },
    },
    "Couldn't get details. Please try again",
    true
  );

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/paid-cohorts"
              className="flex items-center"
            >
              <GridViewIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Paid Cohorts</p>
            </NavLink>
          </Breadcrumbs>
          <div className="space-x-4 flex items-center">
            {role === 'SuperAdmin' && (
              <>
                <button
                  type="button"
                  className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                  onClick={toggleEnrolAdvancedStudentsModal}
                >
                  Enrol Students
                </button>
                <button
                  type="button"
                  className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                  onClick={toggleEditPaidCohortModal}
                >
                  Edit Paid Cohort
                </button>
              </>
            )}
          </div>
        </div>
        <div className="mt-4">
          {isLoading ? (
            <div className="flex items-center justify-center bg-white">
              <Spinner />
            </div>
          ) : (
            <TableWrapper
              title={`List of Students in Paid Cohort - ${paidCohortName}`}
              columns={columns}
              data={data ? data?.data?.students : []}
              // onRowClick={(event, rowData) => navigate(`/cohort/${rowData.id}`)}
              options={{
                rowStyle: {
                  overflowWrap: 'break-word',
                },
              }}
            />
          )}
        </div>
      </MainContent>
      <Modal
        modalOpen={enrolAdvancedStudentsModalOpen}
        toggleModal={toggleEnrolAdvancedStudentsModal}
        title="Enrol Students"
      >
        <EnrolStudentsToAdvancedModules
          paidCohortId={paidCohortId}
          toggleModal={toggleEnrolAdvancedStudentsModal}
        />
      </Modal>
      <Modal
        modalOpen={editPaidCohortModalOpen}
        toggleModal={toggleEditPaidCohortModal}
        title="Edit Paid Cohort"
      >
        <EditPaidCohort
          paidCohortId={paidCohortId}
          toggleModal={toggleEditPaidCohortModal}
        />
      </Modal>
      <Modal
        modalOpen={viewStudentPaidModulesModalOpen}
        toggleModal={toggleViewStudentPaidModulesModal}
        title={`${studentEmail} Paid Modules For This Cohort`}
      >
        <ViewStudentPaidModules
          studentId={studentId}
          paidCohortId={paidCohortId}
          toggleModal={toggleViewStudentPaidModulesModal}
        />
      </Modal>
    </>
  );
}

export default PaidCohortDetail;
