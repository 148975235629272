import React from 'react';

function MainContent({ full, children }) {
  return (
    <div
      className={`absolute px-4 top-32 pr-6  left-[20%] ${
        full ? 'w-4/5' : 'w-3/5'
      }`}
    >
      {children}
    </div>
  );
}

export default MainContent;
