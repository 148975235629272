import React, { useState } from 'react';
import Select from 'react-select';
import useFetchData from '../hooks/useFetchData';
import Spinner from './spinner/Spinner';
import TableWrapper from '../utils/TableWrapper';
import useFetchAnalytics from '../hooks/useFetchAnalytics';

export default function SponsoredCohortSurveyRawData() {
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [cohortCourseId, setCohortCourseId] = useState('');
  const [cohortModules, setCohortModules] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [courseName, setCourseName] = useState('');
  const [moduleId, setModuleId] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [weekId, setWeekId] = useState('');
  const [weekName, setWeekName] = useState('');
  const [surveyId, setSurveyId] = useState('');
  const [surveyName, setSurveyName] = useState('');

  const columns = [
    { title: 'Email', field: 'email' },
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row?.firstname}</p>,
    },
    {
      title: 'Middle Name',
      field: 'middlename',
      render: (row) => <p className="capitalize">{row?.middlename}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row?.lastname}</p>,
    },
    { title: 'Country', field: 'country' },
    { title: 'Phone Number', field: 'phone_number' },
    { title: 'Gender', field: 'gender' },
    { title: 'Academic Level', field: 'level_of_education' },
  ];

  // get cohorts
  const { data: cohorts } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohorts
    ? cohorts?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: cohort.name,
        enrolled_course: cohort?.course_id ? cohort.course_id : null,
        enrolled_modules: cohort?.modules ? cohort?.modules : [],
      }))
    : [];

  // get courses
  const { data: courses } = useFetchData(
    ['courses', cohortCourseId],
    '/courses',
    { params: { courses_ids: JSON.stringify([cohortCourseId]) } },
    "Couldn't fetch courses. Please try again!",
    !!cohortId
  );

  const courseOptions = courses
    ? courses?.data?.courses?.map((course) => ({
        value: course.id,
        label: course.courseName,
      }))
    : [];

  // get modules
  const { data: courseModules } = useFetchData(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't fetch modules. Please try again!",
    courseId
  );

  const moduleOptions = courseModules
    ? courseModules?.data?.course_modules
        .filter((courseModule) => cohortModules.includes(courseModule.id))
        .map((courseModule) => ({
          value: courseModule.id,
          label: courseModule.moduleName,
        }))
    : [];

  // get module weeks

  const { data: moduleWeeks } = useFetchData(
    ['module-weeks', moduleId],
    `/module-weeks`,
    {
      params: { moduleId },
    },
    "Couldn't fetch weeks. Please try again!",
    moduleId
  );
  const weekOptions = moduleWeeks
    ? moduleWeeks?.data?.weeks?.map((week) => ({
        value: week.id,
        label: week.weekName,
      }))
    : [];

  // get surveys
  const { data: surveysData } = useFetchData(
    ['week-surveys', weekId],
    '/surveys',
    {
      params: { week_id: weekId },
    },
    "Couldn't get week surveys. Please try again!",
    weekId
  );

  const surveyOptions = surveysData
    ? surveysData?.data?.surveys?.map((survey) => ({
        value: survey.id,
        label: survey.name,
      }))
    : [];

  const { data: cohortSurveyRawData, isLoading } = useFetchAnalytics(
    ['cohort-survey-submissions-raw-data', cohortId, surveyId],
    `/raw-data/survey-submissions/${cohortId}/${surveyId}`,
    {},
    "Couldn't get cohort survey raw data. Please try again",
    cohortId && surveyId
  );

  return (
    <>
      <div className="bg-white px-2 py-5 rounded-lg">
        <p>
          <span className="font-medium">Cohort Survey Raw Data:</span>
          <span className="underline underline-offset-1">
            List of Cohort learners who have submitted the specified survey.
          </span>
        </p>
      </div>
      <div className="grid grid-cols-4 bg-white p-3 gap-4">
        <Select
          //   {...field}
          isClearable
          placeholder="Select Cohort"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={cohortOptions}
          onChange={(event) => {
            setCohortCourseId(event?.enrolled_course);
            setCohortModules(event?.enrolled_modules);
            setCohortId(event?.value || null);
            setCohortName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Course"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={courseOptions}
          onChange={(event) => {
            setCourseId(event?.value || null);
            setCourseName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Module"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={moduleOptions}
          onChange={(event) => {
            setModuleId(event?.value || null);
            setModuleName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Week"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={weekOptions}
          onChange={(event) => {
            setWeekId(event?.value || null);
            setWeekName(event?.label || null);
          }}
        />
        <Select
          //   {...field}
          isClearable
          placeholder="Select Survey"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={surveyOptions}
          onChange={(event) => {
            setSurveyId(event?.value || null);
            setSurveyName(event?.label || null);
          }}
        />
      </div>
      <div className="mt-4">
        {isLoading && surveyId ? (
          <div className="flex items-center justify-center bg-white">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title={`${cohortName}-${courseName}-${moduleName}-${weekName}-${surveyName}-Students Data`}
            columns={columns}
            data={cohortSurveyRawData?.data || []}
            options={{
              rowStyle: {
                overflowWrap: 'break-word',
              },
            }}
          />
        )}
      </div>
    </>
  );
}
