/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { useQuery } from '@tanstack/react-query';
import { Box, Tabs, Tab, ListItemIcon, ListItem } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import SchoolIcon from '@mui/icons-material/School';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PaymentIcon from '@mui/icons-material/Payment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TableWrapper from '../utils/TableWrapper';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
// import useParsedData from '../hooks/useParsedData';
// import useParsedData from '../hooks/useParsedData';

function Tickets() {
  const [selectedTab, setSelectedTab] = useState(0);

  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchTickets = async (type) => {
    const res = await axiosInstancev2.get(`support/tickets/admin?type=${type}`);
    return res.data;
  };

  const ticketTypesTabsList = [
    {
      id: 0,
      label: 'Login Issues',
      value: 'LOGIN_ISSUE',
      icon: <LoginIcon />,
    },
    {
      id: 1,
      label: ' Live Classes Issues',
      value: 'LIVE_CLASSES_ISSUE',
      icon: <SchoolIcon />,
    },
    {
      id: 2,
      label: 'Content Issues',
      value: 'CONTENT_ISSUE',
      icon: <LocalLibraryIcon />,
    },
    {
      id: 3,
      label: 'Progress Issues',
      value: 'PROGRESS_ISSUE',
      icon: <TrendingUpIcon />,
    },
    {
      id: 4,
      label: 'Certificate Issues',
      value: 'CERTIFICATE_ISSUE',
      icon: <CardMembershipIcon />,
    },
    {
      id: 5,
      label: 'Payment Issues',
      value: 'PAYMENT_ISSUE',
      icon: <PaymentIcon />,
    },
    {
      id: 6,
      label: 'Advanced Courses Enrollment Issues',
      value: 'ADVANCED_COURSES_ENROLLMENT_ISSUE',
      icon: <AssignmentIndIcon />,
    },
  ];

  const query = useQuery(
    ['tickets', selectedTab],
    () =>
      fetchTickets(
        ticketTypesTabsList.find((tab) => tab.id === selectedTab).value
      ),
    {
      staleTime: 3600,
      cacheTime: 3600 * 10000,
    }
  );

  const columns = [
    { title: 'Ref Number', field: 'ref_number' },
    {
      title: 'Subject',
      field: 'subject',
      render: (row) => (
        <p key={row?.id}>
          {row?.subject
            ?.toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase())}
        </p>
      ),
    },
    { title: 'Student Email', field: 'user_email' },
    {
      title: 'Created On',
      field: 'created_at',
      render: (row) => (
        <p key={row?.id}>{new Date(row.created_at).toLocaleString()}</p>
      ),
    },
    {
      title: 'Closed On',
      field: 'closed_at',
      render: (row) => (
        <p key={row?.id}>
          {row.status === 'CLOSED'
            ? new Date(row.closed_at).toLocaleString()
            : ''}
        </p>
      ),
    },
    {
      title: 'Status',
      field: 'status',
      render: (row) => {
        if (row.status === 'CLOSED') {
          return (
            <p
              key={row?.id}
              className="bg-green-400 text-center py-1 rounded-md capitalize font-medium"
            >
              {row.status}
            </p>
          );
        }
        return (
          <p
            key={row?.id}
            className="bg-red-400 text-center py-1 rounded-md capitalize font-medium"
          >
            {row.status}
          </p>
        );
      },
    },
  ];

  const renderTicketsTable = (tickets) => {
    return (
      <div className="mt-4">
        <TableWrapper
          title="List of Tickets"
          columns={columns}
          // eslint-disable-next-line no-unsafe-optional-chaining
          data={Array.isArray(tickets) ? tickets : []}
          onRowClick={(event, rowData) => navigate(`/tickets/${rowData.id}`)}
        />
      </div>
    );
  };

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
        </Breadcrumbs>
      </div>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {ticketTypesTabsList?.map((tab) => (
            <Tab
              key={tab.id}
              label={
                <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '150px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        selectedTab === tab.id ? 'primary.main' : 'inherit',
                    }}
                  >
                    {tab.icon}
                  </ListItemIcon>
                  {tab.label}
                </ListItem>
              }
            />
          ))}
        </Tabs>
        {ticketTypesTabsList?.map((tab) => (
          <TabPanel
            key={tab.id}
            value={selectedTab}
            index={tab.id}
            query={query}
            renderTicketsTable={renderTicketsTable}
          />
        ))}
      </Box>
    </MainContent>
  );
}

function TabPanel({ value, index, query, renderTicketsTable }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index ? (
        query.isLoading ? (
          <Spinner />
        ) : query.isError ? (
          'Error fetching data'
        ) : (
          renderTicketsTable(query.data.tickets)
        )
      ) : null}
    </div>
  );
}

export default Tickets;
