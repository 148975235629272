import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import useFetchData from '../hooks/useFetchData';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import TableWrapper from '../utils/TableWrapper';
import Spinner from './spinner/Spinner';
import Modal from './Modal';
import StudentLoginsTrail from './StudentLoginsTrail';

export default function SponsoredCohortLoginRawData() {
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [loginFilter, setLoginFilter] = useState('');
  const [lastLoginDate, setLastLoginDate] = useState('');
  const [studentId, setStudentId] = useState('');
  const [studentName, setStudentName] = useState('');
  const [studentEmail, setStudentEmail] = useState('');
  const [openStudentLoginActivitiesModal, setOpenStudentLoginActivitiesModal] =
    useState(false);

  const toggleStudentLoginActivitiesModal = useCallback(() => {
    setOpenStudentLoginActivitiesModal((prev) => !prev);
  }, []);

  const columns = [
    {
      title: 'First Name',
      field: 'firstname',
      render: (row) => <p className="capitalize">{row?.firstname}</p>,
    },
    {
      title: 'Middle Name',
      field: 'middlename',
      render: (row) => <p className="capitalize">{row?.middlename}</p>,
    },
    {
      title: 'Last Name',
      field: 'lastname',
      render: (row) => <p className="capitalize">{row?.lastname}</p>,
    },
    { title: 'Email', field: 'email' },
    { title: 'Country', field: 'country' },
    { title: 'Phone Number', field: 'phone_number' },
    {
      title: 'Last Login',
      field: 'last_login',
      render: (row) => (
        <p>
          {row?.last_login
            ? new Date(row?.last_login).toLocaleString(undefined, {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short',
              })
            : 'No Record'}
        </p>
      ),
    },
    { title: 'Gender', field: 'gender' },
    { title: 'Academic Level', field: 'level_of_education' },

    {
      title: 'Actions',
      render: (row) => (
        <button
          className="bg-persian-500 px-4 py-1 rounded-md text-white text-sm cursor-pointer hover:bg-persian-700 space-x-2 flex items-center"
          type="button"
          onClick={() => {
            setStudentId(row?.id);
            setStudentEmail(row?.email);
            setStudentName(`${row?.firstname} ${row?.lastname}`);
            toggleStudentLoginActivitiesModal();
          }}
        >
          <p>Logins Trail</p>
        </button>
      ),
    },
  ];

  // get cohorts
  const { data: cohorts } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohorts
    ? cohorts?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: cohort.name,
      }))
    : [];

  const loginFilterOptions = [
    {
      value: 'after',
      label: 'Last logged in after a specified date',
    },
    {
      value: 'before',
      label: 'Last logged in before a specified date',
    },
    {
      value: 'never',
      label: 'Has never logged in',
    },
  ];

  const { data: cohortLoginsRawData, isLoading } = useFetchAnalytics(
    ['cohort-logins-raw-data', cohortId, loginFilter, lastLoginDate],
    `/raw-data/cohorts-logins/${cohortId}`,
    { params: { loginFilter, lastLoginDate } },
    "Couldn't get cohort logins raw data. Please try again",
    cohortId && loginFilter && (loginFilter !== 'never' ? lastLoginDate : true)
  );

  return (
    <>
      <div className="bg-white px-2 py-5 rounded-lg">
        <p>
          <span className="font-medium">Cohort Logins Raw Data: </span>
          <span className="underline underline-offset-1">
            Retrieve student login data filtered by cohort ID and login date.
            You can filter students who logged in after or before a specified
            date, or those who have never logged in.
          </span>
        </p>
      </div>
      <div className="mt-4 grid grid-cols-4 bg-white p-3 gap-4 rounded-lg">
        <Select
          isClearable
          placeholder="Select Cohort"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={cohortOptions}
          onChange={(event) => {
            setCohortId(event?.value || '');
            setCohortName(event?.label || '');
          }}
        />
        <Select
          isClearable
          placeholder="Select Type of Filter"
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={loginFilterOptions}
          onChange={(event) => {
            setLoginFilter(event?.value || '');
            setLastLoginDate(''); // Reset date when filter changes
          }}
        />
        {(loginFilter === 'after' || loginFilter === 'before') && (
          <input
            type="date"
            max={new Date().toISOString().split('T')[0]}
            placeholder="Select Date to Filter"
            className="p-2 border rounded"
            value={lastLoginDate}
            onChange={(e) => setLastLoginDate(e.target.value)}
          />
        )}
      </div>{' '}
      <div className="mt-4">
        {isLoading &&
        cohortId &&
        loginFilter &&
        (loginFilter !== 'never' ? lastLoginDate : true) ? (
          <div className="flex items-center justify-center bg-white">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title={`${cohortName} Students Logins Data - ${
              loginFilter === 'never'
                ? 'Never logged in'
                : `Last Logged in ${loginFilter} ${lastLoginDate}`
            }`}
            columns={columns}
            data={cohortLoginsRawData ? cohortLoginsRawData?.data : []}
            options={{
              rowStyle: {
                overflowWrap: 'break-word',
              },
            }}
          />
        )}
      </div>
      <Modal
        modalOpen={openStudentLoginActivitiesModal}
        toggleModal={toggleStudentLoginActivitiesModal}
        title={`${studentName} - ${studentEmail} Logins Trail`}
      >
        <StudentLoginsTrail
          studentId={studentId}
          studentName={studentName}
          studentEmail={studentEmail}
          toggleModal={toggleStudentLoginActivitiesModal}
        />
      </Modal>
    </>
  );
}
