import React from 'react';
import { Outlet } from 'react-router';
import LeftBar from './LeftBar';
import Navbar from './Navbar';

function Layout() {
  return (
    <div className="flex flex-col space-y-20 min-h-screen font-roboto_light">
      {/* <div className="flex flex-col space-y-20 min-h-screen px-[3.125%] py-[2.6%]"> */}
      <Navbar />
      <div className="flex ">
        <LeftBar />
        {/* <div className="h-[1800px] col-span-3 absolute left-[19%] w-[61%]"> */}
        <Outlet />
      </div>
      {/* </div> */}
    </div>
  );
}

export default Layout;
