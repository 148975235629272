import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState } from 'react';
import MainContent from '../components/MainContent';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import Modal from '../components/Modal';
import CreateForum from '../components/CreateForum';
import TableWrapper from '../utils/TableWrapper';
import Spinner from '../components/spinner/Spinner';
import useFetchData from '../hooks/useFetchData';

function Forums() {
  const permissions = usePermissions();
  const [modalOpen, setModalOpen] = useState(false);
  const role = useGetUserRole();
  const navigate = useNavigate();

  const columns = [
    { title: 'Module Name', field: 'module_name' },
    {
      title: 'Cohort Name',
      field: 'cohort.name',
      // render: (row) => <p>{row.cohort.name}</p>,
    },
  ];

  // fetch forums
  const { data, isLoading } = useFetchData(
    ['forums'],
    '/forums',
    {},
    "Couldn't get forums. Please try again",
    true
  );

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          {(permissions?.includes('CreateForum') || role === 'SuperAdmin') && (
            <div className=" flex space-x-2">
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleModal}
              >
                Create Forum
              </button>
            </div>
          )}
        </div>
        <div className="mt-4">
          {isLoading ? (
            <div className="w-full flex align-middle justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <TableWrapper
              title="List of Forums"
              columns={columns}
              // eslint-disable-next-line no-unsafe-optional-chaining
              data={data ? data?.data?.forums : []}
              // data={testData}
              onRowClick={(event, rowData) => navigate(`/forums/${rowData.id}`)}
            />
          )}
        </div>
      </MainContent>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Create Forum"
      >
        <CreateForum toggleModal={toggleModal} />
      </Modal>
    </>
  );
}

export default Forums;
