/* eslint-disable no-param-reassign */
import axios from 'axios';

// const baseURL =
//   process.env.NODE_ENV === 'production'
//     ? 'https://api.lms.v1.gateway.powerlearnprojectafrica.org/'
//     : '`https://beta.powerlearnprojectafrica.org/`';

// set up config for courses api calls
export const axiosInstancev2 = axios.create({
  baseURL:
    process.env.REACT_APP_BASE_URL_v2 ||
    'https://api.lms.v2.powerlearnprojectafrica.org/gateway/api',
});

// intercept every request and add the bearer token
axiosInstancev2.interceptors.request.use(
  (requestConfig) => {
    requestConfig.headers.authorization = `Bearer ${localStorage.getItem(
      'token'
    )}`;
    return requestConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstancev2.interceptors.response.use(
  (responseConfig) => {
    return responseConfig;
  },
  (error) => {
    // check if error from server is 401 then logout user

    if (error.response?.status === 401) {
      localStorage.clear();
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);
