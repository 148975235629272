import React from 'react';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PeopleIcon from '@mui/icons-material/People';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import Spinner from './spinner/Spinner';

export default function WebinarAnalyticsOverview({ webinarID }) {
  const { data: webinarAnalyticsOverviewData, isLoading } = useFetchAnalytics(
    ['webinar-analytics-overview', webinarID],
    `/class-sessions/${webinarID}/overview`,
    {},
    "Couldn't fetch webinar analytics overview. Please try again.",
    webinarID
  );

  const startTime = webinarAnalyticsOverviewData?.data?.start_time
    ? new Date(webinarAnalyticsOverviewData?.data?.start_time)
    : null;

  const endTime = webinarAnalyticsOverviewData?.data?.end_time
    ? new Date(webinarAnalyticsOverviewData?.data?.end_time)
    : null;

  return (
    <div className="">
      {isLoading && webinarID ? (
        <div className="flex items-center justify-center bg-white">
          <Spinner />
        </div>
      ) : (
        <div className="border rounded-md shadow-md bg-white flex flex-col gap-4 p-4">
          <div className="flex items-center justify-center gap-3">
            <VideoCameraFrontIcon
              className="text-persian-500"
              style={{ height: '48px', width: '48px' }}
            />
            <h2 className="capitalize text-center text-xl text-persian-500">
              {webinarAnalyticsOverviewData?.data?.title || ''}
            </h2>
          </div>
          <div className="flex flex-col items-center justify-center gap-4">
            {startTime && (
              <div className="flex flex-col md:flex-row items-center justify-center gap-2">
                <div className="flex items-center justify-center gap-2">
                  <AccessTimeIcon className="text-persian-500" />
                  <span className="text-persian-500">Start Time:</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-bold">
                    {startTime?.toLocaleDateString(undefined, {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                  <span className="text-claret-500">
                    {startTime?.toLocaleTimeString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    })}
                  </span>
                </div>
              </div>
            )}
            {endTime && (
              <div className="flex flex-col md:flex-row items-center justify-center gap-2">
                <div className="flex items-center justify-center gap-2">
                  <AccessTimeIcon className="text-persian-500" />
                  <span className="text-persian-500">End Time:</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-bold">
                    {endTime?.toLocaleDateString(undefined, {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                  <span className="text-claret-500">
                    {endTime?.toLocaleTimeString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    })}
                  </span>
                </div>
              </div>
            )}
            <div className="flex items-center gap-2">
              <HourglassBottomIcon className="text-persian-500" />
              <span className="text-persian-500">Duration:</span>
              <span>
                {webinarAnalyticsOverviewData?.data?.duration || 0} minutes
              </span>
            </div>
            <div className="flex items-center gap-2">
              <PeopleIcon className="text-persian-500" />
              <span className="text-persian-500">Number of Registrants:</span>
              <span>
                {webinarAnalyticsOverviewData?.data?.registrants_count || 0}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
